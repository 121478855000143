/*
|---------------------------------------------------------------
| COLORS
|---------------------------------------------------------------
| Define colors
|
|
*/

/*
|
| Colors declaration
|----------------------
|
*/
$black: #000000;
$very-dark-grey: #141414;
$very-light-grey: #fafafa;
$grey: #f6f5f5;
$light-grey: #dedede;
$white: #ffffff;
$white-opacity: #a9a9a9;
$black-opacity: rgba(0, 0, 0, 0.6);

$beige: #f1e9db;
$dark-grey: #383f51;
$orange: #ff563f;

/*
|
| Assign colors
|---------------
|
*/
$border-color: $grey;
$text-color: $very-dark-grey;
$default-background-overlay: $black-opacity;

/*
|-------------
| Colors map 
|-------------
| - How to use: add lines to create more "color classes" 
| - Generated classes: .bg-{color} & .c-{color}
|
*/
$colors: (
  //Required colors
  "black": $black,
  "very-dark-grey": $very-dark-grey,
  "very-light-grey": $very-light-grey,
  "white": $white,
  "grey": $grey,
  "light-grey": $light-grey,

  "dark-grey": $dark-grey,
  "beige": $beige,
  "orange": $orange
);
