/*
|--------------------
|      SINGLE
|--------------------
*/

#page-news-single {
    .post-back {
        position: absolute;
        top: 40px;
        font-family: "Px Grotesk";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        z-index: 1;
        color: $orange;

        &::before {
            content: "";
            background-image: url("../img/icon/arrow-right.svg");
            width: 32px;
            height: 32px;
            display: block;
            float: left;
            margin-top: -7px;
            margin-right: 4px;
            transform: rotate(-180deg);
        }
    }

    .post-date {
        font-family: "Px Grotesk";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        color: $orange;
    }

    .post-title {
        font-family: "Px Grotesk";
        font-style: normal;
        font-weight: 400;
        font-size: 55px;
        line-height: 64px;
        text-align: center;
        @include media-breakpoint-down(md) {
            font-size: 40px;
            line-height: 48px;
        }
        @include media-breakpoint-down(sm) {
            font-size: 28px;
            line-height: 32px;
        }
    }

    .post-intro {
        font-family: "Px Grotesk";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        text-align: center;
    }

    .post-head {
        display: flex;
        .link-alt {
            float: right;
            position: absolute;
            right: 0;
            top: 20px;
        }
    }
    .caption {
        text-align: center;
        font-family: "Px Grotesk";
        font-style: italic;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        color: $orange;
        padding: 20px;
    }
}
