/*
|--------------------
|      HEADER
|--------------------
*/

body {
  font-family: "Px Grotesk";
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  background-color: $beige;
  background-image: url("../img/global/bg-site.png");
  width: 100%;
  height: 1427px;
  &.menu-active {
    overflow: hidden;
  }
}

/*
|
| Header
|---------
*/

.link-menu {
  color: $very-dark-grey;
  font-size: 14px;
  font-weight: 400;
}

#header {
  width: 100%;
  position: fixed;
  z-index: 10;
  background: transparent;
  transition: all ease 0.3s;

  .header-container {
    @extend .container;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 15px;
    transition: all ease 0.3s;
    position: relative;

    .item-logo {
      .logo-w {
        height: 45px;
        transition: all ease 0.3s;
      }
    }

    .item-nav {
      margin: auto;
      display: none;

      .item-menu {
        display: flex;

        li {
          margin: 0 30px;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            background-color: $orange;
            height: 2px;
            width: 0%;
            bottom: 0;
            border-radius: 10px;
            transition: all ease 0.3s;
          }
          &:hover {
            &::before {
              content: "";
              width: 100%;
              transition: all ease 0.3s;
            }
          }
          &.current-menu-item {
            &::before {
              content: "";
              width: 100%;
              transition: all ease 0.3s;
            }
          }

          .item-link {
            font-size: 18px;
            color: #484848;
            letter-spacing: 0;
            font-family: "Px Grotesk";
            font-style: normal;
            font-weight: 400;
            padding-top: 2px;
          }
        }
      }

      @include media-breakpoint-up(xl) {
        display: flex;
        align-items: center;

        .item-menu {
          margin-right: 25px;
        }
      }
    }

    .btn-cta {
      float: left;
      position: relative;
      transition: all ease 0.3s;
      a {
        font-family: "Px Grotesk";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;
        background-color: white;
        padding: 14px 22px 12px 44px;
        border-radius: 55px;
        transition: all ease 0.3s;
        &:before {
          content: "";
          height: 10px;
          width: 10px;
          border-radius: 50%;
          background-color: $orange;
          display: inline-block;
          position: absolute;
          left: 21px;
          top: 18px;
          transition: all ease 0.3s;
        }

        &:hover {
          background-color: $orange;
          transition: all ease 0.3s;
          color: white;
          &:before {
            content: "";
            background-color: white;
            transition: all ease 0.3s;
          }
        }
      }
    }
  }

  .lang-container {
    position: relative;
    display: inline-flex;
    top: -1px;
    @extend .link-menu;
    cursor: pointer;
    ul {
      float: left;
      margin-top: 13px;
      margin-left: 25px;
      li {
        font-family: "Px Grotesk";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;
        color: $dark-grey;
        display: inline-block;
        text-transform: uppercase;
        a {
          opacity: 0.5;
        }
        &:first-child {
          &::after {
            content: "";
            height: 18px;
            width: 2px;
            display: block;
            float: right;
            margin: 0 5px 0 9px;
            background-color: $dark-grey;
          }
        }
      }
    }
  }

  .btn-menu {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    //width: 56px;
    //height: 56px;
    margin-top: 0px;
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;

    & > .item-burger {
      display: block;
      width: 25px;

      & > span {
        display: block;
        width: 100%;
        height: 2px;
        background-color: $very-dark-grey;
        border-radius: 2px;

        &:nth-child(2) {
          margin: 6px 0;
        }
      }
    }

    @include media-breakpoint-up(xl) {
      display: none;
    }
  }
}

.site-container {
  &.menufixed {
    #header {
      background-color: white;
      transition: all ease 0.3s;
      .header-container {
        padding: 7px 15px;
        transition: all ease 0.3s;

        .logo-w {
          height: 36px;
          transition: all ease 0.3s;
        }

        .item-menu {
          display: flex;
        }
        .lang-container {
          top: 0;

          .btn-cta {
            transition: all ease 0.3s;
            a {
              padding: 11px 22px 9px 44px;
              transition: all ease 0.3s;
              border: 1px solid $orange;
              &:before {
                top: 16px;
                transition: all ease 0.3s;
              }
            }
          }
        }
      }
    }
  }
}

/*
|
| Menu
|-------
*/
#mobile-menu {
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: $white;
  overflow: auto;
  opacity: 0;
  visibility: hidden;

  .item-container {
    @extend .container;
    //padding-top: 150px;
    //padding-bottom: 50px;

    ul {
      & > li {
        &:not(:last-child) {
          margin-bottom: 20px;
        }

        a {
          text-transform: uppercase;
          color: $very-dark-grey;
          font-size: 30px;
          font-weight: 500;
          -webkit-font-smoothing: antialiased;

          @include media-breakpoint-down(xs) {
            font-size: 26px;
          }
        }
      }
    }
  }

  .item-socials-container {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;

    .item-socials {
      display: flex;
      align-items: center;
      margin-bottom: 25px;

      a {
        font-size: 25px;

        &:not(:last-child) {
          margin-right: 25px;
        }
      }

      @include media-breakpoint-down(xs) {
        margin-bottom: 15px;

        a {
          font-size: 20px;
        }
      }
    }
  }
  .lang-container {
    ul {
      li {
        display: contents;
        text-transform: uppercase;
        color: $very-dark-grey;
        font-size: 30px;
        font-weight: 500;
        -webkit-font-smoothing: antialiased;
      }
    }
  }
}
