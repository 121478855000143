/*
|--------------------
|       Contact
|--------------------
*/

#page-contact {
    position: relative;
    overflow: hidden;
    .sup_title {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        color: $orange;
    }
    .email {
        font-style: normal;
        font-weight: 400;
        font-size: 64px;
        line-height: 71px;
        text-align: center;
        color: $dark-grey;
        @include media-breakpoint-down(md) {
            font-size: 44px;
            line-height: 50px;
        }
        @include media-breakpoint-down(sm) {
            font-size: 22px;
            line-height: 26px;
        }
    }
    .text {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 22px;
        text-align: center;
        color: $dark-grey;
    }
    .gform_fields {
        margin-right: 0px;
        margin-left: 0px;
    }
    .gform_wrapper {
        font-family: Inter;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;

        h2,
        .gform_required_legend,
        .gform_validation_errors {
            display: none;
        }

        p,
        a {
            font-family: "Px Grotesk";
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
        }

        .gfield {
            margin-bottom: 15px;
        }

        label {
            font-family: "Px Grotesk";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: $dark-grey;
        }

        .gfield_required {
            /* display: none; */
        }

        input,
        textarea,
        select {
            border: none;
        }

        input[type="text"],
        input[type="email"],
        textarea {
            box-sizing: border-box;
            border-radius: 10px;
            padding: 15px 20px !important;
            background-color: $white;

            &::placeholder {
                opacity: 0.5;
            }
        }

        textarea {
            height: 275px !important;
        }

        .gfield_label_before_complex {
            display: none;
        }

        .gfield_description {
            font-family: "Px Grotesk";
            font-style: normal;
            color: #a7a7a9;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
        }

        .ginput_container_consent {
            font-family: "Px Grotesk";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 18px;
            color: $dark-grey;
            position: relative;

            a {
                text-decoration: underline;
                font-family: "Px Grotesk";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 18px;
                color: $dark-grey;
                position: relative;
            }

            .gfield_required {
                /* display: none; */
            }

            input[type="checkbox"] {
                position: absolute;
                cursor: pointer;
                border: none;
                left: 0;
                top: 2px;
                padding: 0;
                margin: 0;
            }

            input[type="checkbox"]:before {
                content: "";
                display: block;
                position: absolute;
                width: 21px;
                height: 21px;
                top: 0;
                left: 0;
                background-color: transparent;
                border: 1px solid $orange;
            }

            input[type="checkbox"]:checked:after {
                content: "";
                display: block;
                width: 15px;
                height: 15px;
                position: absolute;
                top: 3px;
                left: 3px;
                background-color: $orange;
            }

            label {
                margin-top: 4px;
                margin-left: 35px;

                @include media-breakpoint-down(lg) {
                    padding-right: 0px;
                }
            }
        }

        .btn {
            float: right;
            @include media-breakpoint-down(md) {
                float: initial;
            }

            &:after {
                left: 20px;
            }
        }
    }
    .gform_footer {
        .btn {
            float: initial;
            margin: auto;
            .text {
                font-family: "Px Grotesk";
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 20px;
                transition: all ease 0.3s;
            }
            &:hover {
                .text {
                    color: white;
                }
            }
        }
    }
    #gform_ajax_spinner_1 {
        position: absolute;
    }
    .textleave {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 22px;
        text-align: center;
        color: #383f51;
        font-family: "Px Grotesk";
    }
    .gform_confirmation_message_1 {
        margin: 10vh 0 40vh 0;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 22px;
        text-align: center;
        color: #383f51;
        font-family: "Px Grotesk";
    }
}
