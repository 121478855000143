/*
|--------------------
|      NEWS
|--------------------
*/

.blog {
    .banner {
        height: 53vh;
        @include media-breakpoint-down(md) {
            height: 300px;
        }
        .item-content {
            height: 53vh;
            @include media-breakpoint-down(md) {
                height: 300px;
            }
        }
    }
}
.load-more-container {
    min-height: 100px;

    display: flex;
    align-items: center;
    justify-content: center;

    .loader-container {
        display: none;
    }
}
