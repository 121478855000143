/*
|--------------------
|      FOOTER
|--------------------
*/
#footer {
  font-size: 16px;
  line-height: 20px;
  color: white;
  background-color: $dark-grey;

  .menu-secondary {
    ul {
      li {
        display: inline-block;
        .nav-drop {
          li {
            display: block;
          }
        }
        .item-link {
          color: $orange;
        }
      }
    }
  }

  .elem-link {
    opacity: 1;
    transition: all ease 0.3s;

    &:hover {
      opacity: 0.9;
      transition: all ease 0.3s;
    }

    .bg {
      background-position: center;
      background-size: cover;
      height: 360px;
      position: relative;
      padding: 30px;
      display: block;
      @include media-breakpoint-down(md) {
        height: 200px;
      }

      &:before {
        content: "";
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0.4;
        position: absolute;
        background-color: black;
      }
    }

    .text {
      font-size: 16px;
      color: white;
      z-index: 1;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 30px;
      @include media-breakpoint-down(md) {
        height: 200px;
        display: flex;
        vertical-align: middle;
        align-items: center;
        div {
          width: 100%;
        }
      }

      span {
        text-transform: uppercase;
      }
    }
  }

  .elem-values {
    text-align: center;

    img {
      height: 100px;
      @include media-breakpoint-down(sm) {
        height: 60px;
      }
    }

    .text {
      font-family: Helvetica;
      font-size: 22px;
      line-height: 28px;
      @include media-breakpoint-down(sm) {
        font-size: 16px;
        line-height: 20px;
      }

      color: white;
    }
  }

  .ft-links {
    padding-top: 40px;
    @include media-breakpoint-down(sm) {
      display: none;
    }

    a {
      /* border-bottom: 1px solid $dark-grey; */
      transition: all ease 0.3s;

      &:hover {
        color: $orange;
        /* border-bottom: 1px solid $orange; */
        transition: all ease 0.3s;
      }
    }
  }

  h2 {
    font-size: 24px;
    line-height: 32px;
    @include media-breakpoint-down(lg) {
      font-size: 19px;
      line-height: 19px;
    }
  }

  p,
  a,
  li {
    font-size: 16px;
    line-height: 20px;
    color: white;
    letter-spacing: 0;
    font-weight: normal;
    @include media-breakpoint-down(md) {
      font-size: 15px;
    }
  }

  .item-logo {
    img {
      width: 149px;
    }
  }

  .block-m-legal {
    background: black;
    .m-legal {
      padding: 10px 0;
      ul {
        display: block;
        text-align: left;
        line-height: 0;
        padding: 0;
        @include media-breakpoint-down(md) {
          text-align: center;
        }

        &:after {
          display: inline-block;
          width: 95%;
          content: "";
          vertical-align: top;
        }

        li {
          font-family: "Px Grotesk";
          font-weight: 300;
          font-size: 16px;
          color: $white-opacity;
          letter-spacing: 0;
          line-height: 24px;
          display: inline-block;
          vertical-align: middle;
          @include media-breakpoint-down(md) {
            padding: 0 7px;
          }

          a {
            font-family: "Px Grotesk";
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 17px;

            &:hover {
              color: $orange;
            }
          }
          &:not(:last-child) {
            padding: 0 3%;
            a {
              color: $white-opacity;
            }
          }
          &:last-child {
            position: absolute;
            right: 0;
            color: $white-opacity;
            @include media-breakpoint-down(md) {
              position: relative;
            }
            a {
              color: white;
            }
          }
        }
      }
    }
  }
}
