/*
|--------------------
|   PAGES profil
|--------------------
*/

#single-profil {
    h1 {
        font-family: "Px Grotesk";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        color: $orange;
        text-align: left;
    }
    .title {
        font-family: "Px Grotesk";
        font-style: normal;
        font-weight: 400;
        font-size: 59px;
        line-height: 63px;
        @include media-breakpoint-down(md) {
            font-size: 42px;
            line-height: 50px;
        }
        @include media-breakpoint-down(lg) {
            font-size: 36px;
            line-height: 40px;
        }
    }
    .list-anchor {
        width: 321px;
        @include media-breakpoint-down(lg) {
            max-width: 288px;
            width: 100%;
        }
        @include media-breakpoint-down(md) {
            margin: auto;
        }
        li {
            border-bottom: 1px solid $orange;
            a {
                font-family: "Px Grotesk";
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 20px;
                padding: 16px 40px 16px 12px;
                position: relative;
                background-color: transparent;
                width: 100%;
                border-radius: 7px;
                margin-top: -4px;
                &:after {
                    content: "";
                    height: 22px;
                    width: 22px;
                    display: inline-block;
                    position: absolute;
                    right: 10px;
                    top: 14px;
                    background-image: url("../img/icon/ArrowDown.svg");
                }
            }
            &:hover,
            .active {
                border-bottom: 1px solid transparent;
                a {
                    background-color: white;
                }
            }
        }
    }
    .intro {
        font-family: "Px Grotesk";
        font-style: normal;
        font-size: 18px;
        line-height: 28px;
    }
}

#page-profils {
    .card-profils {
        background-color: white;
        padding: 20px 50px 20px 30px;
        margin-bottom: 30px;
        border-radius: 10px;
        position: relative;
        font-size: 16px;
        line-height: 24px;
        &:after {
            content: "";
            height: 22px;
            width: 12px;
            display: inline-block;
            position: absolute;
            right: 30px;
            top: 0;
            bottom: 0;
            margin: auto;
            background-image: url(../img/icon/arrow.svg);
            transition: all ease 0.3s;
        }
        &:hover {
            &:after {
                right: 25px;
                transition: all ease 0.3s;
            }
        }
        .title {
            font-weight: 700;
            display: block;
        }
    }
    #section-temoignages {
        .sliders-tem {
            #swiper-tem-thumbs {
                @include media-breakpoint-down(xs) {
                    display: none;
                }
                .swiper-slide {
                    width: 20%;
                    height: 100%;
                    cursor: pointer;

                    .tem-img {
                        width: calc(100% - 30px);
                        height: 112px;
                        background-size: cover;
                        background-position: center;
                        background-color: #fff;
                        border-radius: 10px;
                        margin: 15px;
                        display: flex;
                        vertical-align: middle;
                        align-items: center;
                        @include media-breakpoint-down(md) {
                            height: 60px;
                        }
                        img {
                            max-height: 60px;
                            height: inherit;
                            margin: auto;
                            display: block;
                            @include media-breakpoint-down(lg) {
                                height: 40px;
                            }
                        }
                    }
                    &.swiper-slide-thumb-active {
                        background-color: white;
                        border-top-left-radius: 10px;
                        border-top-right-radius: 10px;
                    }
                }
            }

            #swiper-tem {
                font-family: "Px Grotesk";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 18px;
                color: $dark-grey;
                overflow: hidden;
                background-color: white;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                .swiper-wrapper {
                    position: relative;
                    .swiper-slide {
                        min-height: 475px;
                        .tem-title {
                            color: $orange;
                        }
                        .tem-text {
                            font-family: "Px Grotesk";
                            font-style: normal;
                            font-weight: 400;
                            font-size: 28px;
                            line-height: 42px;
                            position: relative;
                            @include media-breakpoint-down(sm) {
                                font-size: 26px;
                                line-height: 30px;
                            }
                            &:before {
                                content: "";
                                background-image: url("../img/icon/quote.svg");
                                width: 37px;
                                height: 27px;
                                display: block;
                                float: left;
                                left: 0;
                                position: absolute;
                                margin-top: 6px;
                            }
                        }
                        .tem-infos {
                            float: left;
                            margin-right: 10px;
                            .tem-auth-img {
                                width: 80px;
                                height: 80px;
                                background-size: contain;
                                background-repeat: no-repeat;
                                background-position: center;
                                margin-right: 20px;
                            }

                            .tem-auth-title {
                                font-weight: 700;
                            }
                        }
                    }
                }
                .swiper-buttons {
                    position: absolute;
                    bottom: 60px;
                    right: 60px;
                    z-index: 5;
                    @include media-breakpoint-down(sm) {
                        position: relative;
                        bottom: 0;
                        right: 0;
                        left: 0;
                        margin: 0 auto 20px auto;
                        z-index: 5;
                        display: block;
                        text-align: center;
                        width: 158px;
                        height: 52px;
                    }

                    .button-prev,
                    .button-next {
                        width: 58px;
                        height: 52px;
                        background: #f1e9da;
                        border-radius: 50px;
                        float: left;
                        margin: 0 10px;
                        cursor: pointer;
                        &::before {
                            content: "";
                            background-image: url("../img/icon/arrow-right.svg");
                            width: 32px;
                            height: 32px;
                            display: block;
                            margin: 9px auto;
                        }
                        &.swiper-button-disabled {
                            opacity: 0.5;
                        }
                    }
                    .button-prev {
                        &::before {
                            content: "";
                            transform: rotate(180deg);
                        }
                    }
                }
            }
        }
    }
}
