.section_values {
  img {
    width: 40px;
  }
  .val-img {
    width: 180px;
    max-height: 100px;
  }
  h3 {
    font-family: "Px Grotesk";
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 35px;
    color: $orange;
  }
  .desc {
    font-family: "Px Grotesk";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
}

.section_news {
  h2 {
    text-align: center;
  }
  .custom-card {
    .custom-card-link {
      display: block;
    }
  }
  .custom-card {
    padding-right: 40px;
    @include media-breakpoint-down(lg) {
      padding-right: 0px;
    }

    .card-img-container {
      position: relative;
      overflow: hidden;
      display: block;

      &:after {
        content: "";
        display: block;
        padding-bottom: 100%;
      }

      .card-img {
        position: absolute;

        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        transition: transform 2s $easeOutExpo;
        will-change: transform;
      }
    }

    .card-content {
      display: block;
      .date {
        font-family: "Px Grotesk";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        color: $orange;
        display: block;
      }
      .intro {
        font-family: "Px Grotesk";
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 27px;
        text-align: left;
        display: block;
      }
    }
    &:last-child {
      .custom-card {
        padding-right: 0px;
      }
    }
    &:hover {
      .card-img-container {
        .card-img {
          transform: scale(1.05);
        }
      }
    }
  }
}

.section_numbers {
  .number {
    font-family: "Px Grotesk";
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 76px;
    color: $orange;
    @include media-breakpoint-down(md) {
      font-size: 50px;
      line-height: 60px;
    }
  }
  .text {
    font-family: "Px Grotesk";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: $dark-grey;
    min-height: 60px;
  }
}

.section_team {
  .team {
    height: 195px;
    @include media-breakpoint-down(xl) {
      height: 162px;
    }
    @include media-breakpoint-down(lg) {
      height: 132px;
    }
    @include media-breakpoint-down(md) {
      height: 155px;
    }
    @include media-breakpoint-down(sm) {
      height: 194px;
    }
    @include media-breakpoint-down(xs) {
      height: 132px;
    }
    .elem-team {
      position: relative;
      @include media-breakpoint-down(sm) {
        width: 396px;
        margin: auto;
      }
      @include media-breakpoint-down(xs) {
        width: 270px;
        margin: auto;
      }
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 195px;
        background-image: url(../img/icon/cadre.svg);
        background-size: cover;
        z-index: 1;
        @include media-breakpoint-down(xl) {
          height: 162px;
        }
        @include media-breakpoint-down(lg) {
          height: 133px;
        }
        @include media-breakpoint-down(md) {
          height: 155px;
        }
        @include media-breakpoint-down(sm) {
          height: 195px;
        }
        @include media-breakpoint-down(xs) {
          height: 133px;
        }
      }
      .name {
        position: absolute;
        top: -3px;
        left: 0;
        right: 0;
        margin: auto;
        background-color: white;
        display: inline-table;
        padding: 6px 10px;
        border-radius: 50px;
        font-family: "Px Grotesk";
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #484848;
        z-index: 2;
        @include media-breakpoint-down(xl) {
          top: -6px;
          font-size: 15px;
          line-height: 17px;
        }
        @include media-breakpoint-down(lg) {
          font-size: 14px;
          line-height: 17px;
        }
        @include media-breakpoint-down(sm) {
          font-size: 14px;
          line-height: 17px;
        }
      }
      .photo {
        float: left;
        width: 33%;
        height: 171px;
        background-size: cover;
        background-position: center;
        position: absolute;
        top: 13px;
        left: 12px;
        @include media-breakpoint-down(xl) {
          height: 142px;
          top: 11px;
          left: 10px;
        }
        @include media-breakpoint-down(lg) {
          height: 117px;
          top: 8px;
          left: 8px;
        }
        @include media-breakpoint-down(md) {
          height: 138px;
        }
        @include media-breakpoint-down(sm) {
          height: 171px;
          top: 13px;
          left: 12px;
        }
        @include media-breakpoint-down(xs) {
          height: 117px;
          top: 8px;
          left: 8px;
        }
      }
      .infos {
        float: left;
        width: 61%;
        height: 171px;
        background-size: cover;
        position: absolute;
        top: 13px;
        right: 12px;
        padding: 30px;
        text-align: center;
        font-family: "Px Grotesk";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        z-index: 1;
        @include media-breakpoint-down(xl) {
          height: 142px;
          top: 11px;
          right: 10px;
          font-size: 15px;
          line-height: 20px;
          padding: 0 22px;
        }
        @include media-breakpoint-down(lg) {
          height: 113px;
          font-size: 12px;
          line-height: 16px;
          padding: 22px 22px 0 22px;
        }
        @include media-breakpoint-down(sm) {
          height: 172px;
          font-size: 15px;
          line-height: 17px;
          padding: 22px;
        }
        @include media-breakpoint-down(xs) {
          height: 113px;
          font-size: 12px;
          line-height: 16px;
          padding: 22px 22px 0 22px;
        }
        .link_lk {
          background: white;
          text-decoration: none;
          padding: 13px 20px;
          border-radius: 60px;
          display: inline-block;
          @include media-breakpoint-down(lg) {
            padding: 5px 12px;
          }
          @include media-breakpoint-down(sm) {
            padding: 13px 20px;
          }
          @include media-breakpoint-down(xs) {
            padding: 5px 12px;
          }
          img {
            content: "";
            width: 12px;
            height: 12px;
            display: block;
            background-color: white;
          }
        }
      }
    }

    .elem-team-text {
      position: relative;
      @include media-breakpoint-down(sm) {
        width: 396px;
        margin: auto;
      }
      @include media-breakpoint-down(xs) {
        width: 270px;
        margin: auto;
      }
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 195px;
        background-image: url(../img/icon/cadre.svg);
        background-size: cover;
        z-index: 1;
        @include media-breakpoint-down(xl) {
          height: 162px;
        }
        @include media-breakpoint-down(lg) {
          height: 133px;
        }
        @include media-breakpoint-down(md) {
          height: 155px;
        }
        @include media-breakpoint-down(sm) {
          height: 195px;
        }
        @include media-breakpoint-down(xs) {
          height: 133px;
        }
      }
      .dflex {
        height: 195px;
        padding: 0 30px;
        text-align: center;
        @include media-breakpoint-down(xl) {
          height: 162px;
        }
        @include media-breakpoint-down(lg) {
          height: 132px;
          padding: 0 11px;
        }
        @include media-breakpoint-down(md) {
          height: 154px;
          padding: 0 13px;
        }
        @include media-breakpoint-down(sm) {
          height: 195px;
          padding: 0 30px;
        }
        @include media-breakpoint-down(xs) {
          height: 132px;
          padding: 0 11px;
        }
      }
      .text {
        font-family: "Px Grotesk";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        @include media-breakpoint-down(xl) {
          font-size: 15px;
          line-height: 20px;
        }
        @include media-breakpoint-down(lg) {
          font-size: 13px;
          line-height: 16px;
        }
        @include media-breakpoint-down(sm) {
          font-size: 16px;
          line-height: 24px;
        }
        @include media-breakpoint-down(xs) {
          font-size: 13px;
          line-height: 16px;
        }
      }
    }
    &:hover {
      .link_lk {
        background: $orange !important;
        svg {
          path {
            fill: white;
          }
        }
      }
    }
  }
}
.section_list {
  img {
    width: 40px;
  }
  h3 {
    font-family: "Px Grotesk";
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 35px;
    color: $orange;
    padding: 3px 0;
  }
  .desc {
    font-family: "Px Grotesk";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
}
