/* Bootstrap lightweight */
/*!
 * Bootstrap Grid v4.2.1 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%; }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  text-align: left; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0; }

p {
  margin-top: 0; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bold; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

th {
  text-align: inherit; }

label {
  display: inline-block; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.container, #header .header-container, #mobile-menu .item-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container, #header .header-container, #mobile-menu .item-container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container, #header .header-container, #mobile-menu .item-container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container, #header .header-container, #mobile-menu .item-container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container, #header .header-container, #mobile-menu .item-container {
      max-width: 1140px; } }
  @media (min-width: 1400px) {
    .container, #header .header-container, #mobile-menu .item-container {
      max-width: 1340px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row, .gravity-form-bootstrap .gform_fields {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
.col-xxl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1400px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xxl-first {
    order: -1; }
  .order-xxl-last {
    order: 13; }
  .order-xxl-0 {
    order: 0; }
  .order-xxl-1 {
    order: 1; }
  .order-xxl-2 {
    order: 2; }
  .order-xxl-3 {
    order: 3; }
  .order-xxl-4 {
    order: 4; }
  .order-xxl-5 {
    order: 5; }
  .order-xxl-6 {
    order: 6; }
  .order-xxl-7 {
    order: 7; }
  .order-xxl-8 {
    order: 8; }
  .order-xxl-9 {
    order: 9; }
  .order-xxl-10 {
    order: 10; }
  .order-xxl-11 {
    order: 11; }
  .order-xxl-12 {
    order: 12; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; } }

/* Function */
/*
|---------------------------------------------------------------
| Functions
|---------------------------------------------------------------
| Functions used to add classes to generation
|
|
*/
/*
|
| Add Buttons
|-----------------------
|
*/
/*
|
| Add Blocks
|-----------------------
|
*/
/*
|
| Add Links
|-----------------------
|
*/
/* variables */
/*
|---------------------------------------------------------------
| Variables
|---------------------------------------------------------------
| Import variables
|
|
*/
/*
|---------------------------------------------------------------
| SPACES
|---------------------------------------------------------------
| Margins and paddings
|
|
*/
/*
|--------------
| Space sizes
|--------------
| Variables used to generate magins & paddings classes (including responsive)
| - Normal classes: .{property}-{size} / .mb-md / margin-bottom: $md  
| - Responsive classes: .{screen}-{up/down/only}-{property}-{size} / .md-down-mb-md /  (max-width: 992px) { margin-bottom: $md }
|
*/
/*
|---------------------------------------------------------------
| COLORS
|---------------------------------------------------------------
| Define colors
|
|
*/
/*
|
| Colors declaration
|----------------------
|
*/
/*
|
| Assign colors
|---------------
|
*/
/*
|-------------
| Colors map 
|-------------
| - How to use: add lines to create more "color classes" 
| - Generated classes: .bg-{color} & .c-{color}
|
*/
/*
|---------------------------------------------------------------
| TEXTS
|---------------------------------------------------------------
| Set all text properties
|
|
*/
/*
|
|
| FONT-SIZES
|
|
|----------------------------------------------
| Font sizes settings (for each screen sizes)
|----------------------------------------------
| Variables used to generate font classes (including responsive)
| - Generated classes: .fs-{size}
| - Exemple: .fs-md
|
*/
/*
|
|
| TITLE-SIZES
|
|
|-----------------------------------------------
| Title sizes settings (for each screen sizes)
|-----------------------------------------------
| Variables used to generate title classes (including responsive)
| - Generated classes: .title-{size}
| - Exemple: .title-md
|
*/
/*
|
| FONT-FAMILIES
|----------------
|
*/
/*
|-------------------------------
| Font family classes creation
|-------------------------------
| How to use: add lines to create more "font family classes" 
| - Generated classes: .font-{family}
| - Exemple: .font-custom
|
*/
/*
|-----------------
| LETTER-SPACING
|-----------------
| Variables used to generate letter-spacing classes
| - Generated classes: .ls-{size}
| - Exemple: .ls-md
|
*/
/*
|
| LINE-HEIGHT
|--------------
|
*/
/*
|
| Default font settings
|------------------------
|
*/
/*
|
| Default titles settings
|--------------------------
|
*/
/*
|---------------------------------------------------------------
| LINKS
|---------------------------------------------------------------
| Generate and assign links colors
|
|
*/
/*
|
| Links default
|----------------
|
*/
/*
|------------------------
| Links colors creation
|------------------------
| - How to use: Add --> $links-colors: map-set-links($map, $index, $color, $color-hover)
| - Generated classes: .link-{color} & .links-{color} (on parent)
|
*/
/*
|---------------------------------------------------------------
| BUTTONS
|---------------------------------------------------------------
| Generate and set buttons
|
|
*/
/*
|
| Buttons base default
|----------------------
|
*/
/*
|
| Buttons size setting
|-----------------------
|
*/
/*
|-------------------
| Buttons creation
|-------------------
| - How to use: $button-colors: map-set-buttons($map, $index, $background-color, $border-color, $text-color)
| - Generated classes: .btn-{size}, .btn-{color}, .btn-icon-{direction}.
|
*/
/*
|---------------------------------------------------------------
| SECTIONS
|---------------------------------------------------------------
| Generate responsive sections
|
|
*/
/*
|-------------------
| Sections setting
|-------------------
| - Generated class: .section
|
*/
/*
|---------------------------------------------------------------
| BUTTONS
|---------------------------------------------------------------
| Generate and set buttons
|
|
*/
/*
|
| Bg img default
|----------------------
|
*/
/*
|-------------------
| Bg img creation
|-------------------
| - Generated classes: .bg-img-{size}
|
*/
/* Components */
/*
|---------------------------------------------------------------
| Helpers
|---------------------------------------------------------------
| Define helper classes
|
|
*/
/*
|
| Text Transform
|----------------------------
|
*/
.tt-u {
  text-transform: uppercase !important; }

.tt-l {
  text-transform: lowercase !important; }

.tt-c {
  text-transform: capitalize !important; }

/*
|
| Underline
|------------
|
*/
.td-u {
  text-decoration: underline !important; }

.td-n {
  text-decoration: none !important; }

/*
|
| Font styles
|--------------
|
*/
.fs-i {
  font-style: italic !important; }

.fs-o {
  font-style: oblique !important; }

.fs-n {
  font-style: normal !important; }

/*
|
| Background cover
|-------------------
|
*/
.bg-cover, .bg-cover-top, .bg-cover-bottom {
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important; }

.bg-cover-top {
  background-position: center top !important; }

.bg-cover-bottom {
  background-position: center bottom !important; }

/*
|
| Overlays
|-------------
|
*/
.overlay {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6); }
  .overlay + * {
    position: relative;
    z-index: 2; }

/*
|
| Overflows
|-------------
|
*/
.o-v {
  overflow: visible !important; }

.ox-v {
  overflow-x: visible !important; }

.oy-v {
  overflow-y: visible !important; }

.o-h {
  overflow: hidden !important; }

.ox-h {
  overflow-x: hidden !important; }

.oy-h {
  overflow-y: hidden !important; }

/*
|
| Positions
|-------------
|
*/
.p-r {
  position: relative !important; }

.p-a {
  position: absolute !important; }

.p-f {
  position: fixed !important; }

.p-s {
  position: static !important; }

/*
|
| Flex Alignment
|-----------------
|
*/
.ai-start {
  align-items: flex-start; }

.ai-end {
  align-items: flex-end; }

.ai-center {
  align-items: center; }

.ai-baseline {
  align-items: baseline; }

.ai-stretch {
  align-items: stretch; }

/*
|
| Flex Justify
|---------------
|
*/
.jc-start {
  justify-content: flex-start; }

.jc-end {
  justify-content: flex-end; }

.jc-center {
  justify-content: center; }

.jc-between {
  justify-content: space-between; }

.jc-around {
  justify-content: space-around; }

/*
|
| White space
|--------------
|
*/
.ws-inherit {
  white-space: inherit; }

.ws-normal {
  white-space: normal; }

.ws-nowrap {
  white-space: nowrap; }

.ws-pre {
  white-space: pre; }

.ws-pre-wrap {
  white-space: pre-wrap; }

.ws-pre-line {
  white-space: pre-line; }

/*
|
| Pointer events
|-----------------
|
*/
.pe-inherit {
  pointer-events: inherit; }

.pe-none {
  pointer-events: none; }

.pe-auto {
  pointer-events: auto; }

/*
|
| Optimize animation
|---------------------
|
*/
.optimize-animation {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform-style: preserve-3d;
  perspective: 1000;
  -webkit-perspective: 1000;
  will-change: transform; }

/*
|
| transform-none
|-----------------
|
*/
.transform-none {
  transform: none !important; }

/*
|
| transform-none
|-----------------
|
*/
.transform-none {
  transform: none !important; }

/*
|
| absolute-full
|-----------------
|
*/
.absolute-full {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

/*
|
| Clear floats
|---------------
|
*/
.clear {
  clear: both; }

/*
|
| img full
|-----------
|
*/
.img-full {
  display: inline-block;
  max-width: inherit;
  width: 100%; }

/*
|
| W-100 H-100
|-----------
|
*/
.w-100 {
  width: 100%; }

.h-100 {
  height: 100%; }

/* Core */
/*
|---------------------------------------------------------------
| MAPS
|---------------------------------------------------------------
| Sass maps used in classes generation
|
|
*/
/*
|
| Font Sizes Map
|---------------------
|
*/
/*
|
| Title Sizes Map
|---------------------
|
*/
/*
|
| Buttons Sizes Map
|---------------------
|
*/
/*
|
| Margins & Paddings Maps
|--------------------------
|
*/
/*
|-------------------------------
| Font weight classes creation
|-------------------------------
| How to use: add lines to create more "font weight classes"
| - Generated classes: .font-{weight}
| - Exemple: .font-regular
|
*/
/*
|
| Font-weights
|---------------
|
*/
/*
|
| Text Align Map
|--------------------------
|
*/
/*
|-------------------------------
| Line-height classes creation
|-------------------------------
| How to use: add lines to create more "line-height classes"
| - Generated classes: .lh-{weight}
| - Exemple: .lh-sm
|
*/
/*
|
| Letter Spacing Map
|--------------------------
|
*/
/*
|
| Display Map
|--------------------------
|
*/
/*
|
| Section Map
|--------------------------
|
*/
/*
|---------------------------------------------------------------
| MIXINS
|---------------------------------------------------------------
| Mixins called from generator to generate classes
|
|
*/
/*
|
| Commonly used mixins
|-----------------------
|
*/
/*
|
| Margins & Paddings
|-----------------------
|
*/
/*
|
| Responsive Margins & Paddings
|--------------------------------
|
*/
/*
|
| Font-sizes
|-----------------------
|
*/
/*
|
| Titles
|-----------------------
|
*/
/*
|
| Links
|-----------------------
|
*/
/*
|
| Buttons
|-----------------------
|
*/
/*
|
| Hidden classes
|-----------------------
|
*/
/*
|
| Display
|-----------------------
|
*/
/*
|
| Text Align
|-----------------------
|
*/
/*
|
| Sections
|-----------------------
|
*/
/*
|
| Bg imgs
|-------------
|
*/
/*
|---------------------------------------------------------------
| CLASS GENERATOR
|---------------------------------------------------------------
| Mixins calls to generate classes
|
|
*/
/*------- DISPLAY -------*/
@media (max-width: 1199.98px) {
  .lg-down-d-none {
    display: none; } }

@media (max-width: 991.98px) {
  .md-down-d-none {
    display: none; } }

@media (max-width: 767.98px) {
  .sm-down-d-none {
    display: none; } }

@media (max-width: 575.98px) {
  .xs-down-d-none {
    display: none; } }

.xs-up-d-none {
  display: none; }

@media (min-width: 576px) {
  .sm-up-d-none {
    display: none; } }

@media (min-width: 768px) {
  .md-up-d-none {
    display: none; } }

@media (min-width: 992px) {
  .lg-up-d-none {
    display: none; } }

@media (min-width: 1200px) {
  .xl-up-d-none {
    display: none; } }

@media (max-width: 1199.98px) {
  .lg-down-d-i {
    display: inline; } }

@media (max-width: 991.98px) {
  .md-down-d-i {
    display: inline; } }

@media (max-width: 767.98px) {
  .sm-down-d-i {
    display: inline; } }

@media (max-width: 575.98px) {
  .xs-down-d-i {
    display: inline; } }

.xs-up-d-i {
  display: inline; }

@media (min-width: 576px) {
  .sm-up-d-i {
    display: inline; } }

@media (min-width: 768px) {
  .md-up-d-i {
    display: inline; } }

@media (min-width: 992px) {
  .lg-up-d-i {
    display: inline; } }

@media (min-width: 1200px) {
  .xl-up-d-i {
    display: inline; } }

@media (max-width: 1199.98px) {
  .lg-down-d-b {
    display: block; } }

@media (max-width: 991.98px) {
  .md-down-d-b {
    display: block; } }

@media (max-width: 767.98px) {
  .sm-down-d-b {
    display: block; } }

@media (max-width: 575.98px) {
  .xs-down-d-b {
    display: block; } }

.xs-up-d-b {
  display: block; }

@media (min-width: 576px) {
  .sm-up-d-b {
    display: block; } }

@media (min-width: 768px) {
  .md-up-d-b {
    display: block; } }

@media (min-width: 992px) {
  .lg-up-d-b {
    display: block; } }

@media (min-width: 1200px) {
  .xl-up-d-b {
    display: block; } }

@media (max-width: 1199.98px) {
  .lg-down-d-ib {
    display: inline-block; } }

@media (max-width: 991.98px) {
  .md-down-d-ib {
    display: inline-block; } }

@media (max-width: 767.98px) {
  .sm-down-d-ib {
    display: inline-block; } }

@media (max-width: 575.98px) {
  .xs-down-d-ib {
    display: inline-block; } }

.xs-up-d-ib {
  display: inline-block; }

@media (min-width: 576px) {
  .sm-up-d-ib {
    display: inline-block; } }

@media (min-width: 768px) {
  .md-up-d-ib {
    display: inline-block; } }

@media (min-width: 992px) {
  .lg-up-d-ib {
    display: inline-block; } }

@media (min-width: 1200px) {
  .xl-up-d-ib {
    display: inline-block; } }

@media (max-width: 1199.98px) {
  .lg-down-d-f {
    display: flex; } }

@media (max-width: 991.98px) {
  .md-down-d-f {
    display: flex; } }

@media (max-width: 767.98px) {
  .sm-down-d-f {
    display: flex; } }

@media (max-width: 575.98px) {
  .xs-down-d-f {
    display: flex; } }

.xs-up-d-f {
  display: flex; }

@media (min-width: 576px) {
  .sm-up-d-f {
    display: flex; } }

@media (min-width: 768px) {
  .md-up-d-f {
    display: flex; } }

@media (min-width: 992px) {
  .lg-up-d-f {
    display: flex; } }

@media (min-width: 1200px) {
  .xl-up-d-f {
    display: flex; } }

@media (max-width: 1199.98px) {
  .lg-down-d-if {
    display: inline-flex; } }

@media (max-width: 991.98px) {
  .md-down-d-if {
    display: inline-flex; } }

@media (max-width: 767.98px) {
  .sm-down-d-if {
    display: inline-flex; } }

@media (max-width: 575.98px) {
  .xs-down-d-if {
    display: inline-flex; } }

.xs-up-d-if {
  display: inline-flex; }

@media (min-width: 576px) {
  .sm-up-d-if {
    display: inline-flex; } }

@media (min-width: 768px) {
  .md-up-d-if {
    display: inline-flex; } }

@media (min-width: 992px) {
  .lg-up-d-if {
    display: inline-flex; } }

@media (min-width: 1200px) {
  .xl-up-d-if {
    display: inline-flex; } }

/*------- FONT-SIZES -------*/
.fs-xs {
  font-size: 12px !important; }
  @media (max-width: 1199.98px) {
    .fs-xs {
      font-size: 12px !important; } }
  @media (max-width: 991.98px) {
    .fs-xs {
      font-size: 12px !important; } }
  @media (max-width: 767.98px) {
    .fs-xs {
      font-size: 12px !important; } }
  @media (max-width: 575.98px) {
    .fs-xs {
      font-size: 12px !important; } }

.fs-sm {
  font-size: 16px !important; }
  @media (max-width: 1199.98px) {
    .fs-sm {
      font-size: 16px !important; } }
  @media (max-width: 991.98px) {
    .fs-sm {
      font-size: 16px !important; } }
  @media (max-width: 767.98px) {
    .fs-sm {
      font-size: 16px !important; } }
  @media (max-width: 575.98px) {
    .fs-sm {
      font-size: 16px !important; } }

.fs-md {
  font-size: 18px !important; }
  @media (max-width: 1199.98px) {
    .fs-md {
      font-size: 18px !important; } }
  @media (max-width: 991.98px) {
    .fs-md {
      font-size: 18px !important; } }
  @media (max-width: 767.98px) {
    .fs-md {
      font-size: 18px !important; } }
  @media (max-width: 575.98px) {
    .fs-md {
      font-size: 18px !important; } }

.fs-lg {
  font-size: 28px !important; }
  @media (max-width: 1199.98px) {
    .fs-lg {
      font-size: 28px !important; } }
  @media (max-width: 991.98px) {
    .fs-lg {
      font-size: 28px !important; } }
  @media (max-width: 767.98px) {
    .fs-lg {
      font-size: 28px !important; } }
  @media (max-width: 575.98px) {
    .fs-lg {
      font-size: 28px !important; } }

.fs-xl {
  font-size: 36px !important; }
  @media (max-width: 1199.98px) {
    .fs-xl {
      font-size: 36px !important; } }
  @media (max-width: 991.98px) {
    .fs-xl {
      font-size: 36px !important; } }
  @media (max-width: 767.98px) {
    .fs-xl {
      font-size: 36px !important; } }
  @media (max-width: 575.98px) {
    .fs-xl {
      font-size: 36px !important; } }

.fs-xxl {
  font-size: 36px !important; }
  @media (max-width: 1199.98px) {
    .fs-xxl {
      font-size: 36px !important; } }
  @media (max-width: 991.98px) {
    .fs-xxl {
      font-size: 36px !important; } }
  @media (max-width: 767.98px) {
    .fs-xxl {
      font-size: 36px !important; } }
  @media (max-width: 575.98px) {
    .fs-xxl {
      font-size: 36px !important; } }

/*------- TITLES -------*/
.title-xs, .cms h6 {
  color: inherit;
  font-family: "graphik", sans-serif;
  font-weight: 400;
  line-height: 1.2;
  font-size: 16px; }
  @media (max-width: 1199.98px) {
    .title-xs, .cms h6 {
      font-size: 16px; } }
  @media (max-width: 991.98px) {
    .title-xs, .cms h6 {
      font-size: 16px; } }
  @media (max-width: 767.98px) {
    .title-xs, .cms h6 {
      font-size: 16px; } }
  @media (max-width: 575.98px) {
    .title-xs, .cms h6 {
      font-size: 16px; } }

.title-sm, .cms h5 {
  color: inherit;
  font-family: "graphik", sans-serif;
  font-weight: 400;
  line-height: 1.2;
  font-size: 18px; }
  @media (max-width: 1199.98px) {
    .title-sm, .cms h5 {
      font-size: 18px; } }
  @media (max-width: 991.98px) {
    .title-sm, .cms h5 {
      font-size: 18px; } }
  @media (max-width: 767.98px) {
    .title-sm, .cms h5 {
      font-size: 18px; } }
  @media (max-width: 575.98px) {
    .title-sm, .cms h5 {
      font-size: 18px; } }

.title-md, .cms h4 {
  color: inherit;
  font-family: "graphik", sans-serif;
  font-weight: 400;
  line-height: 1.2;
  font-size: 24px; }
  @media (max-width: 1199.98px) {
    .title-md, .cms h4 {
      font-size: 24px; } }
  @media (max-width: 991.98px) {
    .title-md, .cms h4 {
      font-size: 24px; } }
  @media (max-width: 767.98px) {
    .title-md, .cms h4 {
      font-size: 24px; } }
  @media (max-width: 575.98px) {
    .title-md, .cms h4 {
      font-size: 24px; } }

.title-lg, .cms h3 {
  color: inherit;
  font-family: "graphik", sans-serif;
  font-weight: 400;
  line-height: 1.2;
  font-size: 30px; }
  @media (max-width: 1199.98px) {
    .title-lg, .cms h3 {
      font-size: 30px; } }
  @media (max-width: 991.98px) {
    .title-lg, .cms h3 {
      font-size: 30px; } }
  @media (max-width: 767.98px) {
    .title-lg, .cms h3 {
      font-size: 30px; } }
  @media (max-width: 575.98px) {
    .title-lg, .cms h3 {
      font-size: 30px; } }

.title-xl {
  color: inherit;
  font-family: "graphik", sans-serif;
  font-weight: 400;
  line-height: 1.2;
  font-size: 50px; }
  @media (max-width: 1199.98px) {
    .title-xl {
      font-size: 50px; } }
  @media (max-width: 991.98px) {
    .title-xl {
      font-size: 42px; } }
  @media (max-width: 767.98px) {
    .title-xl {
      font-size: 42px; } }
  @media (max-width: 575.98px) {
    .title-xl {
      font-size: 32px; } }

.title-xxl, .cms h1 {
  color: inherit;
  font-family: "graphik", sans-serif;
  font-weight: 400;
  line-height: 1.2;
  font-size: 62px; }
  @media (max-width: 1199.98px) {
    .title-xxl, .cms h1 {
      font-size: 50px; } }
  @media (max-width: 991.98px) {
    .title-xxl, .cms h1 {
      font-size: 42px; } }
  @media (max-width: 767.98px) {
    .title-xxl, .cms h1 {
      font-size: 36px; } }
  @media (max-width: 575.98px) {
    .title-xxl, .cms h1 {
      font-size: 36px; } }

/*------- FONT-FAMILY -------*/
.ff-default {
  font-family: "graphik", sans-serif ; }

.ff-custom {
  font-family: "graphik", sans-serif ; }

.ff-extra {
  font-family: "canela", sans-serif ; }

/*------- FONT-WEIGHTS -------*/
.fw-100 {
  font-weight: 100 ; }

.fw-200 {
  font-weight: 200 ; }

.fw-300 {
  font-weight: 300 ; }

.fw-400 {
  font-weight: 400 ; }

.fw-500 {
  font-weight: 500 ; }

.fw-600 {
  font-weight: 600 ; }

.fw-700 {
  font-weight: 700 ; }

.fw-800 {
  font-weight: 800 ; }

.fw-900 {
  font-weight: 900 ; }

/*------- LINE-HEIGHTS -------*/
.lh-xs {
  line-height: 1 ; }

.lh-sm {
  line-height: 1.2 ; }

.lh-md {
  line-height: 1.5 ; }

.lh-lg {
  line-height: 1.6 ; }

.lh-xl {
  line-height: 1.8 ; }

.lh-xxl {
  line-height: 2 ; }

/*------- COLORS -------*/
.c-black {
  color: #000000 ; }

.c-very-dark-grey {
  color: #141414 ; }

.c-very-light-grey {
  color: #fafafa ; }

.c-white {
  color: #ffffff ; }

.c-grey {
  color: #f6f5f5 ; }

.c-light-grey {
  color: #dedede ; }

.c-dark-grey {
  color: #383f51 ; }

.c-beige {
  color: #f1e9db ; }

.c-orange {
  color: #ff563f ; }

/*------- LINKS -------*/
.link-white {
  color: #ffffff; }
  .link-white:hover {
    color: #dedede; }

.links-white a {
  color: #ffffff; }
  .links-white a:hover {
    color: #dedede; }

.link-black {
  color: #000000; }
  .link-black:hover {
    color: #f6f5f5; }

.links-black a {
  color: #000000; }
  .links-black a:hover {
    color: #f6f5f5; }

/*------- BUTTONS -------*/
.btn, .btn-black, .btn-white, .btn-xs, .btn-sm, .btn-md, .btn-lg {
  position: relative;
  font-family: "graphik", sans-serif;
  display: inline-block;
  padding: 15px 20px;
  font-size: 14px;
  background: #ffffff;
  color: #141414;
  border: 1px solid #dedede;
  border-radius: 0;
  transition: all 0.3s;
  box-shadow: none !important;
  white-space: inherit;
  cursor: pointer; }
  .btn:hover, .btn-black:hover, .btn-white:hover, .btn-xs:hover, .btn-sm:hover, .btn-md:hover, .btn-lg:hover {
    color: #141414; }
  .btn .icon, .btn-black .icon, .btn-white .icon, .btn-xs .icon, .btn-sm .icon, .btn-md .icon, .btn-lg .icon {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    transition: all 0.4s;
    width: 30px; }
    .btn .icon svg, .btn-black .icon svg, .btn-white .icon svg, .btn-xs .icon svg, .btn-sm .icon svg, .btn-md .icon svg, .btn-lg .icon svg, .btn .icon img, .btn-black .icon img, .btn-white .icon img, .btn-xs .icon img, .btn-sm .icon img, .btn-md .icon img, .btn-lg .icon img {
      width: 100%;
      max-height: 100%; }
  .btn.btn-icon-right, .btn-icon-right.btn-black, .btn-icon-right.btn-white, .btn-icon-right.btn-xs, .btn-icon-right.btn-sm, .btn-icon-right.btn-md, .btn-icon-right.btn-lg {
    text-align: right;
    padding-right: 60px; }
    .btn.btn-icon-right .icon, .btn-icon-right.btn-black .icon, .btn-icon-right.btn-white .icon, .btn-icon-right.btn-xs .icon, .btn-icon-right.btn-sm .icon, .btn-icon-right.btn-md .icon, .btn-icon-right.btn-lg .icon {
      right: 20px; }
  .btn.btn-icon-left, .btn-icon-left.btn-black, .btn-icon-left.btn-white, .btn-icon-left.btn-xs, .btn-icon-left.btn-sm, .btn-icon-left.btn-md, .btn-icon-left.btn-lg {
    text-align: left;
    padding-left: 60px; }
    .btn.btn-icon-left .icon, .btn-icon-left.btn-black .icon, .btn-icon-left.btn-white .icon, .btn-icon-left.btn-xs .icon, .btn-icon-left.btn-sm .icon, .btn-icon-left.btn-md .icon, .btn-icon-left.btn-lg .icon {
      left: 20px; }

.btn-black {
  background-color: #000000;
  border-color: #000000;
  color: #ffffff; }
  .btn-black:hover {
    color: #ffffff; }
  .btn-black .icon {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    transition: all 0.4s;
    width: 30px; }
    .btn-black .icon svg, .btn-black .icon img {
      width: 100%;
      max-height: 100%; }
  .btn-black.btn-icon-right {
    text-align: right;
    padding-right: 60px; }
    .btn-black.btn-icon-right .icon {
      right: 20px; }
  .btn-black.btn-icon-left {
    text-align: left;
    padding-left: 60px; }
    .btn-black.btn-icon-left .icon {
      left: 20px; }

.btn-white {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #000000; }
  .btn-white:hover {
    color: #000000; }
  .btn-white .icon {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    transition: all 0.4s;
    width: 30px; }
    .btn-white .icon svg, .btn-white .icon img {
      width: 100%;
      max-height: 100%; }
  .btn-white.btn-icon-right {
    text-align: right;
    padding-right: 60px; }
    .btn-white.btn-icon-right .icon {
      right: 20px; }
  .btn-white.btn-icon-left {
    text-align: left;
    padding-left: 60px; }
    .btn-white.btn-icon-left .icon {
      left: 20px; }

.btn-xs {
  padding: 2px 5px;
  font-size: 10px; }
  .btn-xs .icon {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    transition: all 0.4s;
    width: 10px; }
    .btn-xs .icon svg, .btn-xs .icon img {
      width: 100%;
      max-height: 100%; }
  .btn-xs.btn-icon-right {
    text-align: right;
    padding-right: 25px; }
    .btn-xs.btn-icon-right .icon {
      right: 5px; }
  .btn-xs.btn-icon-left {
    text-align: left;
    padding-left: 25px; }
    .btn-xs.btn-icon-left .icon {
      left: 5px; }

.btn-sm {
  padding: 5px 10px;
  font-size: 14px; }
  .btn-sm .icon {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    transition: all 0.4s;
    width: 20px; }
    .btn-sm .icon svg, .btn-sm .icon img {
      width: 100%;
      max-height: 100%; }
  .btn-sm.btn-icon-right {
    text-align: right;
    padding-right: 40px; }
    .btn-sm.btn-icon-right .icon {
      right: 10px; }
  .btn-sm.btn-icon-left {
    text-align: left;
    padding-left: 40px; }
    .btn-sm.btn-icon-left .icon {
      left: 10px; }

.btn-md {
  padding: 10px 20px;
  font-size: 16px; }
  .btn-md .icon {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    transition: all 0.4s;
    width: 30px; }
    .btn-md .icon svg, .btn-md .icon img {
      width: 100%;
      max-height: 100%; }
  .btn-md.btn-icon-right {
    text-align: right;
    padding-right: 60px; }
    .btn-md.btn-icon-right .icon {
      right: 20px; }
  .btn-md.btn-icon-left {
    text-align: left;
    padding-left: 60px; }
    .btn-md.btn-icon-left .icon {
      left: 20px; }

.btn-lg {
  padding: 20px 30px;
  font-size: 20px; }
  .btn-lg .icon {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    transition: all 0.4s;
    width: 50px; }
    .btn-lg .icon svg, .btn-lg .icon img {
      width: 100%;
      max-height: 100%; }
  .btn-lg.btn-icon-right {
    text-align: right;
    padding-right: 90px; }
    .btn-lg.btn-icon-right .icon {
      right: 30px; }
  .btn-lg.btn-icon-left {
    text-align: left;
    padding-left: 90px; }
    .btn-lg.btn-icon-left .icon {
      left: 30px; }

/*------- BACKGROUND-COLORS -------*/
.bg-black {
  background-color: #000000 ; }

.bg-very-dark-grey {
  background-color: #141414 ; }

.bg-very-light-grey {
  background-color: #fafafa ; }

.bg-white {
  background-color: #ffffff ; }

.bg-grey {
  background-color: #f6f5f5 ; }

.bg-light-grey {
  background-color: #dedede ; }

.bg-dark-grey {
  background-color: #383f51 ; }

.bg-beige {
  background-color: #f1e9db ; }

.bg-orange {
  background-color: #ff563f ; }

/*------- TEXT-ALIGN -------*/
.ta-r {
  text-align: right ; }

.ta-l {
  text-align: left ; }

.ta-c {
  text-align: center ; }

.ta-j {
  text-align: justify ; }

@media (max-width: 1199.98px) {
  .lg-down-ta-r {
    text-align: right; } }

@media (max-width: 991.98px) {
  .md-down-ta-r {
    text-align: right; } }

@media (max-width: 767.98px) {
  .sm-down-ta-r {
    text-align: right; } }

@media (max-width: 575.98px) {
  .xs-down-ta-r {
    text-align: right; } }

.xs-up-ta-r {
  text-align: right; }

@media (min-width: 576px) {
  .sm-up-ta-r {
    text-align: right; } }

@media (min-width: 768px) {
  .md-up-ta-r {
    text-align: right; } }

@media (min-width: 992px) {
  .lg-up-ta-r {
    text-align: right; } }

@media (min-width: 1200px) {
  .xl-up-ta-r {
    text-align: right; } }

@media (max-width: 1199.98px) {
  .lg-down-ta-l {
    text-align: left; } }

@media (max-width: 991.98px) {
  .md-down-ta-l {
    text-align: left; } }

@media (max-width: 767.98px) {
  .sm-down-ta-l {
    text-align: left; } }

@media (max-width: 575.98px) {
  .xs-down-ta-l {
    text-align: left; } }

.xs-up-ta-l {
  text-align: left; }

@media (min-width: 576px) {
  .sm-up-ta-l {
    text-align: left; } }

@media (min-width: 768px) {
  .md-up-ta-l {
    text-align: left; } }

@media (min-width: 992px) {
  .lg-up-ta-l {
    text-align: left; } }

@media (min-width: 1200px) {
  .xl-up-ta-l {
    text-align: left; } }

@media (max-width: 1199.98px) {
  .lg-down-ta-c {
    text-align: center; } }

@media (max-width: 991.98px) {
  .md-down-ta-c {
    text-align: center; } }

@media (max-width: 767.98px) {
  .sm-down-ta-c {
    text-align: center; } }

@media (max-width: 575.98px) {
  .xs-down-ta-c {
    text-align: center; } }

.xs-up-ta-c {
  text-align: center; }

@media (min-width: 576px) {
  .sm-up-ta-c {
    text-align: center; } }

@media (min-width: 768px) {
  .md-up-ta-c {
    text-align: center; } }

@media (min-width: 992px) {
  .lg-up-ta-c {
    text-align: center; } }

@media (min-width: 1200px) {
  .xl-up-ta-c {
    text-align: center; } }

@media (max-width: 1199.98px) {
  .lg-down-ta-j {
    text-align: justify; } }

@media (max-width: 991.98px) {
  .md-down-ta-j {
    text-align: justify; } }

@media (max-width: 767.98px) {
  .sm-down-ta-j {
    text-align: justify; } }

@media (max-width: 575.98px) {
  .xs-down-ta-j {
    text-align: justify; } }

.xs-up-ta-j {
  text-align: justify; }

@media (min-width: 576px) {
  .sm-up-ta-j {
    text-align: justify; } }

@media (min-width: 768px) {
  .md-up-ta-j {
    text-align: justify; } }

@media (min-width: 992px) {
  .lg-up-ta-j {
    text-align: justify; } }

@media (min-width: 1200px) {
  .xl-up-ta-j {
    text-align: justify; } }

/*------- LETTER SPACING -------*/
.ls-xs {
  letter-spacing: 1px ; }

.ls-sm {
  letter-spacing: 2px ; }

.ls-md {
  letter-spacing: 3px ; }

.ls-lg {
  letter-spacing: 4px ; }

.ls-xl {
  letter-spacing: 5px ; }

.ls-xxl {
  letter-spacing: 6px ; }

/*------- DISPLAYS -------*/
.d-none {
  display: none ; }

.d-i {
  display: inline ; }

.d-b {
  display: block ; }

.d-ib {
  display: inline-block ; }

.d-f {
  display: flex ; }

.d-if {
  display: inline-flex ; }

@media (max-width: 1199.98px) {
  .lg-down-d-none {
    display: none; } }

@media (max-width: 991.98px) {
  .md-down-d-none {
    display: none; } }

@media (max-width: 767.98px) {
  .sm-down-d-none {
    display: none; } }

@media (max-width: 575.98px) {
  .xs-down-d-none {
    display: none; } }

.xs-up-d-none {
  display: none; }

@media (min-width: 576px) {
  .sm-up-d-none {
    display: none; } }

@media (min-width: 768px) {
  .md-up-d-none {
    display: none; } }

@media (min-width: 992px) {
  .lg-up-d-none {
    display: none; } }

@media (min-width: 1200px) {
  .xl-up-d-none {
    display: none; } }

@media (max-width: 1199.98px) {
  .lg-down-d-i {
    display: inline; } }

@media (max-width: 991.98px) {
  .md-down-d-i {
    display: inline; } }

@media (max-width: 767.98px) {
  .sm-down-d-i {
    display: inline; } }

@media (max-width: 575.98px) {
  .xs-down-d-i {
    display: inline; } }

.xs-up-d-i {
  display: inline; }

@media (min-width: 576px) {
  .sm-up-d-i {
    display: inline; } }

@media (min-width: 768px) {
  .md-up-d-i {
    display: inline; } }

@media (min-width: 992px) {
  .lg-up-d-i {
    display: inline; } }

@media (min-width: 1200px) {
  .xl-up-d-i {
    display: inline; } }

@media (max-width: 1199.98px) {
  .lg-down-d-b {
    display: block; } }

@media (max-width: 991.98px) {
  .md-down-d-b {
    display: block; } }

@media (max-width: 767.98px) {
  .sm-down-d-b {
    display: block; } }

@media (max-width: 575.98px) {
  .xs-down-d-b {
    display: block; } }

.xs-up-d-b {
  display: block; }

@media (min-width: 576px) {
  .sm-up-d-b {
    display: block; } }

@media (min-width: 768px) {
  .md-up-d-b {
    display: block; } }

@media (min-width: 992px) {
  .lg-up-d-b {
    display: block; } }

@media (min-width: 1200px) {
  .xl-up-d-b {
    display: block; } }

@media (max-width: 1199.98px) {
  .lg-down-d-ib {
    display: inline-block; } }

@media (max-width: 991.98px) {
  .md-down-d-ib {
    display: inline-block; } }

@media (max-width: 767.98px) {
  .sm-down-d-ib {
    display: inline-block; } }

@media (max-width: 575.98px) {
  .xs-down-d-ib {
    display: inline-block; } }

.xs-up-d-ib {
  display: inline-block; }

@media (min-width: 576px) {
  .sm-up-d-ib {
    display: inline-block; } }

@media (min-width: 768px) {
  .md-up-d-ib {
    display: inline-block; } }

@media (min-width: 992px) {
  .lg-up-d-ib {
    display: inline-block; } }

@media (min-width: 1200px) {
  .xl-up-d-ib {
    display: inline-block; } }

@media (max-width: 1199.98px) {
  .lg-down-d-f {
    display: flex; } }

@media (max-width: 991.98px) {
  .md-down-d-f {
    display: flex; } }

@media (max-width: 767.98px) {
  .sm-down-d-f {
    display: flex; } }

@media (max-width: 575.98px) {
  .xs-down-d-f {
    display: flex; } }

.xs-up-d-f {
  display: flex; }

@media (min-width: 576px) {
  .sm-up-d-f {
    display: flex; } }

@media (min-width: 768px) {
  .md-up-d-f {
    display: flex; } }

@media (min-width: 992px) {
  .lg-up-d-f {
    display: flex; } }

@media (min-width: 1200px) {
  .xl-up-d-f {
    display: flex; } }

@media (max-width: 1199.98px) {
  .lg-down-d-if {
    display: inline-flex; } }

@media (max-width: 991.98px) {
  .md-down-d-if {
    display: inline-flex; } }

@media (max-width: 767.98px) {
  .sm-down-d-if {
    display: inline-flex; } }

@media (max-width: 575.98px) {
  .xs-down-d-if {
    display: inline-flex; } }

.xs-up-d-if {
  display: inline-flex; }

@media (min-width: 576px) {
  .sm-up-d-if {
    display: inline-flex; } }

@media (min-width: 768px) {
  .md-up-d-if {
    display: inline-flex; } }

@media (min-width: 992px) {
  .lg-up-d-if {
    display: inline-flex; } }

@media (min-width: 1200px) {
  .xl-up-d-if {
    display: inline-flex; } }

/*------- SECTIONS -------*/
.section {
  position: relative;
  padding-top: 100px !important;
  padding-bottom: 100px !important; }
  @media (max-width: 1199.98px) {
    .section {
      padding-top: 100px !important;
      padding-bottom: 100px !important; } }
  @media (max-width: 991.98px) {
    .section {
      padding-top: 100px !important;
      padding-bottom: 100px !important; } }
  @media (max-width: 767.98px) {
    .section {
      padding-top: 100px !important;
      padding-bottom: 100px !important; } }
  @media (max-width: 575.98px) {
    .section {
      padding-top: 100px !important;
      padding-bottom: 100px !important; } }

/*------- BG IMG -------*/
.bg-img, .bg-img-square, .bg-img-almost-square, .bg-img-landscape, .bg-img-landscape-sm, .bg-img-portrait {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #000; }
  .bg-img:after, .bg-img-square:after, .bg-img-almost-square:after, .bg-img-landscape:after, .bg-img-landscape-sm:after, .bg-img-portrait:after {
    content: '';
    display: block; }

.bg-img-square:after {
  padding-bottom: 100%; }

.bg-img-almost-square:after {
  padding-bottom: 85%; }

.bg-img-landscape:after {
  padding-bottom: 70%; }

.bg-img-landscape-sm:after {
  padding-bottom: 60%; }

.bg-img-portrait:after {
  padding-bottom: 130%; }

/*------- MARGINS & PADDINGS -------*/
.m-0 {
  margin: 0 !important; }

.mt-0 {
  margin-top: 0 !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.ml-0 {
  margin-left: 0 !important; }

.mr-0 {
  margin-right: 0 !important; }

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.p-0 {
  padding: 0 !important; }

.pt-0 {
  padding-top: 0 !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.pl-0 {
  padding-left: 0 !important; }

.pr-0 {
  padding-right: 0 !important; }

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.m-xs {
  margin: 5px !important; }

.mt-xs {
  margin-top: 5px !important; }

.mb-xs {
  margin-bottom: 5px !important; }

.my-xs {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.ml-xs {
  margin-left: 5px !important; }

.mr-xs {
  margin-right: 5px !important; }

.mx-xs {
  margin-left: 5px !important;
  margin-right: 5px !important; }

.p-xs {
  padding: 5px !important; }

.pt-xs {
  padding-top: 5px !important; }

.pb-xs {
  padding-bottom: 5px !important; }

.py-xs {
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

.pl-xs {
  padding-left: 5px !important; }

.pr-xs {
  padding-right: 5px !important; }

.px-xs {
  padding-left: 5px !important;
  padding-right: 5px !important; }

.m-sm {
  margin: 15px !important; }

.mt-sm {
  margin-top: 15px !important; }

.mb-sm {
  margin-bottom: 15px !important; }

.my-sm {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.ml-sm {
  margin-left: 15px !important; }

.mr-sm {
  margin-right: 15px !important; }

.mx-sm {
  margin-left: 15px !important;
  margin-right: 15px !important; }

.p-sm {
  padding: 15px !important; }

.pt-sm {
  padding-top: 15px !important; }

.pb-sm {
  padding-bottom: 15px !important; }

.py-sm {
  padding-top: 15px !important;
  padding-bottom: 15px !important; }

.pl-sm {
  padding-left: 15px !important; }

.pr-sm {
  padding-right: 15px !important; }

.px-sm {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.m-md {
  margin: 30px !important; }

.mt-md {
  margin-top: 30px !important; }

.mb-md {
  margin-bottom: 30px !important; }

.my-md {
  margin-top: 30px !important;
  margin-bottom: 30px !important; }

.ml-md {
  margin-left: 30px !important; }

.mr-md {
  margin-right: 30px !important; }

.mx-md {
  margin-left: 30px !important;
  margin-right: 30px !important; }

.p-md {
  padding: 30px !important; }

.pt-md {
  padding-top: 30px !important; }

.pb-md {
  padding-bottom: 30px !important; }

.py-md {
  padding-top: 30px !important;
  padding-bottom: 30px !important; }

.pl-md {
  padding-left: 30px !important; }

.pr-md {
  padding-right: 30px !important; }

.px-md {
  padding-left: 30px !important;
  padding-right: 30px !important; }

.m-lg {
  margin: 50px !important; }

.mt-lg {
  margin-top: 50px !important; }

.mb-lg {
  margin-bottom: 50px !important; }

.my-lg {
  margin-top: 50px !important;
  margin-bottom: 50px !important; }

.ml-lg {
  margin-left: 50px !important; }

.mr-lg {
  margin-right: 50px !important; }

.mx-lg {
  margin-left: 50px !important;
  margin-right: 50px !important; }

.p-lg {
  padding: 50px !important; }

.pt-lg {
  padding-top: 50px !important; }

.pb-lg {
  padding-bottom: 50px !important; }

.py-lg {
  padding-top: 50px !important;
  padding-bottom: 50px !important; }

.pl-lg {
  padding-left: 50px !important; }

.pr-lg {
  padding-right: 50px !important; }

.px-lg {
  padding-left: 50px !important;
  padding-right: 50px !important; }

.m-xl {
  margin: 70px !important; }

.mt-xl {
  margin-top: 70px !important; }

.mb-xl {
  margin-bottom: 70px !important; }

.my-xl {
  margin-top: 70px !important;
  margin-bottom: 70px !important; }

.ml-xl {
  margin-left: 70px !important; }

.mr-xl {
  margin-right: 70px !important; }

.mx-xl {
  margin-left: 70px !important;
  margin-right: 70px !important; }

.p-xl {
  padding: 70px !important; }

.pt-xl {
  padding-top: 70px !important; }

.pb-xl {
  padding-bottom: 70px !important; }

.py-xl {
  padding-top: 70px !important;
  padding-bottom: 70px !important; }

.pl-xl {
  padding-left: 70px !important; }

.pr-xl {
  padding-right: 70px !important; }

.px-xl {
  padding-left: 70px !important;
  padding-right: 70px !important; }

.m-xxl {
  margin: 140px !important; }

.mt-xxl {
  margin-top: 140px !important; }

.mb-xxl {
  margin-bottom: 140px !important; }

.my-xxl {
  margin-top: 140px !important;
  margin-bottom: 140px !important; }

.ml-xxl {
  margin-left: 140px !important; }

.mr-xxl {
  margin-right: 140px !important; }

.mx-xxl {
  margin-left: 140px !important;
  margin-right: 140px !important; }

.p-xxl {
  padding: 140px !important; }

.pt-xxl {
  padding-top: 140px !important; }

.pb-xxl {
  padding-bottom: 140px !important; }

.py-xxl {
  padding-top: 140px !important;
  padding-bottom: 140px !important; }

.pl-xxl {
  padding-left: 140px !important; }

.pr-xxl {
  padding-right: 140px !important; }

.px-xxl {
  padding-left: 140px !important;
  padding-right: 140px !important; }

.m-0-child > * {
  margin: 0 !important; }

.m-0-child-not-last > *:not(:last-child) {
  margin: 0 !important; }

.mt-0-child > * {
  margin-top: 0 !important; }

.mt-0-child-not-last > *:not(:last-child) {
  margin-top: 0 !important; }

.mb-0-child > * {
  margin-bottom: 0 !important; }

.mb-0-child-not-last > *:not(:last-child) {
  margin-bottom: 0 !important; }

.my-0-child > * {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.my-0-child-not-last > *:not(:last-child) {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.ml-0-child > * {
  margin-left: 0 !important; }

.ml-0-child-not-last > *:not(:last-child) {
  margin-left: 0 !important; }

.mr-0-child > * {
  margin-right: 0 !important; }

.mr-0-child-not-last > *:not(:last-child) {
  margin-right: 0 !important; }

.mx-0-child > * {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.mx-0-child-not-last > *:not(:last-child) {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.p-0-child > * {
  padding: 0 !important; }

.p-0-child-not-last > *:not(:last-child) {
  padding: 0 !important; }

.pt-0-child > * {
  padding-top: 0 !important; }

.pt-0-child-not-last > *:not(:last-child) {
  padding-top: 0 !important; }

.pb-0-child > * {
  padding-bottom: 0 !important; }

.pb-0-child-not-last > *:not(:last-child) {
  padding-bottom: 0 !important; }

.py-0-child > * {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.py-0-child-not-last > *:not(:last-child) {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.pl-0-child > * {
  padding-left: 0 !important; }

.pl-0-child-not-last > *:not(:last-child) {
  padding-left: 0 !important; }

.pr-0-child > * {
  padding-right: 0 !important; }

.pr-0-child-not-last > *:not(:last-child) {
  padding-right: 0 !important; }

.px-0-child > * {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.px-0-child-not-last > *:not(:last-child) {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.m-xs-child > * {
  margin: 5px !important; }

.m-xs-child-not-last > *:not(:last-child) {
  margin: 5px !important; }

.mt-xs-child > * {
  margin-top: 5px !important; }

.mt-xs-child-not-last > *:not(:last-child) {
  margin-top: 5px !important; }

.mb-xs-child > * {
  margin-bottom: 5px !important; }

.mb-xs-child-not-last > *:not(:last-child) {
  margin-bottom: 5px !important; }

.my-xs-child > * {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.my-xs-child-not-last > *:not(:last-child) {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.ml-xs-child > * {
  margin-left: 5px !important; }

.ml-xs-child-not-last > *:not(:last-child) {
  margin-left: 5px !important; }

.mr-xs-child > * {
  margin-right: 5px !important; }

.mr-xs-child-not-last > *:not(:last-child) {
  margin-right: 5px !important; }

.mx-xs-child > * {
  margin-left: 5px !important;
  margin-right: 5px !important; }

.mx-xs-child-not-last > *:not(:last-child) {
  margin-left: 5px !important;
  margin-right: 5px !important; }

.p-xs-child > * {
  padding: 5px !important; }

.p-xs-child-not-last > *:not(:last-child) {
  padding: 5px !important; }

.pt-xs-child > * {
  padding-top: 5px !important; }

.pt-xs-child-not-last > *:not(:last-child) {
  padding-top: 5px !important; }

.pb-xs-child > * {
  padding-bottom: 5px !important; }

.pb-xs-child-not-last > *:not(:last-child) {
  padding-bottom: 5px !important; }

.py-xs-child > * {
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

.py-xs-child-not-last > *:not(:last-child) {
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

.pl-xs-child > * {
  padding-left: 5px !important; }

.pl-xs-child-not-last > *:not(:last-child) {
  padding-left: 5px !important; }

.pr-xs-child > * {
  padding-right: 5px !important; }

.pr-xs-child-not-last > *:not(:last-child) {
  padding-right: 5px !important; }

.px-xs-child > * {
  padding-left: 5px !important;
  padding-right: 5px !important; }

.px-xs-child-not-last > *:not(:last-child) {
  padding-left: 5px !important;
  padding-right: 5px !important; }

.m-sm-child > * {
  margin: 15px !important; }

.m-sm-child-not-last > *:not(:last-child) {
  margin: 15px !important; }

.mt-sm-child > * {
  margin-top: 15px !important; }

.mt-sm-child-not-last > *:not(:last-child) {
  margin-top: 15px !important; }

.mb-sm-child > * {
  margin-bottom: 15px !important; }

.mb-sm-child-not-last > *:not(:last-child) {
  margin-bottom: 15px !important; }

.my-sm-child > * {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.my-sm-child-not-last > *:not(:last-child) {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.ml-sm-child > * {
  margin-left: 15px !important; }

.ml-sm-child-not-last > *:not(:last-child) {
  margin-left: 15px !important; }

.mr-sm-child > * {
  margin-right: 15px !important; }

.mr-sm-child-not-last > *:not(:last-child) {
  margin-right: 15px !important; }

.mx-sm-child > * {
  margin-left: 15px !important;
  margin-right: 15px !important; }

.mx-sm-child-not-last > *:not(:last-child) {
  margin-left: 15px !important;
  margin-right: 15px !important; }

.p-sm-child > * {
  padding: 15px !important; }

.p-sm-child-not-last > *:not(:last-child) {
  padding: 15px !important; }

.pt-sm-child > * {
  padding-top: 15px !important; }

.pt-sm-child-not-last > *:not(:last-child) {
  padding-top: 15px !important; }

.pb-sm-child > * {
  padding-bottom: 15px !important; }

.pb-sm-child-not-last > *:not(:last-child) {
  padding-bottom: 15px !important; }

.py-sm-child > * {
  padding-top: 15px !important;
  padding-bottom: 15px !important; }

.py-sm-child-not-last > *:not(:last-child) {
  padding-top: 15px !important;
  padding-bottom: 15px !important; }

.pl-sm-child > * {
  padding-left: 15px !important; }

.pl-sm-child-not-last > *:not(:last-child) {
  padding-left: 15px !important; }

.pr-sm-child > * {
  padding-right: 15px !important; }

.pr-sm-child-not-last > *:not(:last-child) {
  padding-right: 15px !important; }

.px-sm-child > * {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.px-sm-child-not-last > *:not(:last-child) {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.m-md-child > * {
  margin: 30px !important; }

.m-md-child-not-last > *:not(:last-child) {
  margin: 30px !important; }

.mt-md-child > * {
  margin-top: 30px !important; }

.mt-md-child-not-last > *:not(:last-child) {
  margin-top: 30px !important; }

.mb-md-child > * {
  margin-bottom: 30px !important; }

.mb-md-child-not-last > *:not(:last-child) {
  margin-bottom: 30px !important; }

.my-md-child > * {
  margin-top: 30px !important;
  margin-bottom: 30px !important; }

.my-md-child-not-last > *:not(:last-child) {
  margin-top: 30px !important;
  margin-bottom: 30px !important; }

.ml-md-child > * {
  margin-left: 30px !important; }

.ml-md-child-not-last > *:not(:last-child) {
  margin-left: 30px !important; }

.mr-md-child > * {
  margin-right: 30px !important; }

.mr-md-child-not-last > *:not(:last-child) {
  margin-right: 30px !important; }

.mx-md-child > * {
  margin-left: 30px !important;
  margin-right: 30px !important; }

.mx-md-child-not-last > *:not(:last-child) {
  margin-left: 30px !important;
  margin-right: 30px !important; }

.p-md-child > * {
  padding: 30px !important; }

.p-md-child-not-last > *:not(:last-child) {
  padding: 30px !important; }

.pt-md-child > * {
  padding-top: 30px !important; }

.pt-md-child-not-last > *:not(:last-child) {
  padding-top: 30px !important; }

.pb-md-child > * {
  padding-bottom: 30px !important; }

.pb-md-child-not-last > *:not(:last-child) {
  padding-bottom: 30px !important; }

.py-md-child > * {
  padding-top: 30px !important;
  padding-bottom: 30px !important; }

.py-md-child-not-last > *:not(:last-child) {
  padding-top: 30px !important;
  padding-bottom: 30px !important; }

.pl-md-child > * {
  padding-left: 30px !important; }

.pl-md-child-not-last > *:not(:last-child) {
  padding-left: 30px !important; }

.pr-md-child > * {
  padding-right: 30px !important; }

.pr-md-child-not-last > *:not(:last-child) {
  padding-right: 30px !important; }

.px-md-child > * {
  padding-left: 30px !important;
  padding-right: 30px !important; }

.px-md-child-not-last > *:not(:last-child) {
  padding-left: 30px !important;
  padding-right: 30px !important; }

.m-lg-child > * {
  margin: 50px !important; }

.m-lg-child-not-last > *:not(:last-child) {
  margin: 50px !important; }

.mt-lg-child > * {
  margin-top: 50px !important; }

.mt-lg-child-not-last > *:not(:last-child) {
  margin-top: 50px !important; }

.mb-lg-child > * {
  margin-bottom: 50px !important; }

.mb-lg-child-not-last > *:not(:last-child) {
  margin-bottom: 50px !important; }

.my-lg-child > * {
  margin-top: 50px !important;
  margin-bottom: 50px !important; }

.my-lg-child-not-last > *:not(:last-child) {
  margin-top: 50px !important;
  margin-bottom: 50px !important; }

.ml-lg-child > * {
  margin-left: 50px !important; }

.ml-lg-child-not-last > *:not(:last-child) {
  margin-left: 50px !important; }

.mr-lg-child > * {
  margin-right: 50px !important; }

.mr-lg-child-not-last > *:not(:last-child) {
  margin-right: 50px !important; }

.mx-lg-child > * {
  margin-left: 50px !important;
  margin-right: 50px !important; }

.mx-lg-child-not-last > *:not(:last-child) {
  margin-left: 50px !important;
  margin-right: 50px !important; }

.p-lg-child > * {
  padding: 50px !important; }

.p-lg-child-not-last > *:not(:last-child) {
  padding: 50px !important; }

.pt-lg-child > * {
  padding-top: 50px !important; }

.pt-lg-child-not-last > *:not(:last-child) {
  padding-top: 50px !important; }

.pb-lg-child > * {
  padding-bottom: 50px !important; }

.pb-lg-child-not-last > *:not(:last-child) {
  padding-bottom: 50px !important; }

.py-lg-child > * {
  padding-top: 50px !important;
  padding-bottom: 50px !important; }

.py-lg-child-not-last > *:not(:last-child) {
  padding-top: 50px !important;
  padding-bottom: 50px !important; }

.pl-lg-child > * {
  padding-left: 50px !important; }

.pl-lg-child-not-last > *:not(:last-child) {
  padding-left: 50px !important; }

.pr-lg-child > * {
  padding-right: 50px !important; }

.pr-lg-child-not-last > *:not(:last-child) {
  padding-right: 50px !important; }

.px-lg-child > * {
  padding-left: 50px !important;
  padding-right: 50px !important; }

.px-lg-child-not-last > *:not(:last-child) {
  padding-left: 50px !important;
  padding-right: 50px !important; }

.m-xl-child > * {
  margin: 70px !important; }

.m-xl-child-not-last > *:not(:last-child) {
  margin: 70px !important; }

.mt-xl-child > * {
  margin-top: 70px !important; }

.mt-xl-child-not-last > *:not(:last-child) {
  margin-top: 70px !important; }

.mb-xl-child > * {
  margin-bottom: 70px !important; }

.mb-xl-child-not-last > *:not(:last-child) {
  margin-bottom: 70px !important; }

.my-xl-child > * {
  margin-top: 70px !important;
  margin-bottom: 70px !important; }

.my-xl-child-not-last > *:not(:last-child) {
  margin-top: 70px !important;
  margin-bottom: 70px !important; }

.ml-xl-child > * {
  margin-left: 70px !important; }

.ml-xl-child-not-last > *:not(:last-child) {
  margin-left: 70px !important; }

.mr-xl-child > * {
  margin-right: 70px !important; }

.mr-xl-child-not-last > *:not(:last-child) {
  margin-right: 70px !important; }

.mx-xl-child > * {
  margin-left: 70px !important;
  margin-right: 70px !important; }

.mx-xl-child-not-last > *:not(:last-child) {
  margin-left: 70px !important;
  margin-right: 70px !important; }

.p-xl-child > * {
  padding: 70px !important; }

.p-xl-child-not-last > *:not(:last-child) {
  padding: 70px !important; }

.pt-xl-child > * {
  padding-top: 70px !important; }

.pt-xl-child-not-last > *:not(:last-child) {
  padding-top: 70px !important; }

.pb-xl-child > * {
  padding-bottom: 70px !important; }

.pb-xl-child-not-last > *:not(:last-child) {
  padding-bottom: 70px !important; }

.py-xl-child > * {
  padding-top: 70px !important;
  padding-bottom: 70px !important; }

.py-xl-child-not-last > *:not(:last-child) {
  padding-top: 70px !important;
  padding-bottom: 70px !important; }

.pl-xl-child > * {
  padding-left: 70px !important; }

.pl-xl-child-not-last > *:not(:last-child) {
  padding-left: 70px !important; }

.pr-xl-child > * {
  padding-right: 70px !important; }

.pr-xl-child-not-last > *:not(:last-child) {
  padding-right: 70px !important; }

.px-xl-child > * {
  padding-left: 70px !important;
  padding-right: 70px !important; }

.px-xl-child-not-last > *:not(:last-child) {
  padding-left: 70px !important;
  padding-right: 70px !important; }

.m-xxl-child > * {
  margin: 140px !important; }

.m-xxl-child-not-last > *:not(:last-child) {
  margin: 140px !important; }

.mt-xxl-child > * {
  margin-top: 140px !important; }

.mt-xxl-child-not-last > *:not(:last-child) {
  margin-top: 140px !important; }

.mb-xxl-child > * {
  margin-bottom: 140px !important; }

.mb-xxl-child-not-last > *:not(:last-child) {
  margin-bottom: 140px !important; }

.my-xxl-child > * {
  margin-top: 140px !important;
  margin-bottom: 140px !important; }

.my-xxl-child-not-last > *:not(:last-child) {
  margin-top: 140px !important;
  margin-bottom: 140px !important; }

.ml-xxl-child > * {
  margin-left: 140px !important; }

.ml-xxl-child-not-last > *:not(:last-child) {
  margin-left: 140px !important; }

.mr-xxl-child > * {
  margin-right: 140px !important; }

.mr-xxl-child-not-last > *:not(:last-child) {
  margin-right: 140px !important; }

.mx-xxl-child > * {
  margin-left: 140px !important;
  margin-right: 140px !important; }

.mx-xxl-child-not-last > *:not(:last-child) {
  margin-left: 140px !important;
  margin-right: 140px !important; }

.p-xxl-child > * {
  padding: 140px !important; }

.p-xxl-child-not-last > *:not(:last-child) {
  padding: 140px !important; }

.pt-xxl-child > * {
  padding-top: 140px !important; }

.pt-xxl-child-not-last > *:not(:last-child) {
  padding-top: 140px !important; }

.pb-xxl-child > * {
  padding-bottom: 140px !important; }

.pb-xxl-child-not-last > *:not(:last-child) {
  padding-bottom: 140px !important; }

.py-xxl-child > * {
  padding-top: 140px !important;
  padding-bottom: 140px !important; }

.py-xxl-child-not-last > *:not(:last-child) {
  padding-top: 140px !important;
  padding-bottom: 140px !important; }

.pl-xxl-child > * {
  padding-left: 140px !important; }

.pl-xxl-child-not-last > *:not(:last-child) {
  padding-left: 140px !important; }

.pr-xxl-child > * {
  padding-right: 140px !important; }

.pr-xxl-child-not-last > *:not(:last-child) {
  padding-right: 140px !important; }

.px-xxl-child > * {
  padding-left: 140px !important;
  padding-right: 140px !important; }

.px-xxl-child-not-last > *:not(:last-child) {
  padding-left: 140px !important;
  padding-right: 140px !important; }

@media (max-width: 1199.98px) {
  .lg-down-m-0 {
    margin: 0 !important; }
  .lg-down-m-0-child > * {
    margin: 0 !important; }
  .lg-down-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mt-0 {
    margin-top: 0 !important; }
  .lg-down-mt-0-child > * {
    margin-top: 0 !important; }
  .lg-down-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mb-0 {
    margin-bottom: 0 !important; }
  .lg-down-mb-0-child > * {
    margin-bottom: 0 !important; }
  .lg-down-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (max-width: 1199.98px) {
  .lg-down-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .lg-down-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .lg-down-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (max-width: 1199.98px) {
  .lg-down-ml-0 {
    margin-left: 0 !important; }
  .lg-down-ml-0-child > * {
    margin-left: 0 !important; }
  .lg-down-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mr-0 {
    margin-right: 0 !important; }
  .lg-down-mr-0-child > * {
    margin-right: 0 !important; }
  .lg-down-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .lg-down-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .lg-down-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (max-width: 1199.98px) {
  .lg-down-p-0 {
    padding: 0 !important; }
  .lg-down-p-0-child > * {
    padding: 0 !important; }
  .lg-down-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pt-0 {
    padding-top: 0 !important; }
  .lg-down-pt-0-child > * {
    padding-top: 0 !important; }
  .lg-down-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pb-0 {
    padding-bottom: 0 !important; }
  .lg-down-pb-0-child > * {
    padding-bottom: 0 !important; }
  .lg-down-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (max-width: 1199.98px) {
  .lg-down-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .lg-down-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .lg-down-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pl-0 {
    padding-left: 0 !important; }
  .lg-down-pl-0-child > * {
    padding-left: 0 !important; }
  .lg-down-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pr-0 {
    padding-right: 0 !important; }
  .lg-down-pr-0-child > * {
    padding-right: 0 !important; }
  .lg-down-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (max-width: 1199.98px) {
  .lg-down-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .lg-down-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .lg-down-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (max-width: 1199.98px) {
  .lg-down-m-xs {
    margin: 5px !important; }
  .lg-down-m-xs-child > * {
    margin: 5px !important; }
  .lg-down-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mt-xs {
    margin-top: 5px !important; }
  .lg-down-mt-xs-child > * {
    margin-top: 5px !important; }
  .lg-down-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mb-xs {
    margin-bottom: 5px !important; }
  .lg-down-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .lg-down-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .lg-down-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .lg-down-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-ml-xs {
    margin-left: 5px !important; }
  .lg-down-ml-xs-child > * {
    margin-left: 5px !important; }
  .lg-down-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mr-xs {
    margin-right: 5px !important; }
  .lg-down-mr-xs-child > * {
    margin-right: 5px !important; }
  .lg-down-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .lg-down-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .lg-down-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-p-xs {
    padding: 5px !important; }
  .lg-down-p-xs-child > * {
    padding: 5px !important; }
  .lg-down-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pt-xs {
    padding-top: 5px !important; }
  .lg-down-pt-xs-child > * {
    padding-top: 5px !important; }
  .lg-down-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pb-xs {
    padding-bottom: 5px !important; }
  .lg-down-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .lg-down-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .lg-down-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .lg-down-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pl-xs {
    padding-left: 5px !important; }
  .lg-down-pl-xs-child > * {
    padding-left: 5px !important; }
  .lg-down-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pr-xs {
    padding-right: 5px !important; }
  .lg-down-pr-xs-child > * {
    padding-right: 5px !important; }
  .lg-down-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .lg-down-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .lg-down-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-m-sm {
    margin: 15px !important; }
  .lg-down-m-sm-child > * {
    margin: 15px !important; }
  .lg-down-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mt-sm {
    margin-top: 15px !important; }
  .lg-down-mt-sm-child > * {
    margin-top: 15px !important; }
  .lg-down-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mb-sm {
    margin-bottom: 15px !important; }
  .lg-down-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .lg-down-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .lg-down-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .lg-down-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-ml-sm {
    margin-left: 15px !important; }
  .lg-down-ml-sm-child > * {
    margin-left: 15px !important; }
  .lg-down-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mr-sm {
    margin-right: 15px !important; }
  .lg-down-mr-sm-child > * {
    margin-right: 15px !important; }
  .lg-down-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .lg-down-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .lg-down-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-p-sm {
    padding: 15px !important; }
  .lg-down-p-sm-child > * {
    padding: 15px !important; }
  .lg-down-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pt-sm {
    padding-top: 15px !important; }
  .lg-down-pt-sm-child > * {
    padding-top: 15px !important; }
  .lg-down-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pb-sm {
    padding-bottom: 15px !important; }
  .lg-down-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .lg-down-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .lg-down-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .lg-down-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pl-sm {
    padding-left: 15px !important; }
  .lg-down-pl-sm-child > * {
    padding-left: 15px !important; }
  .lg-down-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pr-sm {
    padding-right: 15px !important; }
  .lg-down-pr-sm-child > * {
    padding-right: 15px !important; }
  .lg-down-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .lg-down-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .lg-down-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-m-md {
    margin: 30px !important; }
  .lg-down-m-md-child > * {
    margin: 30px !important; }
  .lg-down-m-md-child-not-last > *:not(:last-child) {
    margin: 30px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mt-md {
    margin-top: 30px !important; }
  .lg-down-mt-md-child > * {
    margin-top: 30px !important; }
  .lg-down-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mb-md {
    margin-bottom: 30px !important; }
  .lg-down-mb-md-child > * {
    margin-bottom: 30px !important; }
  .lg-down-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 30px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-my-md {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .lg-down-my-md-child > * {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .lg-down-my-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important;
    margin-bottom: 30px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-ml-md {
    margin-left: 30px !important; }
  .lg-down-ml-md-child > * {
    margin-left: 30px !important; }
  .lg-down-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mr-md {
    margin-right: 30px !important; }
  .lg-down-mr-md-child > * {
    margin-right: 30px !important; }
  .lg-down-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 30px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mx-md {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .lg-down-mx-md-child > * {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .lg-down-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important;
    margin-right: 30px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-p-md {
    padding: 30px !important; }
  .lg-down-p-md-child > * {
    padding: 30px !important; }
  .lg-down-p-md-child-not-last > *:not(:last-child) {
    padding: 30px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pt-md {
    padding-top: 30px !important; }
  .lg-down-pt-md-child > * {
    padding-top: 30px !important; }
  .lg-down-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pb-md {
    padding-bottom: 30px !important; }
  .lg-down-pb-md-child > * {
    padding-bottom: 30px !important; }
  .lg-down-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 30px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-py-md {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .lg-down-py-md-child > * {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .lg-down-py-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important;
    padding-bottom: 30px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pl-md {
    padding-left: 30px !important; }
  .lg-down-pl-md-child > * {
    padding-left: 30px !important; }
  .lg-down-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pr-md {
    padding-right: 30px !important; }
  .lg-down-pr-md-child > * {
    padding-right: 30px !important; }
  .lg-down-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 30px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-px-md {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .lg-down-px-md-child > * {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .lg-down-px-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-m-lg {
    margin: 50px !important; }
  .lg-down-m-lg-child > * {
    margin: 50px !important; }
  .lg-down-m-lg-child-not-last > *:not(:last-child) {
    margin: 50px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mt-lg {
    margin-top: 50px !important; }
  .lg-down-mt-lg-child > * {
    margin-top: 50px !important; }
  .lg-down-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mb-lg {
    margin-bottom: 50px !important; }
  .lg-down-mb-lg-child > * {
    margin-bottom: 50px !important; }
  .lg-down-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 50px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-my-lg {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .lg-down-my-lg-child > * {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .lg-down-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important;
    margin-bottom: 50px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-ml-lg {
    margin-left: 50px !important; }
  .lg-down-ml-lg-child > * {
    margin-left: 50px !important; }
  .lg-down-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mr-lg {
    margin-right: 50px !important; }
  .lg-down-mr-lg-child > * {
    margin-right: 50px !important; }
  .lg-down-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 50px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mx-lg {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .lg-down-mx-lg-child > * {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .lg-down-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important;
    margin-right: 50px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-p-lg {
    padding: 50px !important; }
  .lg-down-p-lg-child > * {
    padding: 50px !important; }
  .lg-down-p-lg-child-not-last > *:not(:last-child) {
    padding: 50px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pt-lg {
    padding-top: 50px !important; }
  .lg-down-pt-lg-child > * {
    padding-top: 50px !important; }
  .lg-down-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pb-lg {
    padding-bottom: 50px !important; }
  .lg-down-pb-lg-child > * {
    padding-bottom: 50px !important; }
  .lg-down-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 50px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-py-lg {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .lg-down-py-lg-child > * {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .lg-down-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important;
    padding-bottom: 50px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pl-lg {
    padding-left: 50px !important; }
  .lg-down-pl-lg-child > * {
    padding-left: 50px !important; }
  .lg-down-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pr-lg {
    padding-right: 50px !important; }
  .lg-down-pr-lg-child > * {
    padding-right: 50px !important; }
  .lg-down-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 50px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-px-lg {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .lg-down-px-lg-child > * {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .lg-down-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-m-xl {
    margin: 70px !important; }
  .lg-down-m-xl-child > * {
    margin: 70px !important; }
  .lg-down-m-xl-child-not-last > *:not(:last-child) {
    margin: 70px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mt-xl {
    margin-top: 70px !important; }
  .lg-down-mt-xl-child > * {
    margin-top: 70px !important; }
  .lg-down-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mb-xl {
    margin-bottom: 70px !important; }
  .lg-down-mb-xl-child > * {
    margin-bottom: 70px !important; }
  .lg-down-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 70px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-my-xl {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .lg-down-my-xl-child > * {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .lg-down-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important;
    margin-bottom: 70px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-ml-xl {
    margin-left: 70px !important; }
  .lg-down-ml-xl-child > * {
    margin-left: 70px !important; }
  .lg-down-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mr-xl {
    margin-right: 70px !important; }
  .lg-down-mr-xl-child > * {
    margin-right: 70px !important; }
  .lg-down-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 70px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mx-xl {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .lg-down-mx-xl-child > * {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .lg-down-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important;
    margin-right: 70px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-p-xl {
    padding: 70px !important; }
  .lg-down-p-xl-child > * {
    padding: 70px !important; }
  .lg-down-p-xl-child-not-last > *:not(:last-child) {
    padding: 70px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pt-xl {
    padding-top: 70px !important; }
  .lg-down-pt-xl-child > * {
    padding-top: 70px !important; }
  .lg-down-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pb-xl {
    padding-bottom: 70px !important; }
  .lg-down-pb-xl-child > * {
    padding-bottom: 70px !important; }
  .lg-down-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 70px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-py-xl {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .lg-down-py-xl-child > * {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .lg-down-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important;
    padding-bottom: 70px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pl-xl {
    padding-left: 70px !important; }
  .lg-down-pl-xl-child > * {
    padding-left: 70px !important; }
  .lg-down-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pr-xl {
    padding-right: 70px !important; }
  .lg-down-pr-xl-child > * {
    padding-right: 70px !important; }
  .lg-down-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 70px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-px-xl {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .lg-down-px-xl-child > * {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .lg-down-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important;
    padding-right: 70px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-m-xxl {
    margin: 140px !important; }
  .lg-down-m-xxl-child > * {
    margin: 140px !important; }
  .lg-down-m-xxl-child-not-last > *:not(:last-child) {
    margin: 140px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mt-xxl {
    margin-top: 140px !important; }
  .lg-down-mt-xxl-child > * {
    margin-top: 140px !important; }
  .lg-down-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mb-xxl {
    margin-bottom: 140px !important; }
  .lg-down-mb-xxl-child > * {
    margin-bottom: 140px !important; }
  .lg-down-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 140px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-my-xxl {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .lg-down-my-xxl-child > * {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .lg-down-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important;
    margin-bottom: 140px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-ml-xxl {
    margin-left: 140px !important; }
  .lg-down-ml-xxl-child > * {
    margin-left: 140px !important; }
  .lg-down-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mr-xxl {
    margin-right: 140px !important; }
  .lg-down-mr-xxl-child > * {
    margin-right: 140px !important; }
  .lg-down-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 140px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mx-xxl {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .lg-down-mx-xxl-child > * {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .lg-down-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important;
    margin-right: 140px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-p-xxl {
    padding: 140px !important; }
  .lg-down-p-xxl-child > * {
    padding: 140px !important; }
  .lg-down-p-xxl-child-not-last > *:not(:last-child) {
    padding: 140px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pt-xxl {
    padding-top: 140px !important; }
  .lg-down-pt-xxl-child > * {
    padding-top: 140px !important; }
  .lg-down-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pb-xxl {
    padding-bottom: 140px !important; }
  .lg-down-pb-xxl-child > * {
    padding-bottom: 140px !important; }
  .lg-down-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 140px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-py-xxl {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .lg-down-py-xxl-child > * {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .lg-down-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important;
    padding-bottom: 140px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pl-xxl {
    padding-left: 140px !important; }
  .lg-down-pl-xxl-child > * {
    padding-left: 140px !important; }
  .lg-down-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pr-xxl {
    padding-right: 140px !important; }
  .lg-down-pr-xxl-child > * {
    padding-right: 140px !important; }
  .lg-down-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 140px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-px-xxl {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .lg-down-px-xxl-child > * {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .lg-down-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important;
    padding-right: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-m-0 {
    margin: 0 !important; }
  .md-down-m-0-child > * {
    margin: 0 !important; }
  .md-down-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-mt-0 {
    margin-top: 0 !important; }
  .md-down-mt-0-child > * {
    margin-top: 0 !important; }
  .md-down-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-mb-0 {
    margin-bottom: 0 !important; }
  .md-down-mb-0-child > * {
    margin-bottom: 0 !important; }
  .md-down-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .md-down-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .md-down-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-ml-0 {
    margin-left: 0 !important; }
  .md-down-ml-0-child > * {
    margin-left: 0 !important; }
  .md-down-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-mr-0 {
    margin-right: 0 !important; }
  .md-down-mr-0-child > * {
    margin-right: 0 !important; }
  .md-down-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .md-down-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .md-down-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-p-0 {
    padding: 0 !important; }
  .md-down-p-0-child > * {
    padding: 0 !important; }
  .md-down-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-pt-0 {
    padding-top: 0 !important; }
  .md-down-pt-0-child > * {
    padding-top: 0 !important; }
  .md-down-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-pb-0 {
    padding-bottom: 0 !important; }
  .md-down-pb-0-child > * {
    padding-bottom: 0 !important; }
  .md-down-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .md-down-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .md-down-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-pl-0 {
    padding-left: 0 !important; }
  .md-down-pl-0-child > * {
    padding-left: 0 !important; }
  .md-down-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-pr-0 {
    padding-right: 0 !important; }
  .md-down-pr-0-child > * {
    padding-right: 0 !important; }
  .md-down-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .md-down-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .md-down-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-m-xs {
    margin: 5px !important; }
  .md-down-m-xs-child > * {
    margin: 5px !important; }
  .md-down-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-mt-xs {
    margin-top: 5px !important; }
  .md-down-mt-xs-child > * {
    margin-top: 5px !important; }
  .md-down-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-mb-xs {
    margin-bottom: 5px !important; }
  .md-down-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .md-down-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .md-down-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .md-down-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-ml-xs {
    margin-left: 5px !important; }
  .md-down-ml-xs-child > * {
    margin-left: 5px !important; }
  .md-down-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-mr-xs {
    margin-right: 5px !important; }
  .md-down-mr-xs-child > * {
    margin-right: 5px !important; }
  .md-down-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .md-down-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .md-down-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-p-xs {
    padding: 5px !important; }
  .md-down-p-xs-child > * {
    padding: 5px !important; }
  .md-down-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-pt-xs {
    padding-top: 5px !important; }
  .md-down-pt-xs-child > * {
    padding-top: 5px !important; }
  .md-down-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-pb-xs {
    padding-bottom: 5px !important; }
  .md-down-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .md-down-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .md-down-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .md-down-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-pl-xs {
    padding-left: 5px !important; }
  .md-down-pl-xs-child > * {
    padding-left: 5px !important; }
  .md-down-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-pr-xs {
    padding-right: 5px !important; }
  .md-down-pr-xs-child > * {
    padding-right: 5px !important; }
  .md-down-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .md-down-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .md-down-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-m-sm {
    margin: 15px !important; }
  .md-down-m-sm-child > * {
    margin: 15px !important; }
  .md-down-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-mt-sm {
    margin-top: 15px !important; }
  .md-down-mt-sm-child > * {
    margin-top: 15px !important; }
  .md-down-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-mb-sm {
    margin-bottom: 15px !important; }
  .md-down-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .md-down-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .md-down-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .md-down-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-ml-sm {
    margin-left: 15px !important; }
  .md-down-ml-sm-child > * {
    margin-left: 15px !important; }
  .md-down-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-mr-sm {
    margin-right: 15px !important; }
  .md-down-mr-sm-child > * {
    margin-right: 15px !important; }
  .md-down-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .md-down-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .md-down-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-p-sm {
    padding: 15px !important; }
  .md-down-p-sm-child > * {
    padding: 15px !important; }
  .md-down-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-pt-sm {
    padding-top: 15px !important; }
  .md-down-pt-sm-child > * {
    padding-top: 15px !important; }
  .md-down-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-pb-sm {
    padding-bottom: 15px !important; }
  .md-down-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .md-down-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .md-down-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .md-down-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-pl-sm {
    padding-left: 15px !important; }
  .md-down-pl-sm-child > * {
    padding-left: 15px !important; }
  .md-down-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-pr-sm {
    padding-right: 15px !important; }
  .md-down-pr-sm-child > * {
    padding-right: 15px !important; }
  .md-down-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .md-down-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .md-down-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-m-md {
    margin: 30px !important; }
  .md-down-m-md-child > * {
    margin: 30px !important; }
  .md-down-m-md-child-not-last > *:not(:last-child) {
    margin: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-mt-md {
    margin-top: 30px !important; }
  .md-down-mt-md-child > * {
    margin-top: 30px !important; }
  .md-down-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-mb-md {
    margin-bottom: 30px !important; }
  .md-down-mb-md-child > * {
    margin-bottom: 30px !important; }
  .md-down-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-my-md {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .md-down-my-md-child > * {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .md-down-my-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important;
    margin-bottom: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-ml-md {
    margin-left: 30px !important; }
  .md-down-ml-md-child > * {
    margin-left: 30px !important; }
  .md-down-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-mr-md {
    margin-right: 30px !important; }
  .md-down-mr-md-child > * {
    margin-right: 30px !important; }
  .md-down-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-mx-md {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .md-down-mx-md-child > * {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .md-down-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important;
    margin-right: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-p-md {
    padding: 30px !important; }
  .md-down-p-md-child > * {
    padding: 30px !important; }
  .md-down-p-md-child-not-last > *:not(:last-child) {
    padding: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-pt-md {
    padding-top: 30px !important; }
  .md-down-pt-md-child > * {
    padding-top: 30px !important; }
  .md-down-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-pb-md {
    padding-bottom: 30px !important; }
  .md-down-pb-md-child > * {
    padding-bottom: 30px !important; }
  .md-down-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-py-md {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .md-down-py-md-child > * {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .md-down-py-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important;
    padding-bottom: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-pl-md {
    padding-left: 30px !important; }
  .md-down-pl-md-child > * {
    padding-left: 30px !important; }
  .md-down-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-pr-md {
    padding-right: 30px !important; }
  .md-down-pr-md-child > * {
    padding-right: 30px !important; }
  .md-down-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-px-md {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .md-down-px-md-child > * {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .md-down-px-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-m-lg {
    margin: 50px !important; }
  .md-down-m-lg-child > * {
    margin: 50px !important; }
  .md-down-m-lg-child-not-last > *:not(:last-child) {
    margin: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-mt-lg {
    margin-top: 50px !important; }
  .md-down-mt-lg-child > * {
    margin-top: 50px !important; }
  .md-down-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-mb-lg {
    margin-bottom: 50px !important; }
  .md-down-mb-lg-child > * {
    margin-bottom: 50px !important; }
  .md-down-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-my-lg {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .md-down-my-lg-child > * {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .md-down-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important;
    margin-bottom: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-ml-lg {
    margin-left: 50px !important; }
  .md-down-ml-lg-child > * {
    margin-left: 50px !important; }
  .md-down-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-mr-lg {
    margin-right: 50px !important; }
  .md-down-mr-lg-child > * {
    margin-right: 50px !important; }
  .md-down-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-mx-lg {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .md-down-mx-lg-child > * {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .md-down-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important;
    margin-right: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-p-lg {
    padding: 50px !important; }
  .md-down-p-lg-child > * {
    padding: 50px !important; }
  .md-down-p-lg-child-not-last > *:not(:last-child) {
    padding: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-pt-lg {
    padding-top: 50px !important; }
  .md-down-pt-lg-child > * {
    padding-top: 50px !important; }
  .md-down-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-pb-lg {
    padding-bottom: 50px !important; }
  .md-down-pb-lg-child > * {
    padding-bottom: 50px !important; }
  .md-down-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-py-lg {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .md-down-py-lg-child > * {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .md-down-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important;
    padding-bottom: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-pl-lg {
    padding-left: 50px !important; }
  .md-down-pl-lg-child > * {
    padding-left: 50px !important; }
  .md-down-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-pr-lg {
    padding-right: 50px !important; }
  .md-down-pr-lg-child > * {
    padding-right: 50px !important; }
  .md-down-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-px-lg {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .md-down-px-lg-child > * {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .md-down-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-m-xl {
    margin: 70px !important; }
  .md-down-m-xl-child > * {
    margin: 70px !important; }
  .md-down-m-xl-child-not-last > *:not(:last-child) {
    margin: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-mt-xl {
    margin-top: 70px !important; }
  .md-down-mt-xl-child > * {
    margin-top: 70px !important; }
  .md-down-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-mb-xl {
    margin-bottom: 70px !important; }
  .md-down-mb-xl-child > * {
    margin-bottom: 70px !important; }
  .md-down-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-my-xl {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .md-down-my-xl-child > * {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .md-down-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important;
    margin-bottom: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-ml-xl {
    margin-left: 70px !important; }
  .md-down-ml-xl-child > * {
    margin-left: 70px !important; }
  .md-down-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-mr-xl {
    margin-right: 70px !important; }
  .md-down-mr-xl-child > * {
    margin-right: 70px !important; }
  .md-down-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-mx-xl {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .md-down-mx-xl-child > * {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .md-down-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important;
    margin-right: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-p-xl {
    padding: 70px !important; }
  .md-down-p-xl-child > * {
    padding: 70px !important; }
  .md-down-p-xl-child-not-last > *:not(:last-child) {
    padding: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-pt-xl {
    padding-top: 70px !important; }
  .md-down-pt-xl-child > * {
    padding-top: 70px !important; }
  .md-down-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-pb-xl {
    padding-bottom: 70px !important; }
  .md-down-pb-xl-child > * {
    padding-bottom: 70px !important; }
  .md-down-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-py-xl {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .md-down-py-xl-child > * {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .md-down-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important;
    padding-bottom: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-pl-xl {
    padding-left: 70px !important; }
  .md-down-pl-xl-child > * {
    padding-left: 70px !important; }
  .md-down-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-pr-xl {
    padding-right: 70px !important; }
  .md-down-pr-xl-child > * {
    padding-right: 70px !important; }
  .md-down-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-px-xl {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .md-down-px-xl-child > * {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .md-down-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important;
    padding-right: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-m-xxl {
    margin: 140px !important; }
  .md-down-m-xxl-child > * {
    margin: 140px !important; }
  .md-down-m-xxl-child-not-last > *:not(:last-child) {
    margin: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-mt-xxl {
    margin-top: 140px !important; }
  .md-down-mt-xxl-child > * {
    margin-top: 140px !important; }
  .md-down-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-mb-xxl {
    margin-bottom: 140px !important; }
  .md-down-mb-xxl-child > * {
    margin-bottom: 140px !important; }
  .md-down-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-my-xxl {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .md-down-my-xxl-child > * {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .md-down-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important;
    margin-bottom: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-ml-xxl {
    margin-left: 140px !important; }
  .md-down-ml-xxl-child > * {
    margin-left: 140px !important; }
  .md-down-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-mr-xxl {
    margin-right: 140px !important; }
  .md-down-mr-xxl-child > * {
    margin-right: 140px !important; }
  .md-down-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-mx-xxl {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .md-down-mx-xxl-child > * {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .md-down-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important;
    margin-right: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-p-xxl {
    padding: 140px !important; }
  .md-down-p-xxl-child > * {
    padding: 140px !important; }
  .md-down-p-xxl-child-not-last > *:not(:last-child) {
    padding: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-pt-xxl {
    padding-top: 140px !important; }
  .md-down-pt-xxl-child > * {
    padding-top: 140px !important; }
  .md-down-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-pb-xxl {
    padding-bottom: 140px !important; }
  .md-down-pb-xxl-child > * {
    padding-bottom: 140px !important; }
  .md-down-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-py-xxl {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .md-down-py-xxl-child > * {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .md-down-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important;
    padding-bottom: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-pl-xxl {
    padding-left: 140px !important; }
  .md-down-pl-xxl-child > * {
    padding-left: 140px !important; }
  .md-down-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-pr-xxl {
    padding-right: 140px !important; }
  .md-down-pr-xxl-child > * {
    padding-right: 140px !important; }
  .md-down-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-px-xxl {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .md-down-px-xxl-child > * {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .md-down-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important;
    padding-right: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-m-0 {
    margin: 0 !important; }
  .sm-down-m-0-child > * {
    margin: 0 !important; }
  .sm-down-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-mt-0 {
    margin-top: 0 !important; }
  .sm-down-mt-0-child > * {
    margin-top: 0 !important; }
  .sm-down-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-mb-0 {
    margin-bottom: 0 !important; }
  .sm-down-mb-0-child > * {
    margin-bottom: 0 !important; }
  .sm-down-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .sm-down-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .sm-down-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-ml-0 {
    margin-left: 0 !important; }
  .sm-down-ml-0-child > * {
    margin-left: 0 !important; }
  .sm-down-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-mr-0 {
    margin-right: 0 !important; }
  .sm-down-mr-0-child > * {
    margin-right: 0 !important; }
  .sm-down-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .sm-down-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .sm-down-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-p-0 {
    padding: 0 !important; }
  .sm-down-p-0-child > * {
    padding: 0 !important; }
  .sm-down-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-pt-0 {
    padding-top: 0 !important; }
  .sm-down-pt-0-child > * {
    padding-top: 0 !important; }
  .sm-down-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-pb-0 {
    padding-bottom: 0 !important; }
  .sm-down-pb-0-child > * {
    padding-bottom: 0 !important; }
  .sm-down-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .sm-down-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .sm-down-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-pl-0 {
    padding-left: 0 !important; }
  .sm-down-pl-0-child > * {
    padding-left: 0 !important; }
  .sm-down-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-pr-0 {
    padding-right: 0 !important; }
  .sm-down-pr-0-child > * {
    padding-right: 0 !important; }
  .sm-down-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .sm-down-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .sm-down-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-m-xs {
    margin: 5px !important; }
  .sm-down-m-xs-child > * {
    margin: 5px !important; }
  .sm-down-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mt-xs {
    margin-top: 5px !important; }
  .sm-down-mt-xs-child > * {
    margin-top: 5px !important; }
  .sm-down-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mb-xs {
    margin-bottom: 5px !important; }
  .sm-down-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .sm-down-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .sm-down-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .sm-down-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-ml-xs {
    margin-left: 5px !important; }
  .sm-down-ml-xs-child > * {
    margin-left: 5px !important; }
  .sm-down-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mr-xs {
    margin-right: 5px !important; }
  .sm-down-mr-xs-child > * {
    margin-right: 5px !important; }
  .sm-down-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .sm-down-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .sm-down-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-p-xs {
    padding: 5px !important; }
  .sm-down-p-xs-child > * {
    padding: 5px !important; }
  .sm-down-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pt-xs {
    padding-top: 5px !important; }
  .sm-down-pt-xs-child > * {
    padding-top: 5px !important; }
  .sm-down-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pb-xs {
    padding-bottom: 5px !important; }
  .sm-down-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .sm-down-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .sm-down-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .sm-down-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pl-xs {
    padding-left: 5px !important; }
  .sm-down-pl-xs-child > * {
    padding-left: 5px !important; }
  .sm-down-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pr-xs {
    padding-right: 5px !important; }
  .sm-down-pr-xs-child > * {
    padding-right: 5px !important; }
  .sm-down-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .sm-down-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .sm-down-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-m-sm {
    margin: 15px !important; }
  .sm-down-m-sm-child > * {
    margin: 15px !important; }
  .sm-down-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mt-sm {
    margin-top: 15px !important; }
  .sm-down-mt-sm-child > * {
    margin-top: 15px !important; }
  .sm-down-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mb-sm {
    margin-bottom: 15px !important; }
  .sm-down-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .sm-down-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .sm-down-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .sm-down-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-ml-sm {
    margin-left: 15px !important; }
  .sm-down-ml-sm-child > * {
    margin-left: 15px !important; }
  .sm-down-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mr-sm {
    margin-right: 15px !important; }
  .sm-down-mr-sm-child > * {
    margin-right: 15px !important; }
  .sm-down-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .sm-down-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .sm-down-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-p-sm {
    padding: 15px !important; }
  .sm-down-p-sm-child > * {
    padding: 15px !important; }
  .sm-down-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pt-sm {
    padding-top: 15px !important; }
  .sm-down-pt-sm-child > * {
    padding-top: 15px !important; }
  .sm-down-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pb-sm {
    padding-bottom: 15px !important; }
  .sm-down-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .sm-down-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .sm-down-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .sm-down-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pl-sm {
    padding-left: 15px !important; }
  .sm-down-pl-sm-child > * {
    padding-left: 15px !important; }
  .sm-down-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pr-sm {
    padding-right: 15px !important; }
  .sm-down-pr-sm-child > * {
    padding-right: 15px !important; }
  .sm-down-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .sm-down-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .sm-down-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-m-md {
    margin: 30px !important; }
  .sm-down-m-md-child > * {
    margin: 30px !important; }
  .sm-down-m-md-child-not-last > *:not(:last-child) {
    margin: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mt-md {
    margin-top: 30px !important; }
  .sm-down-mt-md-child > * {
    margin-top: 30px !important; }
  .sm-down-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mb-md {
    margin-bottom: 30px !important; }
  .sm-down-mb-md-child > * {
    margin-bottom: 30px !important; }
  .sm-down-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-my-md {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .sm-down-my-md-child > * {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .sm-down-my-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important;
    margin-bottom: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-ml-md {
    margin-left: 30px !important; }
  .sm-down-ml-md-child > * {
    margin-left: 30px !important; }
  .sm-down-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mr-md {
    margin-right: 30px !important; }
  .sm-down-mr-md-child > * {
    margin-right: 30px !important; }
  .sm-down-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mx-md {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .sm-down-mx-md-child > * {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .sm-down-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important;
    margin-right: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-p-md {
    padding: 30px !important; }
  .sm-down-p-md-child > * {
    padding: 30px !important; }
  .sm-down-p-md-child-not-last > *:not(:last-child) {
    padding: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pt-md {
    padding-top: 30px !important; }
  .sm-down-pt-md-child > * {
    padding-top: 30px !important; }
  .sm-down-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pb-md {
    padding-bottom: 30px !important; }
  .sm-down-pb-md-child > * {
    padding-bottom: 30px !important; }
  .sm-down-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-py-md {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .sm-down-py-md-child > * {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .sm-down-py-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important;
    padding-bottom: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pl-md {
    padding-left: 30px !important; }
  .sm-down-pl-md-child > * {
    padding-left: 30px !important; }
  .sm-down-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pr-md {
    padding-right: 30px !important; }
  .sm-down-pr-md-child > * {
    padding-right: 30px !important; }
  .sm-down-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-px-md {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .sm-down-px-md-child > * {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .sm-down-px-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-m-lg {
    margin: 50px !important; }
  .sm-down-m-lg-child > * {
    margin: 50px !important; }
  .sm-down-m-lg-child-not-last > *:not(:last-child) {
    margin: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mt-lg {
    margin-top: 50px !important; }
  .sm-down-mt-lg-child > * {
    margin-top: 50px !important; }
  .sm-down-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mb-lg {
    margin-bottom: 50px !important; }
  .sm-down-mb-lg-child > * {
    margin-bottom: 50px !important; }
  .sm-down-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-my-lg {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .sm-down-my-lg-child > * {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .sm-down-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important;
    margin-bottom: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-ml-lg {
    margin-left: 50px !important; }
  .sm-down-ml-lg-child > * {
    margin-left: 50px !important; }
  .sm-down-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mr-lg {
    margin-right: 50px !important; }
  .sm-down-mr-lg-child > * {
    margin-right: 50px !important; }
  .sm-down-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mx-lg {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .sm-down-mx-lg-child > * {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .sm-down-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important;
    margin-right: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-p-lg {
    padding: 50px !important; }
  .sm-down-p-lg-child > * {
    padding: 50px !important; }
  .sm-down-p-lg-child-not-last > *:not(:last-child) {
    padding: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pt-lg {
    padding-top: 50px !important; }
  .sm-down-pt-lg-child > * {
    padding-top: 50px !important; }
  .sm-down-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pb-lg {
    padding-bottom: 50px !important; }
  .sm-down-pb-lg-child > * {
    padding-bottom: 50px !important; }
  .sm-down-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-py-lg {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .sm-down-py-lg-child > * {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .sm-down-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important;
    padding-bottom: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pl-lg {
    padding-left: 50px !important; }
  .sm-down-pl-lg-child > * {
    padding-left: 50px !important; }
  .sm-down-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pr-lg {
    padding-right: 50px !important; }
  .sm-down-pr-lg-child > * {
    padding-right: 50px !important; }
  .sm-down-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-px-lg {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .sm-down-px-lg-child > * {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .sm-down-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-m-xl {
    margin: 70px !important; }
  .sm-down-m-xl-child > * {
    margin: 70px !important; }
  .sm-down-m-xl-child-not-last > *:not(:last-child) {
    margin: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mt-xl {
    margin-top: 70px !important; }
  .sm-down-mt-xl-child > * {
    margin-top: 70px !important; }
  .sm-down-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mb-xl {
    margin-bottom: 70px !important; }
  .sm-down-mb-xl-child > * {
    margin-bottom: 70px !important; }
  .sm-down-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-my-xl {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .sm-down-my-xl-child > * {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .sm-down-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important;
    margin-bottom: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-ml-xl {
    margin-left: 70px !important; }
  .sm-down-ml-xl-child > * {
    margin-left: 70px !important; }
  .sm-down-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mr-xl {
    margin-right: 70px !important; }
  .sm-down-mr-xl-child > * {
    margin-right: 70px !important; }
  .sm-down-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mx-xl {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .sm-down-mx-xl-child > * {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .sm-down-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important;
    margin-right: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-p-xl {
    padding: 70px !important; }
  .sm-down-p-xl-child > * {
    padding: 70px !important; }
  .sm-down-p-xl-child-not-last > *:not(:last-child) {
    padding: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pt-xl {
    padding-top: 70px !important; }
  .sm-down-pt-xl-child > * {
    padding-top: 70px !important; }
  .sm-down-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pb-xl {
    padding-bottom: 70px !important; }
  .sm-down-pb-xl-child > * {
    padding-bottom: 70px !important; }
  .sm-down-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-py-xl {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .sm-down-py-xl-child > * {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .sm-down-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important;
    padding-bottom: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pl-xl {
    padding-left: 70px !important; }
  .sm-down-pl-xl-child > * {
    padding-left: 70px !important; }
  .sm-down-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pr-xl {
    padding-right: 70px !important; }
  .sm-down-pr-xl-child > * {
    padding-right: 70px !important; }
  .sm-down-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-px-xl {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .sm-down-px-xl-child > * {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .sm-down-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important;
    padding-right: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-m-xxl {
    margin: 140px !important; }
  .sm-down-m-xxl-child > * {
    margin: 140px !important; }
  .sm-down-m-xxl-child-not-last > *:not(:last-child) {
    margin: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mt-xxl {
    margin-top: 140px !important; }
  .sm-down-mt-xxl-child > * {
    margin-top: 140px !important; }
  .sm-down-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mb-xxl {
    margin-bottom: 140px !important; }
  .sm-down-mb-xxl-child > * {
    margin-bottom: 140px !important; }
  .sm-down-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-my-xxl {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .sm-down-my-xxl-child > * {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .sm-down-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important;
    margin-bottom: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-ml-xxl {
    margin-left: 140px !important; }
  .sm-down-ml-xxl-child > * {
    margin-left: 140px !important; }
  .sm-down-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mr-xxl {
    margin-right: 140px !important; }
  .sm-down-mr-xxl-child > * {
    margin-right: 140px !important; }
  .sm-down-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mx-xxl {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .sm-down-mx-xxl-child > * {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .sm-down-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important;
    margin-right: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-p-xxl {
    padding: 140px !important; }
  .sm-down-p-xxl-child > * {
    padding: 140px !important; }
  .sm-down-p-xxl-child-not-last > *:not(:last-child) {
    padding: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pt-xxl {
    padding-top: 140px !important; }
  .sm-down-pt-xxl-child > * {
    padding-top: 140px !important; }
  .sm-down-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pb-xxl {
    padding-bottom: 140px !important; }
  .sm-down-pb-xxl-child > * {
    padding-bottom: 140px !important; }
  .sm-down-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-py-xxl {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .sm-down-py-xxl-child > * {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .sm-down-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important;
    padding-bottom: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pl-xxl {
    padding-left: 140px !important; }
  .sm-down-pl-xxl-child > * {
    padding-left: 140px !important; }
  .sm-down-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pr-xxl {
    padding-right: 140px !important; }
  .sm-down-pr-xxl-child > * {
    padding-right: 140px !important; }
  .sm-down-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-px-xxl {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .sm-down-px-xxl-child > * {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .sm-down-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important;
    padding-right: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-m-0 {
    margin: 0 !important; }
  .xs-down-m-0-child > * {
    margin: 0 !important; }
  .xs-down-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-mt-0 {
    margin-top: 0 !important; }
  .xs-down-mt-0-child > * {
    margin-top: 0 !important; }
  .xs-down-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-mb-0 {
    margin-bottom: 0 !important; }
  .xs-down-mb-0-child > * {
    margin-bottom: 0 !important; }
  .xs-down-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .xs-down-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .xs-down-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-ml-0 {
    margin-left: 0 !important; }
  .xs-down-ml-0-child > * {
    margin-left: 0 !important; }
  .xs-down-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-mr-0 {
    margin-right: 0 !important; }
  .xs-down-mr-0-child > * {
    margin-right: 0 !important; }
  .xs-down-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .xs-down-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .xs-down-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-p-0 {
    padding: 0 !important; }
  .xs-down-p-0-child > * {
    padding: 0 !important; }
  .xs-down-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-pt-0 {
    padding-top: 0 !important; }
  .xs-down-pt-0-child > * {
    padding-top: 0 !important; }
  .xs-down-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-pb-0 {
    padding-bottom: 0 !important; }
  .xs-down-pb-0-child > * {
    padding-bottom: 0 !important; }
  .xs-down-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .xs-down-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .xs-down-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-pl-0 {
    padding-left: 0 !important; }
  .xs-down-pl-0-child > * {
    padding-left: 0 !important; }
  .xs-down-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-pr-0 {
    padding-right: 0 !important; }
  .xs-down-pr-0-child > * {
    padding-right: 0 !important; }
  .xs-down-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .xs-down-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .xs-down-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-m-xs {
    margin: 5px !important; }
  .xs-down-m-xs-child > * {
    margin: 5px !important; }
  .xs-down-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mt-xs {
    margin-top: 5px !important; }
  .xs-down-mt-xs-child > * {
    margin-top: 5px !important; }
  .xs-down-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mb-xs {
    margin-bottom: 5px !important; }
  .xs-down-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .xs-down-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .xs-down-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .xs-down-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-ml-xs {
    margin-left: 5px !important; }
  .xs-down-ml-xs-child > * {
    margin-left: 5px !important; }
  .xs-down-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mr-xs {
    margin-right: 5px !important; }
  .xs-down-mr-xs-child > * {
    margin-right: 5px !important; }
  .xs-down-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .xs-down-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .xs-down-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-p-xs {
    padding: 5px !important; }
  .xs-down-p-xs-child > * {
    padding: 5px !important; }
  .xs-down-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pt-xs {
    padding-top: 5px !important; }
  .xs-down-pt-xs-child > * {
    padding-top: 5px !important; }
  .xs-down-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pb-xs {
    padding-bottom: 5px !important; }
  .xs-down-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .xs-down-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .xs-down-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .xs-down-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pl-xs {
    padding-left: 5px !important; }
  .xs-down-pl-xs-child > * {
    padding-left: 5px !important; }
  .xs-down-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pr-xs {
    padding-right: 5px !important; }
  .xs-down-pr-xs-child > * {
    padding-right: 5px !important; }
  .xs-down-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .xs-down-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .xs-down-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-m-sm {
    margin: 15px !important; }
  .xs-down-m-sm-child > * {
    margin: 15px !important; }
  .xs-down-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mt-sm {
    margin-top: 15px !important; }
  .xs-down-mt-sm-child > * {
    margin-top: 15px !important; }
  .xs-down-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mb-sm {
    margin-bottom: 15px !important; }
  .xs-down-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .xs-down-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .xs-down-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .xs-down-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-ml-sm {
    margin-left: 15px !important; }
  .xs-down-ml-sm-child > * {
    margin-left: 15px !important; }
  .xs-down-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mr-sm {
    margin-right: 15px !important; }
  .xs-down-mr-sm-child > * {
    margin-right: 15px !important; }
  .xs-down-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .xs-down-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .xs-down-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-p-sm {
    padding: 15px !important; }
  .xs-down-p-sm-child > * {
    padding: 15px !important; }
  .xs-down-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pt-sm {
    padding-top: 15px !important; }
  .xs-down-pt-sm-child > * {
    padding-top: 15px !important; }
  .xs-down-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pb-sm {
    padding-bottom: 15px !important; }
  .xs-down-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .xs-down-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .xs-down-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .xs-down-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pl-sm {
    padding-left: 15px !important; }
  .xs-down-pl-sm-child > * {
    padding-left: 15px !important; }
  .xs-down-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pr-sm {
    padding-right: 15px !important; }
  .xs-down-pr-sm-child > * {
    padding-right: 15px !important; }
  .xs-down-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .xs-down-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .xs-down-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-m-md {
    margin: 30px !important; }
  .xs-down-m-md-child > * {
    margin: 30px !important; }
  .xs-down-m-md-child-not-last > *:not(:last-child) {
    margin: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mt-md {
    margin-top: 30px !important; }
  .xs-down-mt-md-child > * {
    margin-top: 30px !important; }
  .xs-down-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mb-md {
    margin-bottom: 30px !important; }
  .xs-down-mb-md-child > * {
    margin-bottom: 30px !important; }
  .xs-down-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-my-md {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .xs-down-my-md-child > * {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .xs-down-my-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important;
    margin-bottom: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-ml-md {
    margin-left: 30px !important; }
  .xs-down-ml-md-child > * {
    margin-left: 30px !important; }
  .xs-down-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mr-md {
    margin-right: 30px !important; }
  .xs-down-mr-md-child > * {
    margin-right: 30px !important; }
  .xs-down-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mx-md {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .xs-down-mx-md-child > * {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .xs-down-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important;
    margin-right: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-p-md {
    padding: 30px !important; }
  .xs-down-p-md-child > * {
    padding: 30px !important; }
  .xs-down-p-md-child-not-last > *:not(:last-child) {
    padding: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pt-md {
    padding-top: 30px !important; }
  .xs-down-pt-md-child > * {
    padding-top: 30px !important; }
  .xs-down-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pb-md {
    padding-bottom: 30px !important; }
  .xs-down-pb-md-child > * {
    padding-bottom: 30px !important; }
  .xs-down-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-py-md {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .xs-down-py-md-child > * {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .xs-down-py-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important;
    padding-bottom: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pl-md {
    padding-left: 30px !important; }
  .xs-down-pl-md-child > * {
    padding-left: 30px !important; }
  .xs-down-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pr-md {
    padding-right: 30px !important; }
  .xs-down-pr-md-child > * {
    padding-right: 30px !important; }
  .xs-down-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-px-md {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .xs-down-px-md-child > * {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .xs-down-px-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-m-lg {
    margin: 50px !important; }
  .xs-down-m-lg-child > * {
    margin: 50px !important; }
  .xs-down-m-lg-child-not-last > *:not(:last-child) {
    margin: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mt-lg {
    margin-top: 50px !important; }
  .xs-down-mt-lg-child > * {
    margin-top: 50px !important; }
  .xs-down-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mb-lg {
    margin-bottom: 50px !important; }
  .xs-down-mb-lg-child > * {
    margin-bottom: 50px !important; }
  .xs-down-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-my-lg {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .xs-down-my-lg-child > * {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .xs-down-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important;
    margin-bottom: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-ml-lg {
    margin-left: 50px !important; }
  .xs-down-ml-lg-child > * {
    margin-left: 50px !important; }
  .xs-down-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mr-lg {
    margin-right: 50px !important; }
  .xs-down-mr-lg-child > * {
    margin-right: 50px !important; }
  .xs-down-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mx-lg {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .xs-down-mx-lg-child > * {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .xs-down-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important;
    margin-right: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-p-lg {
    padding: 50px !important; }
  .xs-down-p-lg-child > * {
    padding: 50px !important; }
  .xs-down-p-lg-child-not-last > *:not(:last-child) {
    padding: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pt-lg {
    padding-top: 50px !important; }
  .xs-down-pt-lg-child > * {
    padding-top: 50px !important; }
  .xs-down-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pb-lg {
    padding-bottom: 50px !important; }
  .xs-down-pb-lg-child > * {
    padding-bottom: 50px !important; }
  .xs-down-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-py-lg {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .xs-down-py-lg-child > * {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .xs-down-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important;
    padding-bottom: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pl-lg {
    padding-left: 50px !important; }
  .xs-down-pl-lg-child > * {
    padding-left: 50px !important; }
  .xs-down-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pr-lg {
    padding-right: 50px !important; }
  .xs-down-pr-lg-child > * {
    padding-right: 50px !important; }
  .xs-down-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-px-lg {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .xs-down-px-lg-child > * {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .xs-down-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-m-xl {
    margin: 70px !important; }
  .xs-down-m-xl-child > * {
    margin: 70px !important; }
  .xs-down-m-xl-child-not-last > *:not(:last-child) {
    margin: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mt-xl {
    margin-top: 70px !important; }
  .xs-down-mt-xl-child > * {
    margin-top: 70px !important; }
  .xs-down-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mb-xl {
    margin-bottom: 70px !important; }
  .xs-down-mb-xl-child > * {
    margin-bottom: 70px !important; }
  .xs-down-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-my-xl {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .xs-down-my-xl-child > * {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .xs-down-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important;
    margin-bottom: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-ml-xl {
    margin-left: 70px !important; }
  .xs-down-ml-xl-child > * {
    margin-left: 70px !important; }
  .xs-down-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mr-xl {
    margin-right: 70px !important; }
  .xs-down-mr-xl-child > * {
    margin-right: 70px !important; }
  .xs-down-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mx-xl {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .xs-down-mx-xl-child > * {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .xs-down-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important;
    margin-right: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-p-xl {
    padding: 70px !important; }
  .xs-down-p-xl-child > * {
    padding: 70px !important; }
  .xs-down-p-xl-child-not-last > *:not(:last-child) {
    padding: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pt-xl {
    padding-top: 70px !important; }
  .xs-down-pt-xl-child > * {
    padding-top: 70px !important; }
  .xs-down-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pb-xl {
    padding-bottom: 70px !important; }
  .xs-down-pb-xl-child > * {
    padding-bottom: 70px !important; }
  .xs-down-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-py-xl {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .xs-down-py-xl-child > * {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .xs-down-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important;
    padding-bottom: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pl-xl {
    padding-left: 70px !important; }
  .xs-down-pl-xl-child > * {
    padding-left: 70px !important; }
  .xs-down-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pr-xl {
    padding-right: 70px !important; }
  .xs-down-pr-xl-child > * {
    padding-right: 70px !important; }
  .xs-down-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-px-xl {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .xs-down-px-xl-child > * {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .xs-down-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important;
    padding-right: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-m-xxl {
    margin: 140px !important; }
  .xs-down-m-xxl-child > * {
    margin: 140px !important; }
  .xs-down-m-xxl-child-not-last > *:not(:last-child) {
    margin: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mt-xxl {
    margin-top: 140px !important; }
  .xs-down-mt-xxl-child > * {
    margin-top: 140px !important; }
  .xs-down-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mb-xxl {
    margin-bottom: 140px !important; }
  .xs-down-mb-xxl-child > * {
    margin-bottom: 140px !important; }
  .xs-down-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-my-xxl {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .xs-down-my-xxl-child > * {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .xs-down-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important;
    margin-bottom: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-ml-xxl {
    margin-left: 140px !important; }
  .xs-down-ml-xxl-child > * {
    margin-left: 140px !important; }
  .xs-down-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mr-xxl {
    margin-right: 140px !important; }
  .xs-down-mr-xxl-child > * {
    margin-right: 140px !important; }
  .xs-down-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mx-xxl {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .xs-down-mx-xxl-child > * {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .xs-down-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important;
    margin-right: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-p-xxl {
    padding: 140px !important; }
  .xs-down-p-xxl-child > * {
    padding: 140px !important; }
  .xs-down-p-xxl-child-not-last > *:not(:last-child) {
    padding: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pt-xxl {
    padding-top: 140px !important; }
  .xs-down-pt-xxl-child > * {
    padding-top: 140px !important; }
  .xs-down-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pb-xxl {
    padding-bottom: 140px !important; }
  .xs-down-pb-xxl-child > * {
    padding-bottom: 140px !important; }
  .xs-down-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-py-xxl {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .xs-down-py-xxl-child > * {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .xs-down-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important;
    padding-bottom: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pl-xxl {
    padding-left: 140px !important; }
  .xs-down-pl-xxl-child > * {
    padding-left: 140px !important; }
  .xs-down-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pr-xxl {
    padding-right: 140px !important; }
  .xs-down-pr-xxl-child > * {
    padding-right: 140px !important; }
  .xs-down-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-px-xxl {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .xs-down-px-xxl-child > * {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .xs-down-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important;
    padding-right: 140px !important; } }

.xs-up-m-0 {
  margin: 0 !important; }

.xs-up-m-0-child > * {
  margin: 0 !important; }

.xs-up-m-0-child-not-last > *:not(:last-child) {
  margin: 0 !important; }

.xs-up-mt-0 {
  margin-top: 0 !important; }

.xs-up-mt-0-child > * {
  margin-top: 0 !important; }

.xs-up-mt-0-child-not-last > *:not(:last-child) {
  margin-top: 0 !important; }

.xs-up-mb-0 {
  margin-bottom: 0 !important; }

.xs-up-mb-0-child > * {
  margin-bottom: 0 !important; }

.xs-up-mb-0-child-not-last > *:not(:last-child) {
  margin-bottom: 0 !important; }

.xs-up-my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.xs-up-my-0-child > * {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.xs-up-my-0-child-not-last > *:not(:last-child) {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.xs-up-ml-0 {
  margin-left: 0 !important; }

.xs-up-ml-0-child > * {
  margin-left: 0 !important; }

.xs-up-ml-0-child-not-last > *:not(:last-child) {
  margin-left: 0 !important; }

.xs-up-mr-0 {
  margin-right: 0 !important; }

.xs-up-mr-0-child > * {
  margin-right: 0 !important; }

.xs-up-mr-0-child-not-last > *:not(:last-child) {
  margin-right: 0 !important; }

.xs-up-mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.xs-up-mx-0-child > * {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.xs-up-mx-0-child-not-last > *:not(:last-child) {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.xs-up-p-0 {
  padding: 0 !important; }

.xs-up-p-0-child > * {
  padding: 0 !important; }

.xs-up-p-0-child-not-last > *:not(:last-child) {
  padding: 0 !important; }

.xs-up-pt-0 {
  padding-top: 0 !important; }

.xs-up-pt-0-child > * {
  padding-top: 0 !important; }

.xs-up-pt-0-child-not-last > *:not(:last-child) {
  padding-top: 0 !important; }

.xs-up-pb-0 {
  padding-bottom: 0 !important; }

.xs-up-pb-0-child > * {
  padding-bottom: 0 !important; }

.xs-up-pb-0-child-not-last > *:not(:last-child) {
  padding-bottom: 0 !important; }

.xs-up-py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.xs-up-py-0-child > * {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.xs-up-py-0-child-not-last > *:not(:last-child) {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.xs-up-pl-0 {
  padding-left: 0 !important; }

.xs-up-pl-0-child > * {
  padding-left: 0 !important; }

.xs-up-pl-0-child-not-last > *:not(:last-child) {
  padding-left: 0 !important; }

.xs-up-pr-0 {
  padding-right: 0 !important; }

.xs-up-pr-0-child > * {
  padding-right: 0 !important; }

.xs-up-pr-0-child-not-last > *:not(:last-child) {
  padding-right: 0 !important; }

.xs-up-px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.xs-up-px-0-child > * {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.xs-up-px-0-child-not-last > *:not(:last-child) {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.xs-up-m-xs {
  margin: 5px !important; }

.xs-up-m-xs-child > * {
  margin: 5px !important; }

.xs-up-m-xs-child-not-last > *:not(:last-child) {
  margin: 5px !important; }

.xs-up-mt-xs {
  margin-top: 5px !important; }

.xs-up-mt-xs-child > * {
  margin-top: 5px !important; }

.xs-up-mt-xs-child-not-last > *:not(:last-child) {
  margin-top: 5px !important; }

.xs-up-mb-xs {
  margin-bottom: 5px !important; }

.xs-up-mb-xs-child > * {
  margin-bottom: 5px !important; }

.xs-up-mb-xs-child-not-last > *:not(:last-child) {
  margin-bottom: 5px !important; }

.xs-up-my-xs {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.xs-up-my-xs-child > * {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.xs-up-my-xs-child-not-last > *:not(:last-child) {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.xs-up-ml-xs {
  margin-left: 5px !important; }

.xs-up-ml-xs-child > * {
  margin-left: 5px !important; }

.xs-up-ml-xs-child-not-last > *:not(:last-child) {
  margin-left: 5px !important; }

.xs-up-mr-xs {
  margin-right: 5px !important; }

.xs-up-mr-xs-child > * {
  margin-right: 5px !important; }

.xs-up-mr-xs-child-not-last > *:not(:last-child) {
  margin-right: 5px !important; }

.xs-up-mx-xs {
  margin-left: 5px !important;
  margin-right: 5px !important; }

.xs-up-mx-xs-child > * {
  margin-left: 5px !important;
  margin-right: 5px !important; }

.xs-up-mx-xs-child-not-last > *:not(:last-child) {
  margin-left: 5px !important;
  margin-right: 5px !important; }

.xs-up-p-xs {
  padding: 5px !important; }

.xs-up-p-xs-child > * {
  padding: 5px !important; }

.xs-up-p-xs-child-not-last > *:not(:last-child) {
  padding: 5px !important; }

.xs-up-pt-xs {
  padding-top: 5px !important; }

.xs-up-pt-xs-child > * {
  padding-top: 5px !important; }

.xs-up-pt-xs-child-not-last > *:not(:last-child) {
  padding-top: 5px !important; }

.xs-up-pb-xs {
  padding-bottom: 5px !important; }

.xs-up-pb-xs-child > * {
  padding-bottom: 5px !important; }

.xs-up-pb-xs-child-not-last > *:not(:last-child) {
  padding-bottom: 5px !important; }

.xs-up-py-xs {
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

.xs-up-py-xs-child > * {
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

.xs-up-py-xs-child-not-last > *:not(:last-child) {
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

.xs-up-pl-xs {
  padding-left: 5px !important; }

.xs-up-pl-xs-child > * {
  padding-left: 5px !important; }

.xs-up-pl-xs-child-not-last > *:not(:last-child) {
  padding-left: 5px !important; }

.xs-up-pr-xs {
  padding-right: 5px !important; }

.xs-up-pr-xs-child > * {
  padding-right: 5px !important; }

.xs-up-pr-xs-child-not-last > *:not(:last-child) {
  padding-right: 5px !important; }

.xs-up-px-xs {
  padding-left: 5px !important;
  padding-right: 5px !important; }

.xs-up-px-xs-child > * {
  padding-left: 5px !important;
  padding-right: 5px !important; }

.xs-up-px-xs-child-not-last > *:not(:last-child) {
  padding-left: 5px !important;
  padding-right: 5px !important; }

.xs-up-m-sm {
  margin: 15px !important; }

.xs-up-m-sm-child > * {
  margin: 15px !important; }

.xs-up-m-sm-child-not-last > *:not(:last-child) {
  margin: 15px !important; }

.xs-up-mt-sm {
  margin-top: 15px !important; }

.xs-up-mt-sm-child > * {
  margin-top: 15px !important; }

.xs-up-mt-sm-child-not-last > *:not(:last-child) {
  margin-top: 15px !important; }

.xs-up-mb-sm {
  margin-bottom: 15px !important; }

.xs-up-mb-sm-child > * {
  margin-bottom: 15px !important; }

.xs-up-mb-sm-child-not-last > *:not(:last-child) {
  margin-bottom: 15px !important; }

.xs-up-my-sm {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.xs-up-my-sm-child > * {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.xs-up-my-sm-child-not-last > *:not(:last-child) {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.xs-up-ml-sm {
  margin-left: 15px !important; }

.xs-up-ml-sm-child > * {
  margin-left: 15px !important; }

.xs-up-ml-sm-child-not-last > *:not(:last-child) {
  margin-left: 15px !important; }

.xs-up-mr-sm {
  margin-right: 15px !important; }

.xs-up-mr-sm-child > * {
  margin-right: 15px !important; }

.xs-up-mr-sm-child-not-last > *:not(:last-child) {
  margin-right: 15px !important; }

.xs-up-mx-sm {
  margin-left: 15px !important;
  margin-right: 15px !important; }

.xs-up-mx-sm-child > * {
  margin-left: 15px !important;
  margin-right: 15px !important; }

.xs-up-mx-sm-child-not-last > *:not(:last-child) {
  margin-left: 15px !important;
  margin-right: 15px !important; }

.xs-up-p-sm {
  padding: 15px !important; }

.xs-up-p-sm-child > * {
  padding: 15px !important; }

.xs-up-p-sm-child-not-last > *:not(:last-child) {
  padding: 15px !important; }

.xs-up-pt-sm {
  padding-top: 15px !important; }

.xs-up-pt-sm-child > * {
  padding-top: 15px !important; }

.xs-up-pt-sm-child-not-last > *:not(:last-child) {
  padding-top: 15px !important; }

.xs-up-pb-sm {
  padding-bottom: 15px !important; }

.xs-up-pb-sm-child > * {
  padding-bottom: 15px !important; }

.xs-up-pb-sm-child-not-last > *:not(:last-child) {
  padding-bottom: 15px !important; }

.xs-up-py-sm {
  padding-top: 15px !important;
  padding-bottom: 15px !important; }

.xs-up-py-sm-child > * {
  padding-top: 15px !important;
  padding-bottom: 15px !important; }

.xs-up-py-sm-child-not-last > *:not(:last-child) {
  padding-top: 15px !important;
  padding-bottom: 15px !important; }

.xs-up-pl-sm {
  padding-left: 15px !important; }

.xs-up-pl-sm-child > * {
  padding-left: 15px !important; }

.xs-up-pl-sm-child-not-last > *:not(:last-child) {
  padding-left: 15px !important; }

.xs-up-pr-sm {
  padding-right: 15px !important; }

.xs-up-pr-sm-child > * {
  padding-right: 15px !important; }

.xs-up-pr-sm-child-not-last > *:not(:last-child) {
  padding-right: 15px !important; }

.xs-up-px-sm {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.xs-up-px-sm-child > * {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.xs-up-px-sm-child-not-last > *:not(:last-child) {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.xs-up-m-md {
  margin: 30px !important; }

.xs-up-m-md-child > * {
  margin: 30px !important; }

.xs-up-m-md-child-not-last > *:not(:last-child) {
  margin: 30px !important; }

.xs-up-mt-md {
  margin-top: 30px !important; }

.xs-up-mt-md-child > * {
  margin-top: 30px !important; }

.xs-up-mt-md-child-not-last > *:not(:last-child) {
  margin-top: 30px !important; }

.xs-up-mb-md {
  margin-bottom: 30px !important; }

.xs-up-mb-md-child > * {
  margin-bottom: 30px !important; }

.xs-up-mb-md-child-not-last > *:not(:last-child) {
  margin-bottom: 30px !important; }

.xs-up-my-md {
  margin-top: 30px !important;
  margin-bottom: 30px !important; }

.xs-up-my-md-child > * {
  margin-top: 30px !important;
  margin-bottom: 30px !important; }

.xs-up-my-md-child-not-last > *:not(:last-child) {
  margin-top: 30px !important;
  margin-bottom: 30px !important; }

.xs-up-ml-md {
  margin-left: 30px !important; }

.xs-up-ml-md-child > * {
  margin-left: 30px !important; }

.xs-up-ml-md-child-not-last > *:not(:last-child) {
  margin-left: 30px !important; }

.xs-up-mr-md {
  margin-right: 30px !important; }

.xs-up-mr-md-child > * {
  margin-right: 30px !important; }

.xs-up-mr-md-child-not-last > *:not(:last-child) {
  margin-right: 30px !important; }

.xs-up-mx-md {
  margin-left: 30px !important;
  margin-right: 30px !important; }

.xs-up-mx-md-child > * {
  margin-left: 30px !important;
  margin-right: 30px !important; }

.xs-up-mx-md-child-not-last > *:not(:last-child) {
  margin-left: 30px !important;
  margin-right: 30px !important; }

.xs-up-p-md {
  padding: 30px !important; }

.xs-up-p-md-child > * {
  padding: 30px !important; }

.xs-up-p-md-child-not-last > *:not(:last-child) {
  padding: 30px !important; }

.xs-up-pt-md {
  padding-top: 30px !important; }

.xs-up-pt-md-child > * {
  padding-top: 30px !important; }

.xs-up-pt-md-child-not-last > *:not(:last-child) {
  padding-top: 30px !important; }

.xs-up-pb-md {
  padding-bottom: 30px !important; }

.xs-up-pb-md-child > * {
  padding-bottom: 30px !important; }

.xs-up-pb-md-child-not-last > *:not(:last-child) {
  padding-bottom: 30px !important; }

.xs-up-py-md {
  padding-top: 30px !important;
  padding-bottom: 30px !important; }

.xs-up-py-md-child > * {
  padding-top: 30px !important;
  padding-bottom: 30px !important; }

.xs-up-py-md-child-not-last > *:not(:last-child) {
  padding-top: 30px !important;
  padding-bottom: 30px !important; }

.xs-up-pl-md {
  padding-left: 30px !important; }

.xs-up-pl-md-child > * {
  padding-left: 30px !important; }

.xs-up-pl-md-child-not-last > *:not(:last-child) {
  padding-left: 30px !important; }

.xs-up-pr-md {
  padding-right: 30px !important; }

.xs-up-pr-md-child > * {
  padding-right: 30px !important; }

.xs-up-pr-md-child-not-last > *:not(:last-child) {
  padding-right: 30px !important; }

.xs-up-px-md {
  padding-left: 30px !important;
  padding-right: 30px !important; }

.xs-up-px-md-child > * {
  padding-left: 30px !important;
  padding-right: 30px !important; }

.xs-up-px-md-child-not-last > *:not(:last-child) {
  padding-left: 30px !important;
  padding-right: 30px !important; }

.xs-up-m-lg {
  margin: 50px !important; }

.xs-up-m-lg-child > * {
  margin: 50px !important; }

.xs-up-m-lg-child-not-last > *:not(:last-child) {
  margin: 50px !important; }

.xs-up-mt-lg {
  margin-top: 50px !important; }

.xs-up-mt-lg-child > * {
  margin-top: 50px !important; }

.xs-up-mt-lg-child-not-last > *:not(:last-child) {
  margin-top: 50px !important; }

.xs-up-mb-lg {
  margin-bottom: 50px !important; }

.xs-up-mb-lg-child > * {
  margin-bottom: 50px !important; }

.xs-up-mb-lg-child-not-last > *:not(:last-child) {
  margin-bottom: 50px !important; }

.xs-up-my-lg {
  margin-top: 50px !important;
  margin-bottom: 50px !important; }

.xs-up-my-lg-child > * {
  margin-top: 50px !important;
  margin-bottom: 50px !important; }

.xs-up-my-lg-child-not-last > *:not(:last-child) {
  margin-top: 50px !important;
  margin-bottom: 50px !important; }

.xs-up-ml-lg {
  margin-left: 50px !important; }

.xs-up-ml-lg-child > * {
  margin-left: 50px !important; }

.xs-up-ml-lg-child-not-last > *:not(:last-child) {
  margin-left: 50px !important; }

.xs-up-mr-lg {
  margin-right: 50px !important; }

.xs-up-mr-lg-child > * {
  margin-right: 50px !important; }

.xs-up-mr-lg-child-not-last > *:not(:last-child) {
  margin-right: 50px !important; }

.xs-up-mx-lg {
  margin-left: 50px !important;
  margin-right: 50px !important; }

.xs-up-mx-lg-child > * {
  margin-left: 50px !important;
  margin-right: 50px !important; }

.xs-up-mx-lg-child-not-last > *:not(:last-child) {
  margin-left: 50px !important;
  margin-right: 50px !important; }

.xs-up-p-lg {
  padding: 50px !important; }

.xs-up-p-lg-child > * {
  padding: 50px !important; }

.xs-up-p-lg-child-not-last > *:not(:last-child) {
  padding: 50px !important; }

.xs-up-pt-lg {
  padding-top: 50px !important; }

.xs-up-pt-lg-child > * {
  padding-top: 50px !important; }

.xs-up-pt-lg-child-not-last > *:not(:last-child) {
  padding-top: 50px !important; }

.xs-up-pb-lg {
  padding-bottom: 50px !important; }

.xs-up-pb-lg-child > * {
  padding-bottom: 50px !important; }

.xs-up-pb-lg-child-not-last > *:not(:last-child) {
  padding-bottom: 50px !important; }

.xs-up-py-lg {
  padding-top: 50px !important;
  padding-bottom: 50px !important; }

.xs-up-py-lg-child > * {
  padding-top: 50px !important;
  padding-bottom: 50px !important; }

.xs-up-py-lg-child-not-last > *:not(:last-child) {
  padding-top: 50px !important;
  padding-bottom: 50px !important; }

.xs-up-pl-lg {
  padding-left: 50px !important; }

.xs-up-pl-lg-child > * {
  padding-left: 50px !important; }

.xs-up-pl-lg-child-not-last > *:not(:last-child) {
  padding-left: 50px !important; }

.xs-up-pr-lg {
  padding-right: 50px !important; }

.xs-up-pr-lg-child > * {
  padding-right: 50px !important; }

.xs-up-pr-lg-child-not-last > *:not(:last-child) {
  padding-right: 50px !important; }

.xs-up-px-lg {
  padding-left: 50px !important;
  padding-right: 50px !important; }

.xs-up-px-lg-child > * {
  padding-left: 50px !important;
  padding-right: 50px !important; }

.xs-up-px-lg-child-not-last > *:not(:last-child) {
  padding-left: 50px !important;
  padding-right: 50px !important; }

.xs-up-m-xl {
  margin: 70px !important; }

.xs-up-m-xl-child > * {
  margin: 70px !important; }

.xs-up-m-xl-child-not-last > *:not(:last-child) {
  margin: 70px !important; }

.xs-up-mt-xl {
  margin-top: 70px !important; }

.xs-up-mt-xl-child > * {
  margin-top: 70px !important; }

.xs-up-mt-xl-child-not-last > *:not(:last-child) {
  margin-top: 70px !important; }

.xs-up-mb-xl {
  margin-bottom: 70px !important; }

.xs-up-mb-xl-child > * {
  margin-bottom: 70px !important; }

.xs-up-mb-xl-child-not-last > *:not(:last-child) {
  margin-bottom: 70px !important; }

.xs-up-my-xl {
  margin-top: 70px !important;
  margin-bottom: 70px !important; }

.xs-up-my-xl-child > * {
  margin-top: 70px !important;
  margin-bottom: 70px !important; }

.xs-up-my-xl-child-not-last > *:not(:last-child) {
  margin-top: 70px !important;
  margin-bottom: 70px !important; }

.xs-up-ml-xl {
  margin-left: 70px !important; }

.xs-up-ml-xl-child > * {
  margin-left: 70px !important; }

.xs-up-ml-xl-child-not-last > *:not(:last-child) {
  margin-left: 70px !important; }

.xs-up-mr-xl {
  margin-right: 70px !important; }

.xs-up-mr-xl-child > * {
  margin-right: 70px !important; }

.xs-up-mr-xl-child-not-last > *:not(:last-child) {
  margin-right: 70px !important; }

.xs-up-mx-xl {
  margin-left: 70px !important;
  margin-right: 70px !important; }

.xs-up-mx-xl-child > * {
  margin-left: 70px !important;
  margin-right: 70px !important; }

.xs-up-mx-xl-child-not-last > *:not(:last-child) {
  margin-left: 70px !important;
  margin-right: 70px !important; }

.xs-up-p-xl {
  padding: 70px !important; }

.xs-up-p-xl-child > * {
  padding: 70px !important; }

.xs-up-p-xl-child-not-last > *:not(:last-child) {
  padding: 70px !important; }

.xs-up-pt-xl {
  padding-top: 70px !important; }

.xs-up-pt-xl-child > * {
  padding-top: 70px !important; }

.xs-up-pt-xl-child-not-last > *:not(:last-child) {
  padding-top: 70px !important; }

.xs-up-pb-xl {
  padding-bottom: 70px !important; }

.xs-up-pb-xl-child > * {
  padding-bottom: 70px !important; }

.xs-up-pb-xl-child-not-last > *:not(:last-child) {
  padding-bottom: 70px !important; }

.xs-up-py-xl {
  padding-top: 70px !important;
  padding-bottom: 70px !important; }

.xs-up-py-xl-child > * {
  padding-top: 70px !important;
  padding-bottom: 70px !important; }

.xs-up-py-xl-child-not-last > *:not(:last-child) {
  padding-top: 70px !important;
  padding-bottom: 70px !important; }

.xs-up-pl-xl {
  padding-left: 70px !important; }

.xs-up-pl-xl-child > * {
  padding-left: 70px !important; }

.xs-up-pl-xl-child-not-last > *:not(:last-child) {
  padding-left: 70px !important; }

.xs-up-pr-xl {
  padding-right: 70px !important; }

.xs-up-pr-xl-child > * {
  padding-right: 70px !important; }

.xs-up-pr-xl-child-not-last > *:not(:last-child) {
  padding-right: 70px !important; }

.xs-up-px-xl {
  padding-left: 70px !important;
  padding-right: 70px !important; }

.xs-up-px-xl-child > * {
  padding-left: 70px !important;
  padding-right: 70px !important; }

.xs-up-px-xl-child-not-last > *:not(:last-child) {
  padding-left: 70px !important;
  padding-right: 70px !important; }

.xs-up-m-xxl {
  margin: 140px !important; }

.xs-up-m-xxl-child > * {
  margin: 140px !important; }

.xs-up-m-xxl-child-not-last > *:not(:last-child) {
  margin: 140px !important; }

.xs-up-mt-xxl {
  margin-top: 140px !important; }

.xs-up-mt-xxl-child > * {
  margin-top: 140px !important; }

.xs-up-mt-xxl-child-not-last > *:not(:last-child) {
  margin-top: 140px !important; }

.xs-up-mb-xxl {
  margin-bottom: 140px !important; }

.xs-up-mb-xxl-child > * {
  margin-bottom: 140px !important; }

.xs-up-mb-xxl-child-not-last > *:not(:last-child) {
  margin-bottom: 140px !important; }

.xs-up-my-xxl {
  margin-top: 140px !important;
  margin-bottom: 140px !important; }

.xs-up-my-xxl-child > * {
  margin-top: 140px !important;
  margin-bottom: 140px !important; }

.xs-up-my-xxl-child-not-last > *:not(:last-child) {
  margin-top: 140px !important;
  margin-bottom: 140px !important; }

.xs-up-ml-xxl {
  margin-left: 140px !important; }

.xs-up-ml-xxl-child > * {
  margin-left: 140px !important; }

.xs-up-ml-xxl-child-not-last > *:not(:last-child) {
  margin-left: 140px !important; }

.xs-up-mr-xxl {
  margin-right: 140px !important; }

.xs-up-mr-xxl-child > * {
  margin-right: 140px !important; }

.xs-up-mr-xxl-child-not-last > *:not(:last-child) {
  margin-right: 140px !important; }

.xs-up-mx-xxl {
  margin-left: 140px !important;
  margin-right: 140px !important; }

.xs-up-mx-xxl-child > * {
  margin-left: 140px !important;
  margin-right: 140px !important; }

.xs-up-mx-xxl-child-not-last > *:not(:last-child) {
  margin-left: 140px !important;
  margin-right: 140px !important; }

.xs-up-p-xxl {
  padding: 140px !important; }

.xs-up-p-xxl-child > * {
  padding: 140px !important; }

.xs-up-p-xxl-child-not-last > *:not(:last-child) {
  padding: 140px !important; }

.xs-up-pt-xxl {
  padding-top: 140px !important; }

.xs-up-pt-xxl-child > * {
  padding-top: 140px !important; }

.xs-up-pt-xxl-child-not-last > *:not(:last-child) {
  padding-top: 140px !important; }

.xs-up-pb-xxl {
  padding-bottom: 140px !important; }

.xs-up-pb-xxl-child > * {
  padding-bottom: 140px !important; }

.xs-up-pb-xxl-child-not-last > *:not(:last-child) {
  padding-bottom: 140px !important; }

.xs-up-py-xxl {
  padding-top: 140px !important;
  padding-bottom: 140px !important; }

.xs-up-py-xxl-child > * {
  padding-top: 140px !important;
  padding-bottom: 140px !important; }

.xs-up-py-xxl-child-not-last > *:not(:last-child) {
  padding-top: 140px !important;
  padding-bottom: 140px !important; }

.xs-up-pl-xxl {
  padding-left: 140px !important; }

.xs-up-pl-xxl-child > * {
  padding-left: 140px !important; }

.xs-up-pl-xxl-child-not-last > *:not(:last-child) {
  padding-left: 140px !important; }

.xs-up-pr-xxl {
  padding-right: 140px !important; }

.xs-up-pr-xxl-child > * {
  padding-right: 140px !important; }

.xs-up-pr-xxl-child-not-last > *:not(:last-child) {
  padding-right: 140px !important; }

.xs-up-px-xxl {
  padding-left: 140px !important;
  padding-right: 140px !important; }

.xs-up-px-xxl-child > * {
  padding-left: 140px !important;
  padding-right: 140px !important; }

.xs-up-px-xxl-child-not-last > *:not(:last-child) {
  padding-left: 140px !important;
  padding-right: 140px !important; }

@media (min-width: 576px) {
  .sm-up-m-0 {
    margin: 0 !important; }
  .sm-up-m-0-child > * {
    margin: 0 !important; }
  .sm-up-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-mt-0 {
    margin-top: 0 !important; }
  .sm-up-mt-0-child > * {
    margin-top: 0 !important; }
  .sm-up-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-mb-0 {
    margin-bottom: 0 !important; }
  .sm-up-mb-0-child > * {
    margin-bottom: 0 !important; }
  .sm-up-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .sm-up-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .sm-up-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-ml-0 {
    margin-left: 0 !important; }
  .sm-up-ml-0-child > * {
    margin-left: 0 !important; }
  .sm-up-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-mr-0 {
    margin-right: 0 !important; }
  .sm-up-mr-0-child > * {
    margin-right: 0 !important; }
  .sm-up-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .sm-up-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .sm-up-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-p-0 {
    padding: 0 !important; }
  .sm-up-p-0-child > * {
    padding: 0 !important; }
  .sm-up-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-pt-0 {
    padding-top: 0 !important; }
  .sm-up-pt-0-child > * {
    padding-top: 0 !important; }
  .sm-up-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-pb-0 {
    padding-bottom: 0 !important; }
  .sm-up-pb-0-child > * {
    padding-bottom: 0 !important; }
  .sm-up-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .sm-up-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .sm-up-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-pl-0 {
    padding-left: 0 !important; }
  .sm-up-pl-0-child > * {
    padding-left: 0 !important; }
  .sm-up-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-pr-0 {
    padding-right: 0 !important; }
  .sm-up-pr-0-child > * {
    padding-right: 0 !important; }
  .sm-up-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .sm-up-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .sm-up-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-m-xs {
    margin: 5px !important; }
  .sm-up-m-xs-child > * {
    margin: 5px !important; }
  .sm-up-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-mt-xs {
    margin-top: 5px !important; }
  .sm-up-mt-xs-child > * {
    margin-top: 5px !important; }
  .sm-up-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-mb-xs {
    margin-bottom: 5px !important; }
  .sm-up-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .sm-up-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .sm-up-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .sm-up-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-ml-xs {
    margin-left: 5px !important; }
  .sm-up-ml-xs-child > * {
    margin-left: 5px !important; }
  .sm-up-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-mr-xs {
    margin-right: 5px !important; }
  .sm-up-mr-xs-child > * {
    margin-right: 5px !important; }
  .sm-up-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .sm-up-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .sm-up-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-p-xs {
    padding: 5px !important; }
  .sm-up-p-xs-child > * {
    padding: 5px !important; }
  .sm-up-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-pt-xs {
    padding-top: 5px !important; }
  .sm-up-pt-xs-child > * {
    padding-top: 5px !important; }
  .sm-up-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-pb-xs {
    padding-bottom: 5px !important; }
  .sm-up-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .sm-up-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .sm-up-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .sm-up-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-pl-xs {
    padding-left: 5px !important; }
  .sm-up-pl-xs-child > * {
    padding-left: 5px !important; }
  .sm-up-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-pr-xs {
    padding-right: 5px !important; }
  .sm-up-pr-xs-child > * {
    padding-right: 5px !important; }
  .sm-up-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .sm-up-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .sm-up-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-m-sm {
    margin: 15px !important; }
  .sm-up-m-sm-child > * {
    margin: 15px !important; }
  .sm-up-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-mt-sm {
    margin-top: 15px !important; }
  .sm-up-mt-sm-child > * {
    margin-top: 15px !important; }
  .sm-up-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-mb-sm {
    margin-bottom: 15px !important; }
  .sm-up-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .sm-up-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .sm-up-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .sm-up-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-ml-sm {
    margin-left: 15px !important; }
  .sm-up-ml-sm-child > * {
    margin-left: 15px !important; }
  .sm-up-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-mr-sm {
    margin-right: 15px !important; }
  .sm-up-mr-sm-child > * {
    margin-right: 15px !important; }
  .sm-up-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .sm-up-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .sm-up-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-p-sm {
    padding: 15px !important; }
  .sm-up-p-sm-child > * {
    padding: 15px !important; }
  .sm-up-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-pt-sm {
    padding-top: 15px !important; }
  .sm-up-pt-sm-child > * {
    padding-top: 15px !important; }
  .sm-up-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-pb-sm {
    padding-bottom: 15px !important; }
  .sm-up-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .sm-up-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .sm-up-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .sm-up-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-pl-sm {
    padding-left: 15px !important; }
  .sm-up-pl-sm-child > * {
    padding-left: 15px !important; }
  .sm-up-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-pr-sm {
    padding-right: 15px !important; }
  .sm-up-pr-sm-child > * {
    padding-right: 15px !important; }
  .sm-up-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .sm-up-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .sm-up-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-m-md {
    margin: 30px !important; }
  .sm-up-m-md-child > * {
    margin: 30px !important; }
  .sm-up-m-md-child-not-last > *:not(:last-child) {
    margin: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-mt-md {
    margin-top: 30px !important; }
  .sm-up-mt-md-child > * {
    margin-top: 30px !important; }
  .sm-up-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-mb-md {
    margin-bottom: 30px !important; }
  .sm-up-mb-md-child > * {
    margin-bottom: 30px !important; }
  .sm-up-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-my-md {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .sm-up-my-md-child > * {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .sm-up-my-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important;
    margin-bottom: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-ml-md {
    margin-left: 30px !important; }
  .sm-up-ml-md-child > * {
    margin-left: 30px !important; }
  .sm-up-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-mr-md {
    margin-right: 30px !important; }
  .sm-up-mr-md-child > * {
    margin-right: 30px !important; }
  .sm-up-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-mx-md {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .sm-up-mx-md-child > * {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .sm-up-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important;
    margin-right: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-p-md {
    padding: 30px !important; }
  .sm-up-p-md-child > * {
    padding: 30px !important; }
  .sm-up-p-md-child-not-last > *:not(:last-child) {
    padding: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-pt-md {
    padding-top: 30px !important; }
  .sm-up-pt-md-child > * {
    padding-top: 30px !important; }
  .sm-up-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-pb-md {
    padding-bottom: 30px !important; }
  .sm-up-pb-md-child > * {
    padding-bottom: 30px !important; }
  .sm-up-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-py-md {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .sm-up-py-md-child > * {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .sm-up-py-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important;
    padding-bottom: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-pl-md {
    padding-left: 30px !important; }
  .sm-up-pl-md-child > * {
    padding-left: 30px !important; }
  .sm-up-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-pr-md {
    padding-right: 30px !important; }
  .sm-up-pr-md-child > * {
    padding-right: 30px !important; }
  .sm-up-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-px-md {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .sm-up-px-md-child > * {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .sm-up-px-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-m-lg {
    margin: 50px !important; }
  .sm-up-m-lg-child > * {
    margin: 50px !important; }
  .sm-up-m-lg-child-not-last > *:not(:last-child) {
    margin: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-mt-lg {
    margin-top: 50px !important; }
  .sm-up-mt-lg-child > * {
    margin-top: 50px !important; }
  .sm-up-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-mb-lg {
    margin-bottom: 50px !important; }
  .sm-up-mb-lg-child > * {
    margin-bottom: 50px !important; }
  .sm-up-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-my-lg {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .sm-up-my-lg-child > * {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .sm-up-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important;
    margin-bottom: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-ml-lg {
    margin-left: 50px !important; }
  .sm-up-ml-lg-child > * {
    margin-left: 50px !important; }
  .sm-up-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-mr-lg {
    margin-right: 50px !important; }
  .sm-up-mr-lg-child > * {
    margin-right: 50px !important; }
  .sm-up-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-mx-lg {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .sm-up-mx-lg-child > * {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .sm-up-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important;
    margin-right: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-p-lg {
    padding: 50px !important; }
  .sm-up-p-lg-child > * {
    padding: 50px !important; }
  .sm-up-p-lg-child-not-last > *:not(:last-child) {
    padding: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-pt-lg {
    padding-top: 50px !important; }
  .sm-up-pt-lg-child > * {
    padding-top: 50px !important; }
  .sm-up-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-pb-lg {
    padding-bottom: 50px !important; }
  .sm-up-pb-lg-child > * {
    padding-bottom: 50px !important; }
  .sm-up-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-py-lg {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .sm-up-py-lg-child > * {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .sm-up-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important;
    padding-bottom: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-pl-lg {
    padding-left: 50px !important; }
  .sm-up-pl-lg-child > * {
    padding-left: 50px !important; }
  .sm-up-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-pr-lg {
    padding-right: 50px !important; }
  .sm-up-pr-lg-child > * {
    padding-right: 50px !important; }
  .sm-up-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-px-lg {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .sm-up-px-lg-child > * {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .sm-up-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-m-xl {
    margin: 70px !important; }
  .sm-up-m-xl-child > * {
    margin: 70px !important; }
  .sm-up-m-xl-child-not-last > *:not(:last-child) {
    margin: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-mt-xl {
    margin-top: 70px !important; }
  .sm-up-mt-xl-child > * {
    margin-top: 70px !important; }
  .sm-up-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-mb-xl {
    margin-bottom: 70px !important; }
  .sm-up-mb-xl-child > * {
    margin-bottom: 70px !important; }
  .sm-up-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-my-xl {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .sm-up-my-xl-child > * {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .sm-up-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important;
    margin-bottom: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-ml-xl {
    margin-left: 70px !important; }
  .sm-up-ml-xl-child > * {
    margin-left: 70px !important; }
  .sm-up-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-mr-xl {
    margin-right: 70px !important; }
  .sm-up-mr-xl-child > * {
    margin-right: 70px !important; }
  .sm-up-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-mx-xl {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .sm-up-mx-xl-child > * {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .sm-up-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important;
    margin-right: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-p-xl {
    padding: 70px !important; }
  .sm-up-p-xl-child > * {
    padding: 70px !important; }
  .sm-up-p-xl-child-not-last > *:not(:last-child) {
    padding: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-pt-xl {
    padding-top: 70px !important; }
  .sm-up-pt-xl-child > * {
    padding-top: 70px !important; }
  .sm-up-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-pb-xl {
    padding-bottom: 70px !important; }
  .sm-up-pb-xl-child > * {
    padding-bottom: 70px !important; }
  .sm-up-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-py-xl {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .sm-up-py-xl-child > * {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .sm-up-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important;
    padding-bottom: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-pl-xl {
    padding-left: 70px !important; }
  .sm-up-pl-xl-child > * {
    padding-left: 70px !important; }
  .sm-up-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-pr-xl {
    padding-right: 70px !important; }
  .sm-up-pr-xl-child > * {
    padding-right: 70px !important; }
  .sm-up-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-px-xl {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .sm-up-px-xl-child > * {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .sm-up-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important;
    padding-right: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-m-xxl {
    margin: 140px !important; }
  .sm-up-m-xxl-child > * {
    margin: 140px !important; }
  .sm-up-m-xxl-child-not-last > *:not(:last-child) {
    margin: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-mt-xxl {
    margin-top: 140px !important; }
  .sm-up-mt-xxl-child > * {
    margin-top: 140px !important; }
  .sm-up-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-mb-xxl {
    margin-bottom: 140px !important; }
  .sm-up-mb-xxl-child > * {
    margin-bottom: 140px !important; }
  .sm-up-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-my-xxl {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .sm-up-my-xxl-child > * {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .sm-up-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important;
    margin-bottom: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-ml-xxl {
    margin-left: 140px !important; }
  .sm-up-ml-xxl-child > * {
    margin-left: 140px !important; }
  .sm-up-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-mr-xxl {
    margin-right: 140px !important; }
  .sm-up-mr-xxl-child > * {
    margin-right: 140px !important; }
  .sm-up-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-mx-xxl {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .sm-up-mx-xxl-child > * {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .sm-up-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important;
    margin-right: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-p-xxl {
    padding: 140px !important; }
  .sm-up-p-xxl-child > * {
    padding: 140px !important; }
  .sm-up-p-xxl-child-not-last > *:not(:last-child) {
    padding: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-pt-xxl {
    padding-top: 140px !important; }
  .sm-up-pt-xxl-child > * {
    padding-top: 140px !important; }
  .sm-up-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-pb-xxl {
    padding-bottom: 140px !important; }
  .sm-up-pb-xxl-child > * {
    padding-bottom: 140px !important; }
  .sm-up-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-py-xxl {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .sm-up-py-xxl-child > * {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .sm-up-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important;
    padding-bottom: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-pl-xxl {
    padding-left: 140px !important; }
  .sm-up-pl-xxl-child > * {
    padding-left: 140px !important; }
  .sm-up-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-pr-xxl {
    padding-right: 140px !important; }
  .sm-up-pr-xxl-child > * {
    padding-right: 140px !important; }
  .sm-up-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-px-xxl {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .sm-up-px-xxl-child > * {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .sm-up-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important;
    padding-right: 140px !important; } }

@media (min-width: 768px) {
  .md-up-m-0 {
    margin: 0 !important; }
  .md-up-m-0-child > * {
    margin: 0 !important; }
  .md-up-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (min-width: 768px) {
  .md-up-mt-0 {
    margin-top: 0 !important; }
  .md-up-mt-0-child > * {
    margin-top: 0 !important; }
  .md-up-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (min-width: 768px) {
  .md-up-mb-0 {
    margin-bottom: 0 !important; }
  .md-up-mb-0-child > * {
    margin-bottom: 0 !important; }
  .md-up-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (min-width: 768px) {
  .md-up-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .md-up-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .md-up-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (min-width: 768px) {
  .md-up-ml-0 {
    margin-left: 0 !important; }
  .md-up-ml-0-child > * {
    margin-left: 0 !important; }
  .md-up-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (min-width: 768px) {
  .md-up-mr-0 {
    margin-right: 0 !important; }
  .md-up-mr-0-child > * {
    margin-right: 0 !important; }
  .md-up-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (min-width: 768px) {
  .md-up-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .md-up-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .md-up-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (min-width: 768px) {
  .md-up-p-0 {
    padding: 0 !important; }
  .md-up-p-0-child > * {
    padding: 0 !important; }
  .md-up-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (min-width: 768px) {
  .md-up-pt-0 {
    padding-top: 0 !important; }
  .md-up-pt-0-child > * {
    padding-top: 0 !important; }
  .md-up-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (min-width: 768px) {
  .md-up-pb-0 {
    padding-bottom: 0 !important; }
  .md-up-pb-0-child > * {
    padding-bottom: 0 !important; }
  .md-up-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (min-width: 768px) {
  .md-up-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .md-up-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .md-up-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (min-width: 768px) {
  .md-up-pl-0 {
    padding-left: 0 !important; }
  .md-up-pl-0-child > * {
    padding-left: 0 !important; }
  .md-up-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (min-width: 768px) {
  .md-up-pr-0 {
    padding-right: 0 !important; }
  .md-up-pr-0-child > * {
    padding-right: 0 !important; }
  .md-up-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (min-width: 768px) {
  .md-up-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .md-up-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .md-up-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (min-width: 768px) {
  .md-up-m-xs {
    margin: 5px !important; }
  .md-up-m-xs-child > * {
    margin: 5px !important; }
  .md-up-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (min-width: 768px) {
  .md-up-mt-xs {
    margin-top: 5px !important; }
  .md-up-mt-xs-child > * {
    margin-top: 5px !important; }
  .md-up-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (min-width: 768px) {
  .md-up-mb-xs {
    margin-bottom: 5px !important; }
  .md-up-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .md-up-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (min-width: 768px) {
  .md-up-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .md-up-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .md-up-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (min-width: 768px) {
  .md-up-ml-xs {
    margin-left: 5px !important; }
  .md-up-ml-xs-child > * {
    margin-left: 5px !important; }
  .md-up-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (min-width: 768px) {
  .md-up-mr-xs {
    margin-right: 5px !important; }
  .md-up-mr-xs-child > * {
    margin-right: 5px !important; }
  .md-up-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (min-width: 768px) {
  .md-up-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .md-up-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .md-up-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (min-width: 768px) {
  .md-up-p-xs {
    padding: 5px !important; }
  .md-up-p-xs-child > * {
    padding: 5px !important; }
  .md-up-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (min-width: 768px) {
  .md-up-pt-xs {
    padding-top: 5px !important; }
  .md-up-pt-xs-child > * {
    padding-top: 5px !important; }
  .md-up-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (min-width: 768px) {
  .md-up-pb-xs {
    padding-bottom: 5px !important; }
  .md-up-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .md-up-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (min-width: 768px) {
  .md-up-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .md-up-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .md-up-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (min-width: 768px) {
  .md-up-pl-xs {
    padding-left: 5px !important; }
  .md-up-pl-xs-child > * {
    padding-left: 5px !important; }
  .md-up-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (min-width: 768px) {
  .md-up-pr-xs {
    padding-right: 5px !important; }
  .md-up-pr-xs-child > * {
    padding-right: 5px !important; }
  .md-up-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (min-width: 768px) {
  .md-up-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .md-up-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .md-up-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (min-width: 768px) {
  .md-up-m-sm {
    margin: 15px !important; }
  .md-up-m-sm-child > * {
    margin: 15px !important; }
  .md-up-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (min-width: 768px) {
  .md-up-mt-sm {
    margin-top: 15px !important; }
  .md-up-mt-sm-child > * {
    margin-top: 15px !important; }
  .md-up-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (min-width: 768px) {
  .md-up-mb-sm {
    margin-bottom: 15px !important; }
  .md-up-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .md-up-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (min-width: 768px) {
  .md-up-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .md-up-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .md-up-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (min-width: 768px) {
  .md-up-ml-sm {
    margin-left: 15px !important; }
  .md-up-ml-sm-child > * {
    margin-left: 15px !important; }
  .md-up-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (min-width: 768px) {
  .md-up-mr-sm {
    margin-right: 15px !important; }
  .md-up-mr-sm-child > * {
    margin-right: 15px !important; }
  .md-up-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (min-width: 768px) {
  .md-up-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .md-up-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .md-up-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (min-width: 768px) {
  .md-up-p-sm {
    padding: 15px !important; }
  .md-up-p-sm-child > * {
    padding: 15px !important; }
  .md-up-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (min-width: 768px) {
  .md-up-pt-sm {
    padding-top: 15px !important; }
  .md-up-pt-sm-child > * {
    padding-top: 15px !important; }
  .md-up-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (min-width: 768px) {
  .md-up-pb-sm {
    padding-bottom: 15px !important; }
  .md-up-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .md-up-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (min-width: 768px) {
  .md-up-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .md-up-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .md-up-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (min-width: 768px) {
  .md-up-pl-sm {
    padding-left: 15px !important; }
  .md-up-pl-sm-child > * {
    padding-left: 15px !important; }
  .md-up-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (min-width: 768px) {
  .md-up-pr-sm {
    padding-right: 15px !important; }
  .md-up-pr-sm-child > * {
    padding-right: 15px !important; }
  .md-up-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (min-width: 768px) {
  .md-up-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .md-up-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .md-up-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (min-width: 768px) {
  .md-up-m-md {
    margin: 30px !important; }
  .md-up-m-md-child > * {
    margin: 30px !important; }
  .md-up-m-md-child-not-last > *:not(:last-child) {
    margin: 30px !important; } }

@media (min-width: 768px) {
  .md-up-mt-md {
    margin-top: 30px !important; }
  .md-up-mt-md-child > * {
    margin-top: 30px !important; }
  .md-up-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important; } }

@media (min-width: 768px) {
  .md-up-mb-md {
    margin-bottom: 30px !important; }
  .md-up-mb-md-child > * {
    margin-bottom: 30px !important; }
  .md-up-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 30px !important; } }

@media (min-width: 768px) {
  .md-up-my-md {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .md-up-my-md-child > * {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .md-up-my-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important;
    margin-bottom: 30px !important; } }

@media (min-width: 768px) {
  .md-up-ml-md {
    margin-left: 30px !important; }
  .md-up-ml-md-child > * {
    margin-left: 30px !important; }
  .md-up-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important; } }

@media (min-width: 768px) {
  .md-up-mr-md {
    margin-right: 30px !important; }
  .md-up-mr-md-child > * {
    margin-right: 30px !important; }
  .md-up-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 30px !important; } }

@media (min-width: 768px) {
  .md-up-mx-md {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .md-up-mx-md-child > * {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .md-up-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important;
    margin-right: 30px !important; } }

@media (min-width: 768px) {
  .md-up-p-md {
    padding: 30px !important; }
  .md-up-p-md-child > * {
    padding: 30px !important; }
  .md-up-p-md-child-not-last > *:not(:last-child) {
    padding: 30px !important; } }

@media (min-width: 768px) {
  .md-up-pt-md {
    padding-top: 30px !important; }
  .md-up-pt-md-child > * {
    padding-top: 30px !important; }
  .md-up-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important; } }

@media (min-width: 768px) {
  .md-up-pb-md {
    padding-bottom: 30px !important; }
  .md-up-pb-md-child > * {
    padding-bottom: 30px !important; }
  .md-up-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 30px !important; } }

@media (min-width: 768px) {
  .md-up-py-md {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .md-up-py-md-child > * {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .md-up-py-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important;
    padding-bottom: 30px !important; } }

@media (min-width: 768px) {
  .md-up-pl-md {
    padding-left: 30px !important; }
  .md-up-pl-md-child > * {
    padding-left: 30px !important; }
  .md-up-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important; } }

@media (min-width: 768px) {
  .md-up-pr-md {
    padding-right: 30px !important; }
  .md-up-pr-md-child > * {
    padding-right: 30px !important; }
  .md-up-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 30px !important; } }

@media (min-width: 768px) {
  .md-up-px-md {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .md-up-px-md-child > * {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .md-up-px-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media (min-width: 768px) {
  .md-up-m-lg {
    margin: 50px !important; }
  .md-up-m-lg-child > * {
    margin: 50px !important; }
  .md-up-m-lg-child-not-last > *:not(:last-child) {
    margin: 50px !important; } }

@media (min-width: 768px) {
  .md-up-mt-lg {
    margin-top: 50px !important; }
  .md-up-mt-lg-child > * {
    margin-top: 50px !important; }
  .md-up-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important; } }

@media (min-width: 768px) {
  .md-up-mb-lg {
    margin-bottom: 50px !important; }
  .md-up-mb-lg-child > * {
    margin-bottom: 50px !important; }
  .md-up-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 50px !important; } }

@media (min-width: 768px) {
  .md-up-my-lg {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .md-up-my-lg-child > * {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .md-up-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important;
    margin-bottom: 50px !important; } }

@media (min-width: 768px) {
  .md-up-ml-lg {
    margin-left: 50px !important; }
  .md-up-ml-lg-child > * {
    margin-left: 50px !important; }
  .md-up-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important; } }

@media (min-width: 768px) {
  .md-up-mr-lg {
    margin-right: 50px !important; }
  .md-up-mr-lg-child > * {
    margin-right: 50px !important; }
  .md-up-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 50px !important; } }

@media (min-width: 768px) {
  .md-up-mx-lg {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .md-up-mx-lg-child > * {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .md-up-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important;
    margin-right: 50px !important; } }

@media (min-width: 768px) {
  .md-up-p-lg {
    padding: 50px !important; }
  .md-up-p-lg-child > * {
    padding: 50px !important; }
  .md-up-p-lg-child-not-last > *:not(:last-child) {
    padding: 50px !important; } }

@media (min-width: 768px) {
  .md-up-pt-lg {
    padding-top: 50px !important; }
  .md-up-pt-lg-child > * {
    padding-top: 50px !important; }
  .md-up-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important; } }

@media (min-width: 768px) {
  .md-up-pb-lg {
    padding-bottom: 50px !important; }
  .md-up-pb-lg-child > * {
    padding-bottom: 50px !important; }
  .md-up-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 50px !important; } }

@media (min-width: 768px) {
  .md-up-py-lg {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .md-up-py-lg-child > * {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .md-up-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important;
    padding-bottom: 50px !important; } }

@media (min-width: 768px) {
  .md-up-pl-lg {
    padding-left: 50px !important; }
  .md-up-pl-lg-child > * {
    padding-left: 50px !important; }
  .md-up-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important; } }

@media (min-width: 768px) {
  .md-up-pr-lg {
    padding-right: 50px !important; }
  .md-up-pr-lg-child > * {
    padding-right: 50px !important; }
  .md-up-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 50px !important; } }

@media (min-width: 768px) {
  .md-up-px-lg {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .md-up-px-lg-child > * {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .md-up-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media (min-width: 768px) {
  .md-up-m-xl {
    margin: 70px !important; }
  .md-up-m-xl-child > * {
    margin: 70px !important; }
  .md-up-m-xl-child-not-last > *:not(:last-child) {
    margin: 70px !important; } }

@media (min-width: 768px) {
  .md-up-mt-xl {
    margin-top: 70px !important; }
  .md-up-mt-xl-child > * {
    margin-top: 70px !important; }
  .md-up-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important; } }

@media (min-width: 768px) {
  .md-up-mb-xl {
    margin-bottom: 70px !important; }
  .md-up-mb-xl-child > * {
    margin-bottom: 70px !important; }
  .md-up-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 70px !important; } }

@media (min-width: 768px) {
  .md-up-my-xl {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .md-up-my-xl-child > * {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .md-up-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important;
    margin-bottom: 70px !important; } }

@media (min-width: 768px) {
  .md-up-ml-xl {
    margin-left: 70px !important; }
  .md-up-ml-xl-child > * {
    margin-left: 70px !important; }
  .md-up-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important; } }

@media (min-width: 768px) {
  .md-up-mr-xl {
    margin-right: 70px !important; }
  .md-up-mr-xl-child > * {
    margin-right: 70px !important; }
  .md-up-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 70px !important; } }

@media (min-width: 768px) {
  .md-up-mx-xl {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .md-up-mx-xl-child > * {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .md-up-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important;
    margin-right: 70px !important; } }

@media (min-width: 768px) {
  .md-up-p-xl {
    padding: 70px !important; }
  .md-up-p-xl-child > * {
    padding: 70px !important; }
  .md-up-p-xl-child-not-last > *:not(:last-child) {
    padding: 70px !important; } }

@media (min-width: 768px) {
  .md-up-pt-xl {
    padding-top: 70px !important; }
  .md-up-pt-xl-child > * {
    padding-top: 70px !important; }
  .md-up-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important; } }

@media (min-width: 768px) {
  .md-up-pb-xl {
    padding-bottom: 70px !important; }
  .md-up-pb-xl-child > * {
    padding-bottom: 70px !important; }
  .md-up-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 70px !important; } }

@media (min-width: 768px) {
  .md-up-py-xl {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .md-up-py-xl-child > * {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .md-up-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important;
    padding-bottom: 70px !important; } }

@media (min-width: 768px) {
  .md-up-pl-xl {
    padding-left: 70px !important; }
  .md-up-pl-xl-child > * {
    padding-left: 70px !important; }
  .md-up-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important; } }

@media (min-width: 768px) {
  .md-up-pr-xl {
    padding-right: 70px !important; }
  .md-up-pr-xl-child > * {
    padding-right: 70px !important; }
  .md-up-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 70px !important; } }

@media (min-width: 768px) {
  .md-up-px-xl {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .md-up-px-xl-child > * {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .md-up-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important;
    padding-right: 70px !important; } }

@media (min-width: 768px) {
  .md-up-m-xxl {
    margin: 140px !important; }
  .md-up-m-xxl-child > * {
    margin: 140px !important; }
  .md-up-m-xxl-child-not-last > *:not(:last-child) {
    margin: 140px !important; } }

@media (min-width: 768px) {
  .md-up-mt-xxl {
    margin-top: 140px !important; }
  .md-up-mt-xxl-child > * {
    margin-top: 140px !important; }
  .md-up-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important; } }

@media (min-width: 768px) {
  .md-up-mb-xxl {
    margin-bottom: 140px !important; }
  .md-up-mb-xxl-child > * {
    margin-bottom: 140px !important; }
  .md-up-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 140px !important; } }

@media (min-width: 768px) {
  .md-up-my-xxl {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .md-up-my-xxl-child > * {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .md-up-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important;
    margin-bottom: 140px !important; } }

@media (min-width: 768px) {
  .md-up-ml-xxl {
    margin-left: 140px !important; }
  .md-up-ml-xxl-child > * {
    margin-left: 140px !important; }
  .md-up-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important; } }

@media (min-width: 768px) {
  .md-up-mr-xxl {
    margin-right: 140px !important; }
  .md-up-mr-xxl-child > * {
    margin-right: 140px !important; }
  .md-up-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 140px !important; } }

@media (min-width: 768px) {
  .md-up-mx-xxl {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .md-up-mx-xxl-child > * {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .md-up-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important;
    margin-right: 140px !important; } }

@media (min-width: 768px) {
  .md-up-p-xxl {
    padding: 140px !important; }
  .md-up-p-xxl-child > * {
    padding: 140px !important; }
  .md-up-p-xxl-child-not-last > *:not(:last-child) {
    padding: 140px !important; } }

@media (min-width: 768px) {
  .md-up-pt-xxl {
    padding-top: 140px !important; }
  .md-up-pt-xxl-child > * {
    padding-top: 140px !important; }
  .md-up-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important; } }

@media (min-width: 768px) {
  .md-up-pb-xxl {
    padding-bottom: 140px !important; }
  .md-up-pb-xxl-child > * {
    padding-bottom: 140px !important; }
  .md-up-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 140px !important; } }

@media (min-width: 768px) {
  .md-up-py-xxl {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .md-up-py-xxl-child > * {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .md-up-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important;
    padding-bottom: 140px !important; } }

@media (min-width: 768px) {
  .md-up-pl-xxl {
    padding-left: 140px !important; }
  .md-up-pl-xxl-child > * {
    padding-left: 140px !important; }
  .md-up-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important; } }

@media (min-width: 768px) {
  .md-up-pr-xxl {
    padding-right: 140px !important; }
  .md-up-pr-xxl-child > * {
    padding-right: 140px !important; }
  .md-up-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 140px !important; } }

@media (min-width: 768px) {
  .md-up-px-xxl {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .md-up-px-xxl-child > * {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .md-up-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important;
    padding-right: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-m-0 {
    margin: 0 !important; }
  .lg-up-m-0-child > * {
    margin: 0 !important; }
  .lg-up-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-mt-0 {
    margin-top: 0 !important; }
  .lg-up-mt-0-child > * {
    margin-top: 0 !important; }
  .lg-up-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-mb-0 {
    margin-bottom: 0 !important; }
  .lg-up-mb-0-child > * {
    margin-bottom: 0 !important; }
  .lg-up-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .lg-up-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .lg-up-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-ml-0 {
    margin-left: 0 !important; }
  .lg-up-ml-0-child > * {
    margin-left: 0 !important; }
  .lg-up-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-mr-0 {
    margin-right: 0 !important; }
  .lg-up-mr-0-child > * {
    margin-right: 0 !important; }
  .lg-up-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .lg-up-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .lg-up-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-p-0 {
    padding: 0 !important; }
  .lg-up-p-0-child > * {
    padding: 0 !important; }
  .lg-up-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-pt-0 {
    padding-top: 0 !important; }
  .lg-up-pt-0-child > * {
    padding-top: 0 !important; }
  .lg-up-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-pb-0 {
    padding-bottom: 0 !important; }
  .lg-up-pb-0-child > * {
    padding-bottom: 0 !important; }
  .lg-up-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .lg-up-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .lg-up-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-pl-0 {
    padding-left: 0 !important; }
  .lg-up-pl-0-child > * {
    padding-left: 0 !important; }
  .lg-up-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-pr-0 {
    padding-right: 0 !important; }
  .lg-up-pr-0-child > * {
    padding-right: 0 !important; }
  .lg-up-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .lg-up-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .lg-up-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-m-xs {
    margin: 5px !important; }
  .lg-up-m-xs-child > * {
    margin: 5px !important; }
  .lg-up-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-mt-xs {
    margin-top: 5px !important; }
  .lg-up-mt-xs-child > * {
    margin-top: 5px !important; }
  .lg-up-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-mb-xs {
    margin-bottom: 5px !important; }
  .lg-up-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .lg-up-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .lg-up-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .lg-up-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-ml-xs {
    margin-left: 5px !important; }
  .lg-up-ml-xs-child > * {
    margin-left: 5px !important; }
  .lg-up-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-mr-xs {
    margin-right: 5px !important; }
  .lg-up-mr-xs-child > * {
    margin-right: 5px !important; }
  .lg-up-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .lg-up-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .lg-up-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-p-xs {
    padding: 5px !important; }
  .lg-up-p-xs-child > * {
    padding: 5px !important; }
  .lg-up-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-pt-xs {
    padding-top: 5px !important; }
  .lg-up-pt-xs-child > * {
    padding-top: 5px !important; }
  .lg-up-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-pb-xs {
    padding-bottom: 5px !important; }
  .lg-up-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .lg-up-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .lg-up-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .lg-up-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-pl-xs {
    padding-left: 5px !important; }
  .lg-up-pl-xs-child > * {
    padding-left: 5px !important; }
  .lg-up-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-pr-xs {
    padding-right: 5px !important; }
  .lg-up-pr-xs-child > * {
    padding-right: 5px !important; }
  .lg-up-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .lg-up-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .lg-up-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-m-sm {
    margin: 15px !important; }
  .lg-up-m-sm-child > * {
    margin: 15px !important; }
  .lg-up-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-mt-sm {
    margin-top: 15px !important; }
  .lg-up-mt-sm-child > * {
    margin-top: 15px !important; }
  .lg-up-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-mb-sm {
    margin-bottom: 15px !important; }
  .lg-up-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .lg-up-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .lg-up-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .lg-up-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-ml-sm {
    margin-left: 15px !important; }
  .lg-up-ml-sm-child > * {
    margin-left: 15px !important; }
  .lg-up-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-mr-sm {
    margin-right: 15px !important; }
  .lg-up-mr-sm-child > * {
    margin-right: 15px !important; }
  .lg-up-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .lg-up-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .lg-up-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-p-sm {
    padding: 15px !important; }
  .lg-up-p-sm-child > * {
    padding: 15px !important; }
  .lg-up-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-pt-sm {
    padding-top: 15px !important; }
  .lg-up-pt-sm-child > * {
    padding-top: 15px !important; }
  .lg-up-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-pb-sm {
    padding-bottom: 15px !important; }
  .lg-up-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .lg-up-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .lg-up-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .lg-up-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-pl-sm {
    padding-left: 15px !important; }
  .lg-up-pl-sm-child > * {
    padding-left: 15px !important; }
  .lg-up-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-pr-sm {
    padding-right: 15px !important; }
  .lg-up-pr-sm-child > * {
    padding-right: 15px !important; }
  .lg-up-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .lg-up-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .lg-up-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-m-md {
    margin: 30px !important; }
  .lg-up-m-md-child > * {
    margin: 30px !important; }
  .lg-up-m-md-child-not-last > *:not(:last-child) {
    margin: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-mt-md {
    margin-top: 30px !important; }
  .lg-up-mt-md-child > * {
    margin-top: 30px !important; }
  .lg-up-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-mb-md {
    margin-bottom: 30px !important; }
  .lg-up-mb-md-child > * {
    margin-bottom: 30px !important; }
  .lg-up-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-my-md {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .lg-up-my-md-child > * {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .lg-up-my-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important;
    margin-bottom: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-ml-md {
    margin-left: 30px !important; }
  .lg-up-ml-md-child > * {
    margin-left: 30px !important; }
  .lg-up-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-mr-md {
    margin-right: 30px !important; }
  .lg-up-mr-md-child > * {
    margin-right: 30px !important; }
  .lg-up-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-mx-md {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .lg-up-mx-md-child > * {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .lg-up-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important;
    margin-right: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-p-md {
    padding: 30px !important; }
  .lg-up-p-md-child > * {
    padding: 30px !important; }
  .lg-up-p-md-child-not-last > *:not(:last-child) {
    padding: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-pt-md {
    padding-top: 30px !important; }
  .lg-up-pt-md-child > * {
    padding-top: 30px !important; }
  .lg-up-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-pb-md {
    padding-bottom: 30px !important; }
  .lg-up-pb-md-child > * {
    padding-bottom: 30px !important; }
  .lg-up-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-py-md {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .lg-up-py-md-child > * {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .lg-up-py-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important;
    padding-bottom: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-pl-md {
    padding-left: 30px !important; }
  .lg-up-pl-md-child > * {
    padding-left: 30px !important; }
  .lg-up-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-pr-md {
    padding-right: 30px !important; }
  .lg-up-pr-md-child > * {
    padding-right: 30px !important; }
  .lg-up-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-px-md {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .lg-up-px-md-child > * {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .lg-up-px-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-m-lg {
    margin: 50px !important; }
  .lg-up-m-lg-child > * {
    margin: 50px !important; }
  .lg-up-m-lg-child-not-last > *:not(:last-child) {
    margin: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-mt-lg {
    margin-top: 50px !important; }
  .lg-up-mt-lg-child > * {
    margin-top: 50px !important; }
  .lg-up-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-mb-lg {
    margin-bottom: 50px !important; }
  .lg-up-mb-lg-child > * {
    margin-bottom: 50px !important; }
  .lg-up-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-my-lg {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .lg-up-my-lg-child > * {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .lg-up-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important;
    margin-bottom: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-ml-lg {
    margin-left: 50px !important; }
  .lg-up-ml-lg-child > * {
    margin-left: 50px !important; }
  .lg-up-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-mr-lg {
    margin-right: 50px !important; }
  .lg-up-mr-lg-child > * {
    margin-right: 50px !important; }
  .lg-up-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-mx-lg {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .lg-up-mx-lg-child > * {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .lg-up-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important;
    margin-right: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-p-lg {
    padding: 50px !important; }
  .lg-up-p-lg-child > * {
    padding: 50px !important; }
  .lg-up-p-lg-child-not-last > *:not(:last-child) {
    padding: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-pt-lg {
    padding-top: 50px !important; }
  .lg-up-pt-lg-child > * {
    padding-top: 50px !important; }
  .lg-up-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-pb-lg {
    padding-bottom: 50px !important; }
  .lg-up-pb-lg-child > * {
    padding-bottom: 50px !important; }
  .lg-up-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-py-lg {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .lg-up-py-lg-child > * {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .lg-up-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important;
    padding-bottom: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-pl-lg {
    padding-left: 50px !important; }
  .lg-up-pl-lg-child > * {
    padding-left: 50px !important; }
  .lg-up-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-pr-lg {
    padding-right: 50px !important; }
  .lg-up-pr-lg-child > * {
    padding-right: 50px !important; }
  .lg-up-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-px-lg {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .lg-up-px-lg-child > * {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .lg-up-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-m-xl {
    margin: 70px !important; }
  .lg-up-m-xl-child > * {
    margin: 70px !important; }
  .lg-up-m-xl-child-not-last > *:not(:last-child) {
    margin: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-mt-xl {
    margin-top: 70px !important; }
  .lg-up-mt-xl-child > * {
    margin-top: 70px !important; }
  .lg-up-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-mb-xl {
    margin-bottom: 70px !important; }
  .lg-up-mb-xl-child > * {
    margin-bottom: 70px !important; }
  .lg-up-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-my-xl {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .lg-up-my-xl-child > * {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .lg-up-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important;
    margin-bottom: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-ml-xl {
    margin-left: 70px !important; }
  .lg-up-ml-xl-child > * {
    margin-left: 70px !important; }
  .lg-up-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-mr-xl {
    margin-right: 70px !important; }
  .lg-up-mr-xl-child > * {
    margin-right: 70px !important; }
  .lg-up-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-mx-xl {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .lg-up-mx-xl-child > * {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .lg-up-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important;
    margin-right: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-p-xl {
    padding: 70px !important; }
  .lg-up-p-xl-child > * {
    padding: 70px !important; }
  .lg-up-p-xl-child-not-last > *:not(:last-child) {
    padding: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-pt-xl {
    padding-top: 70px !important; }
  .lg-up-pt-xl-child > * {
    padding-top: 70px !important; }
  .lg-up-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-pb-xl {
    padding-bottom: 70px !important; }
  .lg-up-pb-xl-child > * {
    padding-bottom: 70px !important; }
  .lg-up-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-py-xl {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .lg-up-py-xl-child > * {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .lg-up-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important;
    padding-bottom: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-pl-xl {
    padding-left: 70px !important; }
  .lg-up-pl-xl-child > * {
    padding-left: 70px !important; }
  .lg-up-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-pr-xl {
    padding-right: 70px !important; }
  .lg-up-pr-xl-child > * {
    padding-right: 70px !important; }
  .lg-up-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-px-xl {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .lg-up-px-xl-child > * {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .lg-up-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important;
    padding-right: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-m-xxl {
    margin: 140px !important; }
  .lg-up-m-xxl-child > * {
    margin: 140px !important; }
  .lg-up-m-xxl-child-not-last > *:not(:last-child) {
    margin: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-mt-xxl {
    margin-top: 140px !important; }
  .lg-up-mt-xxl-child > * {
    margin-top: 140px !important; }
  .lg-up-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-mb-xxl {
    margin-bottom: 140px !important; }
  .lg-up-mb-xxl-child > * {
    margin-bottom: 140px !important; }
  .lg-up-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-my-xxl {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .lg-up-my-xxl-child > * {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .lg-up-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important;
    margin-bottom: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-ml-xxl {
    margin-left: 140px !important; }
  .lg-up-ml-xxl-child > * {
    margin-left: 140px !important; }
  .lg-up-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-mr-xxl {
    margin-right: 140px !important; }
  .lg-up-mr-xxl-child > * {
    margin-right: 140px !important; }
  .lg-up-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-mx-xxl {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .lg-up-mx-xxl-child > * {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .lg-up-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important;
    margin-right: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-p-xxl {
    padding: 140px !important; }
  .lg-up-p-xxl-child > * {
    padding: 140px !important; }
  .lg-up-p-xxl-child-not-last > *:not(:last-child) {
    padding: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-pt-xxl {
    padding-top: 140px !important; }
  .lg-up-pt-xxl-child > * {
    padding-top: 140px !important; }
  .lg-up-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-pb-xxl {
    padding-bottom: 140px !important; }
  .lg-up-pb-xxl-child > * {
    padding-bottom: 140px !important; }
  .lg-up-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-py-xxl {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .lg-up-py-xxl-child > * {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .lg-up-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important;
    padding-bottom: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-pl-xxl {
    padding-left: 140px !important; }
  .lg-up-pl-xxl-child > * {
    padding-left: 140px !important; }
  .lg-up-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-pr-xxl {
    padding-right: 140px !important; }
  .lg-up-pr-xxl-child > * {
    padding-right: 140px !important; }
  .lg-up-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-px-xxl {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .lg-up-px-xxl-child > * {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .lg-up-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important;
    padding-right: 140px !important; } }

@media (min-width: 1200px) {
  .xl-up-m-0 {
    margin: 0 !important; }
  .xl-up-m-0-child > * {
    margin: 0 !important; }
  .xl-up-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (min-width: 1200px) {
  .xl-up-mt-0 {
    margin-top: 0 !important; }
  .xl-up-mt-0-child > * {
    margin-top: 0 !important; }
  .xl-up-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (min-width: 1200px) {
  .xl-up-mb-0 {
    margin-bottom: 0 !important; }
  .xl-up-mb-0-child > * {
    margin-bottom: 0 !important; }
  .xl-up-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (min-width: 1200px) {
  .xl-up-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .xl-up-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .xl-up-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (min-width: 1200px) {
  .xl-up-ml-0 {
    margin-left: 0 !important; }
  .xl-up-ml-0-child > * {
    margin-left: 0 !important; }
  .xl-up-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (min-width: 1200px) {
  .xl-up-mr-0 {
    margin-right: 0 !important; }
  .xl-up-mr-0-child > * {
    margin-right: 0 !important; }
  .xl-up-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (min-width: 1200px) {
  .xl-up-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .xl-up-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .xl-up-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (min-width: 1200px) {
  .xl-up-p-0 {
    padding: 0 !important; }
  .xl-up-p-0-child > * {
    padding: 0 !important; }
  .xl-up-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (min-width: 1200px) {
  .xl-up-pt-0 {
    padding-top: 0 !important; }
  .xl-up-pt-0-child > * {
    padding-top: 0 !important; }
  .xl-up-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (min-width: 1200px) {
  .xl-up-pb-0 {
    padding-bottom: 0 !important; }
  .xl-up-pb-0-child > * {
    padding-bottom: 0 !important; }
  .xl-up-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (min-width: 1200px) {
  .xl-up-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .xl-up-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .xl-up-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (min-width: 1200px) {
  .xl-up-pl-0 {
    padding-left: 0 !important; }
  .xl-up-pl-0-child > * {
    padding-left: 0 !important; }
  .xl-up-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (min-width: 1200px) {
  .xl-up-pr-0 {
    padding-right: 0 !important; }
  .xl-up-pr-0-child > * {
    padding-right: 0 !important; }
  .xl-up-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (min-width: 1200px) {
  .xl-up-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .xl-up-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .xl-up-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (min-width: 1200px) {
  .xl-up-m-xs {
    margin: 5px !important; }
  .xl-up-m-xs-child > * {
    margin: 5px !important; }
  .xl-up-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (min-width: 1200px) {
  .xl-up-mt-xs {
    margin-top: 5px !important; }
  .xl-up-mt-xs-child > * {
    margin-top: 5px !important; }
  .xl-up-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (min-width: 1200px) {
  .xl-up-mb-xs {
    margin-bottom: 5px !important; }
  .xl-up-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .xl-up-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (min-width: 1200px) {
  .xl-up-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .xl-up-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .xl-up-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (min-width: 1200px) {
  .xl-up-ml-xs {
    margin-left: 5px !important; }
  .xl-up-ml-xs-child > * {
    margin-left: 5px !important; }
  .xl-up-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (min-width: 1200px) {
  .xl-up-mr-xs {
    margin-right: 5px !important; }
  .xl-up-mr-xs-child > * {
    margin-right: 5px !important; }
  .xl-up-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (min-width: 1200px) {
  .xl-up-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .xl-up-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .xl-up-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (min-width: 1200px) {
  .xl-up-p-xs {
    padding: 5px !important; }
  .xl-up-p-xs-child > * {
    padding: 5px !important; }
  .xl-up-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (min-width: 1200px) {
  .xl-up-pt-xs {
    padding-top: 5px !important; }
  .xl-up-pt-xs-child > * {
    padding-top: 5px !important; }
  .xl-up-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (min-width: 1200px) {
  .xl-up-pb-xs {
    padding-bottom: 5px !important; }
  .xl-up-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .xl-up-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (min-width: 1200px) {
  .xl-up-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .xl-up-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .xl-up-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (min-width: 1200px) {
  .xl-up-pl-xs {
    padding-left: 5px !important; }
  .xl-up-pl-xs-child > * {
    padding-left: 5px !important; }
  .xl-up-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (min-width: 1200px) {
  .xl-up-pr-xs {
    padding-right: 5px !important; }
  .xl-up-pr-xs-child > * {
    padding-right: 5px !important; }
  .xl-up-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (min-width: 1200px) {
  .xl-up-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .xl-up-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .xl-up-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (min-width: 1200px) {
  .xl-up-m-sm {
    margin: 15px !important; }
  .xl-up-m-sm-child > * {
    margin: 15px !important; }
  .xl-up-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (min-width: 1200px) {
  .xl-up-mt-sm {
    margin-top: 15px !important; }
  .xl-up-mt-sm-child > * {
    margin-top: 15px !important; }
  .xl-up-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (min-width: 1200px) {
  .xl-up-mb-sm {
    margin-bottom: 15px !important; }
  .xl-up-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .xl-up-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (min-width: 1200px) {
  .xl-up-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .xl-up-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .xl-up-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (min-width: 1200px) {
  .xl-up-ml-sm {
    margin-left: 15px !important; }
  .xl-up-ml-sm-child > * {
    margin-left: 15px !important; }
  .xl-up-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (min-width: 1200px) {
  .xl-up-mr-sm {
    margin-right: 15px !important; }
  .xl-up-mr-sm-child > * {
    margin-right: 15px !important; }
  .xl-up-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (min-width: 1200px) {
  .xl-up-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .xl-up-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .xl-up-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (min-width: 1200px) {
  .xl-up-p-sm {
    padding: 15px !important; }
  .xl-up-p-sm-child > * {
    padding: 15px !important; }
  .xl-up-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (min-width: 1200px) {
  .xl-up-pt-sm {
    padding-top: 15px !important; }
  .xl-up-pt-sm-child > * {
    padding-top: 15px !important; }
  .xl-up-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (min-width: 1200px) {
  .xl-up-pb-sm {
    padding-bottom: 15px !important; }
  .xl-up-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .xl-up-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (min-width: 1200px) {
  .xl-up-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .xl-up-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .xl-up-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (min-width: 1200px) {
  .xl-up-pl-sm {
    padding-left: 15px !important; }
  .xl-up-pl-sm-child > * {
    padding-left: 15px !important; }
  .xl-up-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (min-width: 1200px) {
  .xl-up-pr-sm {
    padding-right: 15px !important; }
  .xl-up-pr-sm-child > * {
    padding-right: 15px !important; }
  .xl-up-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (min-width: 1200px) {
  .xl-up-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .xl-up-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .xl-up-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (min-width: 1200px) {
  .xl-up-m-md {
    margin: 30px !important; }
  .xl-up-m-md-child > * {
    margin: 30px !important; }
  .xl-up-m-md-child-not-last > *:not(:last-child) {
    margin: 30px !important; } }

@media (min-width: 1200px) {
  .xl-up-mt-md {
    margin-top: 30px !important; }
  .xl-up-mt-md-child > * {
    margin-top: 30px !important; }
  .xl-up-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important; } }

@media (min-width: 1200px) {
  .xl-up-mb-md {
    margin-bottom: 30px !important; }
  .xl-up-mb-md-child > * {
    margin-bottom: 30px !important; }
  .xl-up-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 30px !important; } }

@media (min-width: 1200px) {
  .xl-up-my-md {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .xl-up-my-md-child > * {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .xl-up-my-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important;
    margin-bottom: 30px !important; } }

@media (min-width: 1200px) {
  .xl-up-ml-md {
    margin-left: 30px !important; }
  .xl-up-ml-md-child > * {
    margin-left: 30px !important; }
  .xl-up-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important; } }

@media (min-width: 1200px) {
  .xl-up-mr-md {
    margin-right: 30px !important; }
  .xl-up-mr-md-child > * {
    margin-right: 30px !important; }
  .xl-up-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 30px !important; } }

@media (min-width: 1200px) {
  .xl-up-mx-md {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .xl-up-mx-md-child > * {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .xl-up-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important;
    margin-right: 30px !important; } }

@media (min-width: 1200px) {
  .xl-up-p-md {
    padding: 30px !important; }
  .xl-up-p-md-child > * {
    padding: 30px !important; }
  .xl-up-p-md-child-not-last > *:not(:last-child) {
    padding: 30px !important; } }

@media (min-width: 1200px) {
  .xl-up-pt-md {
    padding-top: 30px !important; }
  .xl-up-pt-md-child > * {
    padding-top: 30px !important; }
  .xl-up-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important; } }

@media (min-width: 1200px) {
  .xl-up-pb-md {
    padding-bottom: 30px !important; }
  .xl-up-pb-md-child > * {
    padding-bottom: 30px !important; }
  .xl-up-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 30px !important; } }

@media (min-width: 1200px) {
  .xl-up-py-md {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .xl-up-py-md-child > * {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .xl-up-py-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important;
    padding-bottom: 30px !important; } }

@media (min-width: 1200px) {
  .xl-up-pl-md {
    padding-left: 30px !important; }
  .xl-up-pl-md-child > * {
    padding-left: 30px !important; }
  .xl-up-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important; } }

@media (min-width: 1200px) {
  .xl-up-pr-md {
    padding-right: 30px !important; }
  .xl-up-pr-md-child > * {
    padding-right: 30px !important; }
  .xl-up-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 30px !important; } }

@media (min-width: 1200px) {
  .xl-up-px-md {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .xl-up-px-md-child > * {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .xl-up-px-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media (min-width: 1200px) {
  .xl-up-m-lg {
    margin: 50px !important; }
  .xl-up-m-lg-child > * {
    margin: 50px !important; }
  .xl-up-m-lg-child-not-last > *:not(:last-child) {
    margin: 50px !important; } }

@media (min-width: 1200px) {
  .xl-up-mt-lg {
    margin-top: 50px !important; }
  .xl-up-mt-lg-child > * {
    margin-top: 50px !important; }
  .xl-up-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important; } }

@media (min-width: 1200px) {
  .xl-up-mb-lg {
    margin-bottom: 50px !important; }
  .xl-up-mb-lg-child > * {
    margin-bottom: 50px !important; }
  .xl-up-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 50px !important; } }

@media (min-width: 1200px) {
  .xl-up-my-lg {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .xl-up-my-lg-child > * {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .xl-up-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important;
    margin-bottom: 50px !important; } }

@media (min-width: 1200px) {
  .xl-up-ml-lg {
    margin-left: 50px !important; }
  .xl-up-ml-lg-child > * {
    margin-left: 50px !important; }
  .xl-up-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important; } }

@media (min-width: 1200px) {
  .xl-up-mr-lg {
    margin-right: 50px !important; }
  .xl-up-mr-lg-child > * {
    margin-right: 50px !important; }
  .xl-up-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 50px !important; } }

@media (min-width: 1200px) {
  .xl-up-mx-lg {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .xl-up-mx-lg-child > * {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .xl-up-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important;
    margin-right: 50px !important; } }

@media (min-width: 1200px) {
  .xl-up-p-lg {
    padding: 50px !important; }
  .xl-up-p-lg-child > * {
    padding: 50px !important; }
  .xl-up-p-lg-child-not-last > *:not(:last-child) {
    padding: 50px !important; } }

@media (min-width: 1200px) {
  .xl-up-pt-lg {
    padding-top: 50px !important; }
  .xl-up-pt-lg-child > * {
    padding-top: 50px !important; }
  .xl-up-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important; } }

@media (min-width: 1200px) {
  .xl-up-pb-lg {
    padding-bottom: 50px !important; }
  .xl-up-pb-lg-child > * {
    padding-bottom: 50px !important; }
  .xl-up-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 50px !important; } }

@media (min-width: 1200px) {
  .xl-up-py-lg {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .xl-up-py-lg-child > * {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .xl-up-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important;
    padding-bottom: 50px !important; } }

@media (min-width: 1200px) {
  .xl-up-pl-lg {
    padding-left: 50px !important; }
  .xl-up-pl-lg-child > * {
    padding-left: 50px !important; }
  .xl-up-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important; } }

@media (min-width: 1200px) {
  .xl-up-pr-lg {
    padding-right: 50px !important; }
  .xl-up-pr-lg-child > * {
    padding-right: 50px !important; }
  .xl-up-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 50px !important; } }

@media (min-width: 1200px) {
  .xl-up-px-lg {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .xl-up-px-lg-child > * {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .xl-up-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media (min-width: 1200px) {
  .xl-up-m-xl {
    margin: 70px !important; }
  .xl-up-m-xl-child > * {
    margin: 70px !important; }
  .xl-up-m-xl-child-not-last > *:not(:last-child) {
    margin: 70px !important; } }

@media (min-width: 1200px) {
  .xl-up-mt-xl {
    margin-top: 70px !important; }
  .xl-up-mt-xl-child > * {
    margin-top: 70px !important; }
  .xl-up-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important; } }

@media (min-width: 1200px) {
  .xl-up-mb-xl {
    margin-bottom: 70px !important; }
  .xl-up-mb-xl-child > * {
    margin-bottom: 70px !important; }
  .xl-up-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 70px !important; } }

@media (min-width: 1200px) {
  .xl-up-my-xl {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .xl-up-my-xl-child > * {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .xl-up-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important;
    margin-bottom: 70px !important; } }

@media (min-width: 1200px) {
  .xl-up-ml-xl {
    margin-left: 70px !important; }
  .xl-up-ml-xl-child > * {
    margin-left: 70px !important; }
  .xl-up-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important; } }

@media (min-width: 1200px) {
  .xl-up-mr-xl {
    margin-right: 70px !important; }
  .xl-up-mr-xl-child > * {
    margin-right: 70px !important; }
  .xl-up-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 70px !important; } }

@media (min-width: 1200px) {
  .xl-up-mx-xl {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .xl-up-mx-xl-child > * {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .xl-up-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important;
    margin-right: 70px !important; } }

@media (min-width: 1200px) {
  .xl-up-p-xl {
    padding: 70px !important; }
  .xl-up-p-xl-child > * {
    padding: 70px !important; }
  .xl-up-p-xl-child-not-last > *:not(:last-child) {
    padding: 70px !important; } }

@media (min-width: 1200px) {
  .xl-up-pt-xl {
    padding-top: 70px !important; }
  .xl-up-pt-xl-child > * {
    padding-top: 70px !important; }
  .xl-up-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important; } }

@media (min-width: 1200px) {
  .xl-up-pb-xl {
    padding-bottom: 70px !important; }
  .xl-up-pb-xl-child > * {
    padding-bottom: 70px !important; }
  .xl-up-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 70px !important; } }

@media (min-width: 1200px) {
  .xl-up-py-xl {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .xl-up-py-xl-child > * {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .xl-up-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important;
    padding-bottom: 70px !important; } }

@media (min-width: 1200px) {
  .xl-up-pl-xl {
    padding-left: 70px !important; }
  .xl-up-pl-xl-child > * {
    padding-left: 70px !important; }
  .xl-up-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important; } }

@media (min-width: 1200px) {
  .xl-up-pr-xl {
    padding-right: 70px !important; }
  .xl-up-pr-xl-child > * {
    padding-right: 70px !important; }
  .xl-up-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 70px !important; } }

@media (min-width: 1200px) {
  .xl-up-px-xl {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .xl-up-px-xl-child > * {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .xl-up-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important;
    padding-right: 70px !important; } }

@media (min-width: 1200px) {
  .xl-up-m-xxl {
    margin: 140px !important; }
  .xl-up-m-xxl-child > * {
    margin: 140px !important; }
  .xl-up-m-xxl-child-not-last > *:not(:last-child) {
    margin: 140px !important; } }

@media (min-width: 1200px) {
  .xl-up-mt-xxl {
    margin-top: 140px !important; }
  .xl-up-mt-xxl-child > * {
    margin-top: 140px !important; }
  .xl-up-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important; } }

@media (min-width: 1200px) {
  .xl-up-mb-xxl {
    margin-bottom: 140px !important; }
  .xl-up-mb-xxl-child > * {
    margin-bottom: 140px !important; }
  .xl-up-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 140px !important; } }

@media (min-width: 1200px) {
  .xl-up-my-xxl {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .xl-up-my-xxl-child > * {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .xl-up-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important;
    margin-bottom: 140px !important; } }

@media (min-width: 1200px) {
  .xl-up-ml-xxl {
    margin-left: 140px !important; }
  .xl-up-ml-xxl-child > * {
    margin-left: 140px !important; }
  .xl-up-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important; } }

@media (min-width: 1200px) {
  .xl-up-mr-xxl {
    margin-right: 140px !important; }
  .xl-up-mr-xxl-child > * {
    margin-right: 140px !important; }
  .xl-up-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 140px !important; } }

@media (min-width: 1200px) {
  .xl-up-mx-xxl {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .xl-up-mx-xxl-child > * {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .xl-up-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important;
    margin-right: 140px !important; } }

@media (min-width: 1200px) {
  .xl-up-p-xxl {
    padding: 140px !important; }
  .xl-up-p-xxl-child > * {
    padding: 140px !important; }
  .xl-up-p-xxl-child-not-last > *:not(:last-child) {
    padding: 140px !important; } }

@media (min-width: 1200px) {
  .xl-up-pt-xxl {
    padding-top: 140px !important; }
  .xl-up-pt-xxl-child > * {
    padding-top: 140px !important; }
  .xl-up-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important; } }

@media (min-width: 1200px) {
  .xl-up-pb-xxl {
    padding-bottom: 140px !important; }
  .xl-up-pb-xxl-child > * {
    padding-bottom: 140px !important; }
  .xl-up-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 140px !important; } }

@media (min-width: 1200px) {
  .xl-up-py-xxl {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .xl-up-py-xxl-child > * {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .xl-up-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important;
    padding-bottom: 140px !important; } }

@media (min-width: 1200px) {
  .xl-up-pl-xxl {
    padding-left: 140px !important; }
  .xl-up-pl-xxl-child > * {
    padding-left: 140px !important; }
  .xl-up-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important; } }

@media (min-width: 1200px) {
  .xl-up-pr-xxl {
    padding-right: 140px !important; }
  .xl-up-pr-xxl-child > * {
    padding-right: 140px !important; }
  .xl-up-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 140px !important; } }

@media (min-width: 1200px) {
  .xl-up-px-xxl {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .xl-up-px-xxl-child > * {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .xl-up-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important;
    padding-right: 140px !important; } }

/*
|--------------------
|    FONTS IMPORTS
|--------------------
|
| 100 - Extra Light or Ultra Light
| 200 - Light or Thin
| 300 - Book or Demi
| 400 - Normal or Regular
| 500 - Medium
| 600 - Semibold, Demibold
| 700 - Bold
| 800 - Black, Extra Bold or Heavy
| 900 - Extra Black, Fat, Poster or Ultra Black
|
*/
@font-face {
  font-family: "graphik";
  src: url("../fonts/graphik/Graphik-Light.eot");
  src: url("../fonts/graphik/Graphik-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/graphik/Graphik-Light.woff2") format("woff2"), url("../fonts/graphik/Graphik-Light.woff") format("woff"), url("../fonts/graphik/Graphik-Light.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
  font-stretch: normal; }

@font-face {
  font-family: "graphik";
  src: url("../fonts/graphik/Graphik-Regular.eot");
  src: url("../fonts/graphik/Graphik-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/graphik/Graphik-Regular.woff2") format("woff2"), url("../fonts/graphik/Graphik-Regular.woff") format("woff"), url("../fonts/graphik/Graphik-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal; }

@font-face {
  font-family: "graphik";
  src: url("../fonts/graphik/Graphik-Medium.eot");
  src: url("../fonts/graphik/Graphik-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/graphik/Graphik-Medium.woff2") format("woff2"), url("../fonts/graphik/Graphik-Medium.woff") format("woff"), url("../fonts/graphik/Graphik-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal; }

@font-face {
  font-family: "graphik";
  src: url("../fonts/graphik/Graphik-Bold.eot");
  src: url("../fonts/graphik/Graphik-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/graphik/Graphik-Bold.woff2") format("woff2"), url("../fonts/graphik/Graphik-Bold.woff") format("woff"), url("../fonts/graphik/Graphik-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal; }

@font-face {
  font-family: "canela";
  src: url("../fonts/canela/Canela-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/canela/Canela-Regular.otf") format("opentype"), url("../fonts/canela/Canela-Regular.woff") format("woff"), url("../fonts/canela/Canela-Regular.ttf") format("truetype"), url("../fonts/canela/Canela-Regular.svg#Canela-Regular") format("svg");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "canela";
  src: url("../fonts/canela/Canela-Thin.eot?#iefix") format("embedded-opentype"), url("../fonts/canela/Canela-Thin.otf") format("opentype"), url("../fonts/canela/Canela-Thin.woff") format("woff"), url("../fonts/canela/Canela-Thin.ttf") format("truetype"), url("../fonts/canela/Canela-Thin.svg#Canela-Thin") format("svg");
  font-weight: 200;
  font-style: normal; }

@font-face {
  font-family: "canela";
  src: url("../fonts/canela/Canela-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/canela/Canela-Light.otf") format("opentype"), url("../fonts/canela/Canela-Light.woff") format("woff"), url("../fonts/canela/Canela-Light.ttf") format("truetype"), url("../fonts/canela/Canela-Light.svg#Canela-Light") format("svg");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: "Px Grotesk";
  src: url("../fonts/pxgrotesk/PxGroteskRegular.eot");
  src: url("../fonts/pxgrotesk/PxGroteskRegular.eot?#iefix") format("embedded-opentype"), url("../fonts/pxgrotesk/PxGroteskRegular.woff2") format("woff2"), url("../fonts/pxgrotesk/PxGroteskRegular.woff") format("woff"), url("../fonts/pxgrotesk/PxGroteskRegular.ttf") format("truetype"), url("../fonts/pxgrotesk/PxGroteskRegular.svg#PxGroteskRegular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Px Grotesk";
  src: url("../fonts/pxgrotesk/PxGrotesk-Screen.eot");
  src: url("../fonts/pxgrotesk/PxGrotesk-Screen.eot?#iefix") format("embedded-opentype"), url("../fonts/pxgrotesk/PxGrotesk-Screen.woff2") format("woff2"), url("../fonts/pxgrotesk/PxGrotesk-Screen.woff") format("woff"), url("../fonts/pxgrotesk/PxGrotesk-Screen.ttf") format("truetype"), url("../fonts/pxgrotesk/PxGrotesk-Screen.svg#PxGrotesk-Screen") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Px Grotesk";
  src: url("../fonts/pxgrotesk/PxGrotesk-Bold.eot");
  src: url("../fonts/pxgrotesk/PxGrotesk-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/pxgrotesk/PxGrotesk-Bold.woff2") format("woff2"), url("../fonts/pxgrotesk/PxGrotesk-Bold.woff") format("woff"), url("../fonts/pxgrotesk/PxGrotesk-Bold.ttf") format("truetype"), url("../fonts/pxgrotesk/PxGrotesk-Bold.svg#PxGrotesk-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Px Grotesk";
  src: url("../fonts/pxgrotesk/PxGrotesk-RegularItalic.eot");
  src: url("../fonts/pxgrotesk/PxGrotesk-RegularItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/pxgrotesk/PxGrotesk-RegularItalic.woff2") format("woff2"), url("../fonts/pxgrotesk/PxGrotesk-RegularItalic.woff") format("woff"), url("../fonts/pxgrotesk/PxGrotesk-RegularItalic.ttf") format("truetype"), url("../fonts/pxgrotesk/PxGrotesk-RegularItalic.svg#PxGrotesk-RegularItalic") format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: "Px Grotesk";
  src: url("../fonts/pxgrotesk/PxGrotesk-Light.eot");
  src: url("../fonts/pxgrotesk/PxGrotesk-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/pxgrotesk/PxGrotesk-Light.woff2") format("woff2"), url("../fonts/pxgrotesk/PxGrotesk-Light.woff") format("woff"), url("../fonts/pxgrotesk/PxGrotesk-Light.ttf") format("truetype"), url("../fonts/pxgrotesk/PxGrotesk-Light.svg#PxGrotesk-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Px Grotesk";
  src: url("../fonts/pxgrotesk/PxGrotesk-LightItalic.eot");
  src: url("../fonts/pxgrotesk/PxGrotesk-LightItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/pxgrotesk/PxGrotesk-LightItalic.woff2") format("woff2"), url("../fonts/pxgrotesk/PxGrotesk-LightItalic.woff") format("woff"), url("../fonts/pxgrotesk/PxGrotesk-LightItalic.ttf") format("truetype"), url("../fonts/pxgrotesk/PxGrotesk-LightItalic.svg#PxGrotesk-LightItalic") format("svg");
  font-weight: 300;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: "Px Grotesk";
  src: url("../fonts/pxgrotesk/PxGrotesk-BoldItalic.eot");
  src: url("../fonts/pxgrotesk/PxGrotesk-BoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/pxgrotesk/PxGrotesk-BoldItalic.woff2") format("woff2"), url("../fonts/pxgrotesk/PxGrotesk-BoldItalic.woff") format("woff"), url("../fonts/pxgrotesk/PxGrotesk-BoldItalic.ttf") format("truetype"), url("../fonts/pxgrotesk/PxGrotesk-BoldItalic.svg#PxGrotesk-BoldItalic") format("svg");
  font-weight: bold;
  font-style: italic;
  font-display: swap; }

/*
|--------------------------
|      DEFAULT & RESET
|--------------------------
*/
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

* {
  margin: 0;
  padding: 0; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  font-family: "Px Grotesk";
  color: #383f51;
  font-size: 16px;
  line-height: 1.8;
  font-weight: 200;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow-x: hidden;
  background-color: #f1e9db; }
  body.--loading {
    opacity: 0;
    overflow: hidden;
    transition: opacity 0.3s ease; }

h1, h2, h3, h4, h5, h6 {
  color: #383f51;
  font-family: "Px Grotesk"; }

h1 {
  font-family: "Px Grotesk";
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  line-height: 71px;
  text-align: center; }
  @media (max-width: 1199.98px) {
    h1 {
      font-size: 56px;
      line-height: 60px; } }
  @media (max-width: 991.98px) {
    h1 {
      font-size: 48px;
      line-height: 60px; } }
  @media (max-width: 767.98px) {
    h1 {
      font-size: 30px;
      line-height: 36px; } }

h2 {
  font-family: "Px Grotesk";
  font-style: normal;
  font-weight: 400;
  font-size: 44px;
  line-height: 46px; }
  @media (max-width: 1199.98px) {
    h2 {
      font-size: 44px;
      line-height: 46px; } }
  @media (max-width: 991.98px) {
    h2 {
      font-size: 36px;
      line-height: 40px; } }
  @media (max-width: 767.98px) {
    h2 {
      font-size: 28px;
      line-height: 32px; } }

.sup {
  color: #ff563f;
  font-family: "Px Grotesk";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px; }

button:focus,
input:focus,
textarea:focus {
  outline: none !important;
  box-shadow: none !important; }

button {
  -webkit-appearance: none;
  padding: 0;
  background-color: transparent;
  border: none; }

img {
  max-width: 100%; }

p {
  font-family: "Px Grotesk"; }

a,
img,
button {
  display: inline-block; }

a {
  font-family: "Px Grotesk";
  color: #000000;
  text-decoration: none; }
  a:hover {
    color: #000000; }

ul {
  margin: 0;
  padding: 0; }
  ul li {
    list-style-type: none; }

.clearfix {
  clear: both;
  width: 100%;
  display: block; }

.rel {
  position: relative; }

.m-a {
  margin: auto; }

.d-t {
  display: table !important; }

.d-b {
  display: block !important; }

.txt-c {
  text-align: center !important; }

.is-hidden {
  display: none;
  transition: all ease 0.3s; }

.mbm {
  mix-blend-mode: multiply; }

.no-mbm,
.no-mbm img {
  mix-blend-mode: initial; }

.sup {
  color: #ff563f;
  font-family: "Px Grotesk";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px; }

.logo-c {
  display: none; }

.disp-desk {
  display: block !important; }
  @media (max-width: 991.98px) {
    .disp-desk {
      display: none !important; } }

.disp-desk-lg {
  display: block !important; }
  @media (max-width: 1199.98px) {
    .disp-desk-lg {
      display: none !important; } }

.disp-mob {
  display: none !important; }
  @media (max-width: 991.98px) {
    .disp-mob {
      display: block !important; } }

.disp-desk-flex {
  display: flex !important; }
  @media (max-width: 991.98px) {
    .disp-desk-flex {
      display: none !important; } }

.disp-mob-flex {
  display: none !important; }
  @media (max-width: 991.98px) {
    .disp-mob-flex {
      display: flex !important; } }

.dflex {
  display: flex;
  vertical-align: middle;
  align-items: center; }

/*
|--------------------
|      HEADER
|--------------------
*/
body {
  font-family: "Px Grotesk";
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  background-color: #f1e9db;
  background-image: url("../img/global/bg-site.png");
  width: 100%;
  height: 1427px; }
  body.menu-active {
    overflow: hidden; }

/*
|
| Header
|---------
*/
.link-menu, #header .lang-container {
  color: #141414;
  font-size: 14px;
  font-weight: 400; }

#header {
  width: 100%;
  position: fixed;
  z-index: 10;
  background: transparent;
  transition: all ease 0.3s; }
  #header .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 15px;
    transition: all ease 0.3s;
    position: relative; }
    #header .header-container .item-logo .logo-w {
      height: 45px;
      transition: all ease 0.3s; }
    #header .header-container .item-nav {
      margin: auto;
      display: none; }
      #header .header-container .item-nav .item-menu {
        display: flex; }
        #header .header-container .item-nav .item-menu li {
          margin: 0 30px;
          position: relative; }
          #header .header-container .item-nav .item-menu li::before {
            content: "";
            position: absolute;
            background-color: #ff563f;
            height: 2px;
            width: 0%;
            bottom: 0;
            border-radius: 10px;
            transition: all ease 0.3s; }
          #header .header-container .item-nav .item-menu li:hover::before {
            content: "";
            width: 100%;
            transition: all ease 0.3s; }
          #header .header-container .item-nav .item-menu li.current-menu-item::before {
            content: "";
            width: 100%;
            transition: all ease 0.3s; }
          #header .header-container .item-nav .item-menu li .item-link {
            font-size: 18px;
            color: #484848;
            letter-spacing: 0;
            font-family: "Px Grotesk";
            font-style: normal;
            font-weight: 400;
            padding-top: 2px; }
      @media (min-width: 1200px) {
        #header .header-container .item-nav {
          display: flex;
          align-items: center; }
          #header .header-container .item-nav .item-menu {
            margin-right: 25px; } }
    #header .header-container .btn-cta {
      float: left;
      position: relative;
      transition: all ease 0.3s; }
      #header .header-container .btn-cta a {
        font-family: "Px Grotesk";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;
        background-color: white;
        padding: 14px 22px 12px 44px;
        border-radius: 55px;
        transition: all ease 0.3s; }
        #header .header-container .btn-cta a:before {
          content: "";
          height: 10px;
          width: 10px;
          border-radius: 50%;
          background-color: #ff563f;
          display: inline-block;
          position: absolute;
          left: 21px;
          top: 18px;
          transition: all ease 0.3s; }
        #header .header-container .btn-cta a:hover {
          background-color: #ff563f;
          transition: all ease 0.3s;
          color: white; }
          #header .header-container .btn-cta a:hover:before {
            content: "";
            background-color: white;
            transition: all ease 0.3s; }
  #header .lang-container {
    position: relative;
    display: inline-flex;
    top: -1px;
    cursor: pointer; }
    #header .lang-container ul {
      float: left;
      margin-top: 13px;
      margin-left: 25px; }
      #header .lang-container ul li {
        font-family: "Px Grotesk";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;
        color: #383f51;
        display: inline-block;
        text-transform: uppercase; }
        #header .lang-container ul li a {
          opacity: 0.5; }
        #header .lang-container ul li:first-child::after {
          content: "";
          height: 18px;
          width: 2px;
          display: block;
          float: right;
          margin: 0 5px 0 9px;
          background-color: #383f51; }
  #header .btn-menu {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-top: 0px;
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer; }
    #header .btn-menu > .item-burger {
      display: block;
      width: 25px; }
      #header .btn-menu > .item-burger > span {
        display: block;
        width: 100%;
        height: 2px;
        background-color: #141414;
        border-radius: 2px; }
        #header .btn-menu > .item-burger > span:nth-child(2) {
          margin: 6px 0; }
    @media (min-width: 1200px) {
      #header .btn-menu {
        display: none; } }

.site-container.menufixed #header {
  background-color: white;
  transition: all ease 0.3s; }
  .site-container.menufixed #header .header-container {
    padding: 7px 15px;
    transition: all ease 0.3s; }
    .site-container.menufixed #header .header-container .logo-w {
      height: 36px;
      transition: all ease 0.3s; }
    .site-container.menufixed #header .header-container .item-menu {
      display: flex; }
    .site-container.menufixed #header .header-container .lang-container {
      top: 0; }
      .site-container.menufixed #header .header-container .lang-container .btn-cta {
        transition: all ease 0.3s; }
        .site-container.menufixed #header .header-container .lang-container .btn-cta a {
          padding: 11px 22px 9px 44px;
          transition: all ease 0.3s;
          border: 1px solid #ff563f; }
          .site-container.menufixed #header .header-container .lang-container .btn-cta a:before {
            top: 16px;
            transition: all ease 0.3s; }

/*
|
| Menu
|-------
*/
#mobile-menu {
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
  overflow: auto;
  opacity: 0;
  visibility: hidden; }
  #mobile-menu .item-container ul > li:not(:last-child) {
    margin-bottom: 20px; }
  #mobile-menu .item-container ul > li a {
    text-transform: uppercase;
    color: #141414;
    font-size: 30px;
    font-weight: 500;
    -webkit-font-smoothing: antialiased; }
    @media (max-width: 575.98px) {
      #mobile-menu .item-container ul > li a {
        font-size: 26px; } }
  #mobile-menu .item-socials-container {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%; }
    #mobile-menu .item-socials-container .item-socials {
      display: flex;
      align-items: center;
      margin-bottom: 25px; }
      #mobile-menu .item-socials-container .item-socials a {
        font-size: 25px; }
        #mobile-menu .item-socials-container .item-socials a:not(:last-child) {
          margin-right: 25px; }
      @media (max-width: 575.98px) {
        #mobile-menu .item-socials-container .item-socials {
          margin-bottom: 15px; }
          #mobile-menu .item-socials-container .item-socials a {
            font-size: 20px; } }
  #mobile-menu .lang-container ul li {
    display: contents;
    text-transform: uppercase;
    color: #141414;
    font-size: 30px;
    font-weight: 500;
    -webkit-font-smoothing: antialiased; }

/*
|--------------------
|      FOOTER
|--------------------
*/
#footer {
  font-size: 16px;
  line-height: 20px;
  color: white;
  background-color: #383f51; }
  #footer .menu-secondary ul li {
    display: inline-block; }
    #footer .menu-secondary ul li .nav-drop li {
      display: block; }
    #footer .menu-secondary ul li .item-link {
      color: #ff563f; }
  #footer .elem-link {
    opacity: 1;
    transition: all ease 0.3s; }
    #footer .elem-link:hover {
      opacity: 0.9;
      transition: all ease 0.3s; }
    #footer .elem-link .bg {
      background-position: center;
      background-size: cover;
      height: 360px;
      position: relative;
      padding: 30px;
      display: block; }
      @media (max-width: 991.98px) {
        #footer .elem-link .bg {
          height: 200px; } }
      #footer .elem-link .bg:before {
        content: "";
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0.4;
        position: absolute;
        background-color: black; }
    #footer .elem-link .text {
      font-size: 16px;
      color: white;
      z-index: 1;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 30px; }
      @media (max-width: 991.98px) {
        #footer .elem-link .text {
          height: 200px;
          display: flex;
          vertical-align: middle;
          align-items: center; }
          #footer .elem-link .text div {
            width: 100%; } }
      #footer .elem-link .text span {
        text-transform: uppercase; }
  #footer .elem-values {
    text-align: center; }
    #footer .elem-values img {
      height: 100px; }
      @media (max-width: 767.98px) {
        #footer .elem-values img {
          height: 60px; } }
    #footer .elem-values .text {
      font-family: Helvetica;
      font-size: 22px;
      line-height: 28px;
      color: white; }
      @media (max-width: 767.98px) {
        #footer .elem-values .text {
          font-size: 16px;
          line-height: 20px; } }
  #footer .ft-links {
    padding-top: 40px; }
    @media (max-width: 767.98px) {
      #footer .ft-links {
        display: none; } }
    #footer .ft-links a {
      /* border-bottom: 1px solid $dark-grey; */
      transition: all ease 0.3s; }
      #footer .ft-links a:hover {
        color: #ff563f;
        /* border-bottom: 1px solid $orange; */
        transition: all ease 0.3s; }
  #footer h2 {
    font-size: 24px;
    line-height: 32px; }
    @media (max-width: 1199.98px) {
      #footer h2 {
        font-size: 19px;
        line-height: 19px; } }
  #footer p,
  #footer a,
  #footer li {
    font-size: 16px;
    line-height: 20px;
    color: white;
    letter-spacing: 0;
    font-weight: normal; }
    @media (max-width: 991.98px) {
      #footer p,
      #footer a,
      #footer li {
        font-size: 15px; } }
  #footer .item-logo img {
    width: 149px; }
  #footer .block-m-legal {
    background: black; }
    #footer .block-m-legal .m-legal {
      padding: 10px 0; }
      #footer .block-m-legal .m-legal ul {
        display: block;
        text-align: left;
        line-height: 0;
        padding: 0; }
        @media (max-width: 991.98px) {
          #footer .block-m-legal .m-legal ul {
            text-align: center; } }
        #footer .block-m-legal .m-legal ul:after {
          display: inline-block;
          width: 95%;
          content: "";
          vertical-align: top; }
        #footer .block-m-legal .m-legal ul li {
          font-family: "Px Grotesk";
          font-weight: 300;
          font-size: 16px;
          color: #a9a9a9;
          letter-spacing: 0;
          line-height: 24px;
          display: inline-block;
          vertical-align: middle; }
          @media (max-width: 991.98px) {
            #footer .block-m-legal .m-legal ul li {
              padding: 0 7px; } }
          #footer .block-m-legal .m-legal ul li a {
            font-family: "Px Grotesk";
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 17px; }
            #footer .block-m-legal .m-legal ul li a:hover {
              color: #ff563f; }
          #footer .block-m-legal .m-legal ul li:not(:last-child) {
            padding: 0 3%; }
            #footer .block-m-legal .m-legal ul li:not(:last-child) a {
              color: #a9a9a9; }
          #footer .block-m-legal .m-legal ul li:last-child {
            position: absolute;
            right: 0;
            color: #a9a9a9; }
            @media (max-width: 991.98px) {
              #footer .block-m-legal .m-legal ul li:last-child {
                position: relative; } }
            #footer .block-m-legal .m-legal ul li:last-child a {
              color: white; }

/*
|--------------------
|      CONTENT
|--------------------
*/
.pagination-container li {
  display: inline-block; }
  .pagination-container li a {
    transition: all 0.3s ease-out;
    padding: 0 8px; }
    .pagination-container li a:hover {
      color: #f6f5f5; }
  .pagination-container li .current {
    padding: 0 8px;
    font-weight: 800;
    color: #f6f5f5; }

.cms span {
  display: inline; }

.cms h1,
.cms h2,
.cms h3,
.cms h4,
.cms h5,
.cms h6 {
  margin-bottom: 30px; }

.cms h2 {
  color: inherit;
  font-family: "graphik", sans-serif;
  font-weight: 400;
  line-height: 1.2;
  font-size: 40px; }

.cms img {
  display: block;
  width: 100%;
  height: auto; }

.cms strong {
  font-weight: bold; }

.cms p {
  margin-bottom: 20px; }

.cms ul {
  margin-bottom: 20px;
  padding-left: 20px; }
  .cms ul li {
    list-style: disc; }
    .cms ul li:not(:last-child) {
      margin-bottom: 5px; }
    .cms ul li ul li {
      list-style: circle; }

.cms ol {
  margin-bottom: 20px;
  padding-left: 20px; }

.cms.cms-no-break p {
  margin-bottom: 0; }

.cms blockquote {
  position: relative;
  width: 80%;
  margin-right: 0;
  margin-left: auto;
  padding: 130px 0;
  font-size: 30px;
  line-height: 1.5em;
  font-weight: 300; }
  @media (max-width: 991.98px) {
    .cms blockquote {
      margin: auto; } }
  .cms blockquote:before {
    content: "";
    position: absolute;
    background-image: url("../img/icon/quote-left.svg");
    width: 50px;
    height: 50px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    transform: translateX(-60px) translateY(-30px); }
  .cms blockquote:after {
    content: "";
    position: absolute;
    right: 0;
    background-image: url("../img/icon/quote-right.svg");
    width: 50px;
    height: 50px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    transform: translateX(0) translateY(-50px); }
    @media (max-width: 991.98px) {
      .cms blockquote:after {
        transform: translateX(60px) translateY(-50px); } }

.custom-loader {
  width: 100px;
  display: inline-block; }
  .custom-loader.cl-center {
    margin-left: auto;
    margin-right: auto; }
  .custom-loader svg {
    display: inline-block;
    width: 100%;
    height: auto;
    fill: #000000; }
    .custom-loader svg circle {
      stroke: #000000; }
  .custom-loader.ajax-loader {
    display: none; }

.btn, .btn-black, .btn-white, .btn-xs, .btn-sm, .btn-md, .btn-lg {
  font-family: "Px Grotesk";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  background-color: white;
  padding: 14px 22px 12px 44px;
  border-radius: 55px;
  transition: all ease 0.3s;
  position: relative; }
  .btn:before, .btn-black:before, .btn-white:before, .btn-xs:before, .btn-sm:before, .btn-md:before, .btn-lg:before {
    content: "";
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #ff563f;
    display: inline-block;
    position: absolute;
    left: 21px;
    top: 18px;
    transition: all ease 0.3s; }
  .btn:hover, .btn-black:hover, .btn-white:hover, .btn-xs:hover, .btn-sm:hover, .btn-md:hover, .btn-lg:hover {
    background-color: #ff563f;
    transition: all ease 0.3s;
    color: white; }
    .btn:hover:before, .btn-black:hover:before, .btn-white:hover:before, .btn-xs:hover:before, .btn-sm:hover:before, .btn-md:hover:before, .btn-lg:hover:before {
      content: "";
      background-color: white;
      transition: all ease 0.3s; }
  .btn.btn-alt, .btn-alt.btn-black, .btn-alt.btn-white, .btn-alt.btn-xs, .btn-alt.btn-sm, .btn-alt.btn-md, .btn-alt.btn-lg {
    color: #ffffff;
    background-color: #ff563f; }
    .btn.btn-alt:before, .btn-alt.btn-black:before, .btn-alt.btn-white:before, .btn-alt.btn-xs:before, .btn-alt.btn-sm:before, .btn-alt.btn-md:before, .btn-alt.btn-lg:before {
      content: "";
      background-color: #ffffff; }
    .btn.btn-alt:hover, .btn-alt.btn-black:hover, .btn-alt.btn-white:hover, .btn-alt.btn-xs:hover, .btn-alt.btn-sm:hover, .btn-alt.btn-md:hover, .btn-alt.btn-lg:hover {
      background-color: #ffffff;
      color: #141414; }
      .btn.btn-alt:hover:before, .btn-alt.btn-black:hover:before, .btn-alt.btn-white:hover:before, .btn-alt.btn-xs:hover:before, .btn-alt.btn-sm:hover:before, .btn-alt.btn-md:hover:before, .btn-alt.btn-lg:hover:before {
        content: "";
        background-color: #ff563f; }

.page-offset {
  padding-top: 95px; }

.banner {
  height: 70vh;
  background-size: cover;
  background-position: center;
  position: relative;
  text-align: center; }
  @media (max-width: 767.98px) {
    .banner {
      height: initial; } }
  .banner .item-content {
    height: 70vh;
    color: black; }
    @media (max-width: 767.98px) {
      .banner .item-content {
        height: initial;
        min-height: 250px; } }
    .banner .item-content .item-title {
      font-family: "Px Grotesk";
      font-style: normal;
      font-weight: 400;
      font-size: 64px;
      line-height: 71px;
      text-align: center; }
      @media (max-width: 991.98px) {
        .banner .item-content .item-title {
          font-size: 40px;
          line-height: 44px; } }
      @media (max-width: 575.98px) {
        .banner .item-content .item-title {
          font-size: 30px;
          line-height: 36px; } }
    .banner .item-content .item-text {
      font-family: "Px Grotesk";
      font-style: normal;
      font-size: 16px;
      line-height: 19px;
      text-align: center; }
      .banner .item-content .item-text p {
        margin-bottom: 15px; }

.svg-big-virgule {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  height: 460px;
  width: 519px;
  z-index: -1;
  background-size: cover;
  background-image: url("../img/icon/big-virgule.svg"); }
  @media (max-width: 1199.98px) {
    .svg-big-virgule {
      height: 430px;
      width: 485px; } }
  @media (max-width: 991.98px) {
    .svg-big-virgule {
      height: 357px;
      width: 403px; } }
  @media (max-width: 767.98px) {
    .svg-big-virgule {
      height: 297px;
      width: 335px; } }
  @media (max-width: 575.98px) {
    .svg-big-virgule {
      height: 227px;
      width: 256px; } }

.svg-virgule {
  position: absolute;
  width: auto;
  height: 1120px;
  display: block;
  top: 210px;
  left: 150px;
  right: 0px;
  margin: auto;
  z-index: -1;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../img/icon/big-virgule-4.svg"); }
  @media (max-width: 1399.98px) {
    .svg-virgule {
      width: 1100px; } }
  @media (max-width: 1199.98px) {
    .svg-virgule {
      top: 150px;
      width: 933px; } }
  @media (max-width: 991.98px) {
    .svg-virgule {
      left: 70px;
      width: 920px; } }
  @media (max-width: 767.98px) {
    .svg-virgule {
      top: -110px;
      width: 100%; } }
  @media (max-width: 575.98px) {
    .svg-virgule {
      opacity: 0.4; } }
  .svg-virgule-home {
    left: 0;
    top: initial;
    bottom: -490px;
    opacity: 0.4;
    height: 710px;
    background-size: contain; }
    @media (max-width: 1399.98px) {
      .svg-virgule-home {
        bottom: -430px;
        height: 700px;
        width: 1000px; } }
    @media (max-width: 991.98px) {
      .svg-virgule-home {
        background-size: contain;
        width: auto;
        height: 543px; } }
    @media (max-width: 575.98px) {
      .svg-virgule-home {
        display: none; } }
  .svg-virgule-actu {
    max-width: 1830px;
    height: 1015px;
    top: 110px;
    background-size: cover;
    width: auto;
    left: 0;
    right: 0px; }
    @media (max-width: 1399.98px) {
      .svg-virgule-actu {
        width: inherit;
        left: 0; } }
    @media (max-width: 1199.98px) {
      .svg-virgule-actu {
        width: inherit; } }
    @media (max-width: 991.98px) {
      .svg-virgule-actu {
        top: 110px;
        width: inherit; } }
    @media (max-width: 767.98px) {
      .svg-virgule-actu {
        width: inherit; } }
    @media (max-width: 575.98px) {
      .svg-virgule-actu {
        opacity: 1; } }
  .svg-virgule-single {
    width: 1830px;
    height: 1015px;
    left: 0;
    top: 520px;
    background-size: cover; }
    @media (max-width: 1399.98px) {
      .svg-virgule-single {
        width: inherit;
        left: 0;
        top: 430px;
        background-size: contain; } }
    @media (max-width: 1199.98px) {
      .svg-virgule-single {
        top: 350px;
        width: inherit; } }
    @media (max-width: 991.98px) {
      .svg-virgule-single {
        top: 350px;
        width: inherit; } }
    @media (max-width: 767.98px) {
      .svg-virgule-single {
        top: 110px;
        width: inherit; } }
    @media (max-width: 575.98px) {
      .svg-virgule-single {
        top: 110px;
        opacity: 1; } }
  .svg-virgule-contact {
    top: 530px;
    height: 772px;
    width: 1290px;
    opacity: 0.2;
    left: 0;
    background-size: contain; }
    @media (max-width: 1399.98px) {
      .svg-virgule-contact {
        top: 480px;
        height: 700px;
        width: 1000px; } }
    @media (max-width: 991.98px) {
      .svg-virgule-contact {
        background-size: cover;
        width: auto;
        top: 290px; } }
  .svg-virgule-profil {
    top: 530px;
    height: 772px;
    width: 1290px;
    left: 0;
    background-size: contain;
    background-image: url("../img/icon/svg-orange.svg"); }
    @media (max-width: 1399.98px) {
      .svg-virgule-profil {
        top: 480px;
        height: 700px;
        width: 1000px; } }
    @media (max-width: 991.98px) {
      .svg-virgule-profil {
        background-size: cover;
        width: auto;
        top: 460px; } }
  .svg-virgule-a-propos {
    top: 170px;
    left: 0px;
    right: 0;
    bottom: inherit;
    opacity: 1;
    background-image: url("../img/icon/big-virgule-3.svg");
    background-size: cover;
    transform: rotate(0);
    height: 1620px; }
    @media (max-width: 1399.98px) {
      .svg-virgule-a-propos {
        height: 1820px;
        width: 100%; } }
  .svg-virgule-modulaire {
    top: 40px;
    left: 140px;
    right: 0;
    margin: auto;
    bottom: inherit;
    opacity: 1;
    background-image: url("../img/icon/svg-modulaire.svg");
    background-size: initial;
    transform: rotate(-13deg);
    height: 2000px;
    width: 100%; }
    @media (max-width: 1399.98px) {
      .svg-virgule-modulaire {
        height: 1190px;
        width: 850px;
        left: 0;
        top: 180px;
        background-position: center;
        background-size: cover; } }
    @media (max-width: 767.98px) {
      .svg-virgule-modulaire {
        height: 1190px;
        width: 850px;
        left: 20px;
        background-position: center;
        background-size: contain; } }
    @media (max-width: 575.98px) {
      .svg-virgule-modulaire {
        left: -160px; } }
  .svg-virgule-red {
    width: 871px;
    height: 657px;
    top: 0;
    left: -550px;
    right: 0;
    bottom: inherit;
    opacity: 1;
    background-image: url("../img/icon/big-virgule-red.svg");
    background-size: cover;
    transform: rotate(0); }
    @media (max-width: 575.98px) {
      .svg-virgule-red {
        opacity: 0.4; } }

.svg-flexible-big-virgule {
  position: absolute;
  right: -30px;
  margin: auto;
  top: 40px;
  bottom: 0;
  height: 520px;
  width: 689px;
  z-index: -1;
  background-size: cover;
  background-image: url("../img/icon/svg-flexible-big-virgule.svg"); }
  .svg-flexible-big-virgule-blue {
    right: initial;
    left: -30px;
    background-image: url("../img/icon/svg-flexible-big-virgule-blue.svg"); }
  @media (max-width: 1199.98px) {
    .svg-flexible-big-virgule {
      height: 367px;
      width: 485px; } }
  @media (max-width: 991.98px) {
    .svg-flexible-big-virgule {
      height: 357px;
      width: 393px; } }
  @media (max-width: 767.98px) {
    .svg-flexible-big-virgule {
      height: 297px;
      width: 293px; } }
  @media (max-width: 575.98px) {
    .svg-flexible-big-virgule {
      height: 227px;
      width: 256px; } }

.svg-marie {
  position: absolute;
  left: 0;
  top: 0px;
  width: 157.23px;
  height: 105.92px;
  background-size: cover;
  background-image: url(../img/icon/svg-marie.svg); }
  @media (max-width: 1199.98px) {
    .svg-marie {
      width: 113.23px;
      height: 75.92px; } }
  @media (max-width: 991.98px) {
    .svg-marie {
      width: 157.23px;
      height: 105.92px; } }
  @media (max-width: 575.98px) {
    .svg-marie {
      display: none; } }

.img_select1 {
  position: absolute;
  left: 20px;
  top: 70px;
  height: 73px;
  width: 179px;
  background-size: cover;
  background-image: url("../img/global/img-marg.svg"); }
  @media (max-width: 1199.98px) {
    .img_select1 {
      height: 58px;
      width: 142px;
      top: 30px; } }
  @media (max-width: 991.98px) {
    .img_select1 {
      height: 74px;
      width: 182px;
      top: 30px;
      left: 0px; } }
  @media (max-width: 767.98px) {
    .img_select1 {
      height: 58px;
      width: 142px;
      top: 50px;
      left: 20px; } }
  @media (max-width: 575.98px) {
    .img_select1 {
      height: 58px;
      width: 142px;
      top: -10px;
      left: 5%;
      transform: scale(70%); } }

.img_select2 {
  position: absolute;
  right: -20px;
  top: 170px;
  width: 157.23px;
  height: 105.92px;
  background-size: cover;
  background-image: url("../img/global/img-eco2.svg"); }
  @media (max-width: 1199.98px) {
    .img_select2 {
      height: 87px;
      width: 129px;
      top: 140px; } }
  @media (max-width: 991.98px) {
    .img_select2 {
      height: 121px;
      width: 179px; } }
  @media (max-width: 767.98px) {
    .img_select2 {
      height: 87px;
      width: 129px;
      right: 0px;
      top: 169px; } }
  @media (max-width: 575.98px) {
    .img_select2 {
      height: 97px;
      width: 143px;
      right: 5%;
      top: -10px;
      transform: scale(70%); } }

.pre-footer {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }
  .pre-footer .pft_img_1 {
    position: absolute;
    top: 0;
    left: 0;
    mix-blend-mode: multiply;
    z-index: -1; }
    @media (max-width: 1399.98px) {
      .pre-footer .pft_img_1 {
        left: -116px; } }
    @media (max-width: 991.98px) {
      .pre-footer .pft_img_1 {
        top: 80px;
        left: -80px;
        width: 410px; } }
    @media (max-width: 767.98px) {
      .pre-footer .pft_img_1 {
        top: initial;
        bottom: 0;
        left: -10px;
        width: 140px; } }
    @media (max-width: 575.98px) {
      .pre-footer .pft_img_1 {
        width: 110px; } }
  .pre-footer .pft_img_1_1 {
    width: 198px;
    height: 32px;
    position: absolute;
    top: 306px;
    left: 330px;
    background-repeat: no-repeat; }
    @media (max-width: 1399.98px) {
      .pre-footer .pft_img_1_1 {
        left: 216px; } }
    @media (max-width: 991.98px) {
      .pre-footer .pft_img_1_1 {
        top: 260px;
        left: 80px;
        width: 170px;
        background-size: contain; } }
    @media (max-width: 767.98px) {
      .pre-footer .pft_img_1_1 {
        top: initial;
        bottom: 40px;
        left: 10px;
        width: 140px;
        z-index: -1; } }
    @media (max-width: 575.98px) {
      .pre-footer .pft_img_1_1 {
        width: 110px; } }
  .pre-footer .pft_img_2 {
    position: absolute;
    width: 100%;
    height: 530px;
    bottom: -246px;
    left: -150px;
    right: 0;
    margin: auto;
    z-index: -2;
    background-position: center;
    background-repeat: no-repeat; }
    @media (max-width: 1199.98px) {
      .pre-footer .pft_img_2 {
        height: 570px; } }
    @media (max-width: 991.98px) {
      .pre-footer .pft_img_2 {
        height: 470px;
        background-size: cover;
        left: 0;
        right: 0; } }
    @media (max-width: 767.98px) {
      .pre-footer .pft_img_2 {
        height: 440px; } }
    @media (max-width: 575.98px) {
      .pre-footer .pft_img_2 {
        height: 380px; } }
  .pre-footer .pft_img_3 {
    position: absolute;
    top: 0;
    right: 0;
    mix-blend-mode: multiply;
    z-index: -1; }
    @media (max-width: 1399.98px) {
      .pre-footer .pft_img_3 {
        right: -56px; } }
    @media (max-width: 991.98px) {
      .pre-footer .pft_img_3 {
        top: 80px;
        right: -29px;
        width: 410px; } }
    @media (max-width: 767.98px) {
      .pre-footer .pft_img_3 {
        top: initial;
        bottom: -50px;
        right: -32px;
        width: 200px; } }
    @media (max-width: 575.98px) {
      .pre-footer .pft_img_3 {
        width: 160px; } }
  .pre-footer .content-pre-ft {
    text-align: center;
    height: 590px; }
    @media (max-width: 1199.98px) {
      .pre-footer .content-pre-ft {
        height: 600px; } }
    @media (max-width: 991.98px) {
      .pre-footer .content-pre-ft {
        height: 470px; } }
    @media (max-width: 767.98px) {
      .pre-footer .content-pre-ft {
        height: 400px; } }
    @media (max-width: 575.98px) {
      .pre-footer .content-pre-ft {
        height: 280px; } }
    .pre-footer .content-pre-ft .desc {
      color: #383f51;
      text-shadow: 0px 0px 2px #f1e9db; }
    .pre-footer .content-pre-ft h2 {
      font-size: 64px;
      line-height: 68px; }
      @media (max-width: 1199.98px) {
        .pre-footer .content-pre-ft h2 {
          font-size: 44px;
          line-height: 48px; } }
      @media (max-width: 991.98px) {
        .pre-footer .content-pre-ft h2 {
          font-size: 36px;
          line-height: 40px; } }
      @media (max-width: 767.98px) {
        .pre-footer .content-pre-ft h2 {
          font-size: 26px;
          line-height: 30px; } }
  .pre-footer .pft_img_2_1 {
    width: 157.23px;
    height: 106.76px;
    position: absolute;
    top: 4px;
    right: 355px;
    background-repeat: no-repeat; }
    @media (max-width: 1399.98px) {
      .pre-footer .pft_img_2_1 {
        right: 299px; } }
    @media (max-width: 991.98px) {
      .pre-footer .pft_img_2_1 {
        top: 82px;
        right: 197px;
        width: 105px;
        background-size: contain; } }
    @media (max-width: 767.98px) {
      .pre-footer .pft_img_2_1 {
        top: initial;
        bottom: 100px;
        right: 70px;
        width: 70px;
        z-index: -1; } }
    @media (max-width: 575.98px) {
      .pre-footer .pft_img_2_1 {
        width: 50px;
        bottom: 40px;
        right: 50px; } }
  .pre-footer .pft_img_2_2 {
    width: 134px;
    height: 32px;
    position: absolute;
    top: 239px;
    right: 219px;
    background-repeat: no-repeat; }
    @media (max-width: 1399.98px) {
      .pre-footer .pft_img_2_2 {
        right: 164px; } }
    @media (max-width: 991.98px) {
      .pre-footer .pft_img_2_2 {
        top: 234px;
        right: 112px;
        width: 88px;
        background-size: contain; } }
    @media (max-width: 767.98px) {
      .pre-footer .pft_img_2_2 {
        top: initial;
        bottom: 87px;
        right: 40px;
        width: 60px;
        z-index: -1; } }
    @media (max-width: 575.98px) {
      .pre-footer .pft_img_2_2 {
        width: 50px;
        bottom: 40px;
        right: 50px; } }
  .pre-footer .pft_img_2_3 {
    width: 126px;
    height: 40px;
    position: absolute;
    top: 162px;
    right: 59px;
    background-repeat: no-repeat; }
    @media (max-width: 1399.98px) {
      .pre-footer .pft_img_2_3 {
        right: 5px; } }
    @media (max-width: 991.98px) {
      .pre-footer .pft_img_2_3 {
        top: 184px;
        right: 2px;
        width: 88px;
        background-size: contain; } }
    @media (max-width: 767.98px) {
      .pre-footer .pft_img_2_3 {
        top: initial;
        bottom: 102px;
        right: -6px;
        width: 60px;
        z-index: -1; } }
    @media (max-width: 575.98px) {
      .pre-footer .pft_img_2_3 {
        width: 50px;
        bottom: 56px;
        right: -7px; } }

/*
|--------------------
|     PAGE HOME
|--------------------
*/
#page-home {
  overflow: hidden;
  position: relative; }

.banner-home {
  height: 100vh;
  position: relative;
  text-align: center; }
  .banner-home .item-content {
    position: relative;
    z-index: 8;
    height: 100vh;
    color: black; }
    .banner-home .item-content .item-title {
      font-family: "Px Grotesk";
      font-style: normal;
      font-weight: 400;
      font-size: 64px;
      line-height: 71px;
      text-align: center; }
      @media (max-width: 991.98px) {
        .banner-home .item-content .item-title {
          font-size: 54px;
          line-height: 60px; } }
      @media (max-width: 767.98px) {
        .banner-home .item-content .item-title {
          font-size: 44px;
          line-height: 50px; } }
      @media (max-width: 575.98px) {
        .banner-home .item-content .item-title {
          font-size: 30px;
          line-height: 36px; } }
    .banner-home .item-content .item-text {
      font-family: "Px Grotesk";
      font-style: normal;
      font-size: 16px;
      line-height: 19px;
      text-align: center; }
      .banner-home .item-content .item-text p {
        margin-bottom: 15px; }
    .banner-home .item-content .home-link {
      position: absolute;
      bottom: 30px;
      left: 0;
      right: 0;
      padding: 7px;
      width: 430px;
      border-radius: 40px;
      margin: auto;
      background-color: white;
      z-index: 1; }
      @media (max-width: 767.98px) {
        .banner-home .item-content .home-link {
          display: block !important;
          width: 90%;
          left: 10px;
          right: 10px;
          background: none; }
          .banner-home .item-content .home-link span {
            display: block;
            margin-bottom: 10px;
            background: none; } }
      .banner-home .item-content .home-link a {
        font-family: "Px Grotesk";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        text-align: center;
        border: none; }

.a__svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: scale(0.8);
  /*.a__svg-top {
    position: absolute;

    top: -23vw;
    left: 0;
    right: 0;

    display: flex;
    justify-content: center;

    svg {
      width: 70vw;
      max-width: 1000px;
      height: auto;
    }
  }

  .a__svg-right {
    position: absolute;

    right: -20vw;
    top: 0;
    bottom: 0;

    display: flex;
    align-items: center;

    svg {
      height: 70vw;
      max-height: 1000px;
      width: auto;
    }
  }

  .a__svg-bottom {
    position: absolute;

    bottom: -23vw;
    left: 0;
    right: 0;

    display: flex;
    justify-content: center;

    width: 60%;
    margin: auto;

    svg {
      width: 70vw;
      max-width: 1000px;
      height: auto;
    }
  }

  .a__svg-left {
    position: absolute;

    left: -20vw;
    top: 0;
    bottom: 0;

    display: flex;
    align-items: center;

    svg {
      height: 70vw;
      max-height: 1000px;
      width: auto;
    }
  }*/ }
  @media (max-width: 991.98px) {
    .a__svg {
      bottom: 200px; } }
  .a__svg .a__svg-top,
  .a__svg .a__svg-right,
  .a__svg .a__svg-bottom,
  .a__svg .a__svg-left {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50% 50%);
    display: flex;
    justify-content: center; }
    .a__svg .a__svg-top svg,
    .a__svg .a__svg-right svg,
    .a__svg .a__svg-bottom svg,
    .a__svg .a__svg-left svg {
      width: 70vw;
      max-width: 1000px;
      height: auto; }

/*
|--------------------
|     PAGE flexible
|--------------------
*/
/*
|--------------------
|      NEWS
|--------------------
*/
.blog .banner {
  height: 53vh; }
  @media (max-width: 991.98px) {
    .blog .banner {
      height: 300px; } }
  .blog .banner .item-content {
    height: 53vh; }
    @media (max-width: 991.98px) {
      .blog .banner .item-content {
        height: 300px; } }

.load-more-container {
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .load-more-container .loader-container {
    display: none; }

/*
|--------------------
|       Contact
|--------------------
*/
#page-a-propos {
  position: relative;
  overflow: hidden; }
  #page-a-propos .banner {
    height: initial;
    padding: 280px 0px 70px 0px; }
    @media (max-width: 1199.98px) {
      #page-a-propos .banner {
        padding: 280px 0px 0px 0px; } }
  #page-a-propos .item-content {
    height: initial; }

.section_values img {
  width: 40px; }

.section_values .val-img {
  width: 180px;
  max-height: 100px; }

.section_values h3 {
  font-family: "Px Grotesk";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 35px;
  color: #ff563f; }

.section_values .desc {
  font-family: "Px Grotesk";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px; }

.section_news h2 {
  text-align: center; }

.section_news .custom-card .custom-card-link {
  display: block; }

.section_news .custom-card {
  padding-right: 40px; }
  @media (max-width: 1199.98px) {
    .section_news .custom-card {
      padding-right: 0px; } }
  .section_news .custom-card .card-img-container {
    position: relative;
    overflow: hidden;
    display: block; }
    .section_news .custom-card .card-img-container:after {
      content: "";
      display: block;
      padding-bottom: 100%; }
    .section_news .custom-card .card-img-container .card-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: transform 2s cubic-bezier(0.19, 1, 0.22, 1);
      will-change: transform; }
  .section_news .custom-card .card-content {
    display: block; }
    .section_news .custom-card .card-content .date {
      font-family: "Px Grotesk";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      color: #ff563f;
      display: block; }
    .section_news .custom-card .card-content .intro {
      font-family: "Px Grotesk";
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 27px;
      text-align: left;
      display: block; }
  .section_news .custom-card:last-child .custom-card {
    padding-right: 0px; }
  .section_news .custom-card:hover .card-img-container .card-img {
    transform: scale(1.05); }

.section_numbers .number {
  font-family: "Px Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 76px;
  color: #ff563f; }
  @media (max-width: 991.98px) {
    .section_numbers .number {
      font-size: 50px;
      line-height: 60px; } }

.section_numbers .text {
  font-family: "Px Grotesk";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #383f51;
  min-height: 60px; }

.section_team .team {
  height: 195px; }
  @media (max-width: 1399.98px) {
    .section_team .team {
      height: 162px; } }
  @media (max-width: 1199.98px) {
    .section_team .team {
      height: 132px; } }
  @media (max-width: 991.98px) {
    .section_team .team {
      height: 155px; } }
  @media (max-width: 767.98px) {
    .section_team .team {
      height: 194px; } }
  @media (max-width: 575.98px) {
    .section_team .team {
      height: 132px; } }
  .section_team .team .elem-team {
    position: relative; }
    @media (max-width: 767.98px) {
      .section_team .team .elem-team {
        width: 396px;
        margin: auto; } }
    @media (max-width: 575.98px) {
      .section_team .team .elem-team {
        width: 270px;
        margin: auto; } }
    .section_team .team .elem-team:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 195px;
      background-image: url(../img/icon/cadre.svg);
      background-size: cover;
      z-index: 1; }
      @media (max-width: 1399.98px) {
        .section_team .team .elem-team:before {
          height: 162px; } }
      @media (max-width: 1199.98px) {
        .section_team .team .elem-team:before {
          height: 133px; } }
      @media (max-width: 991.98px) {
        .section_team .team .elem-team:before {
          height: 155px; } }
      @media (max-width: 767.98px) {
        .section_team .team .elem-team:before {
          height: 195px; } }
      @media (max-width: 575.98px) {
        .section_team .team .elem-team:before {
          height: 133px; } }
    .section_team .team .elem-team .name {
      position: absolute;
      top: -3px;
      left: 0;
      right: 0;
      margin: auto;
      background-color: white;
      display: inline-table;
      padding: 6px 10px;
      border-radius: 50px;
      font-family: "Px Grotesk";
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #484848;
      z-index: 2; }
      @media (max-width: 1399.98px) {
        .section_team .team .elem-team .name {
          top: -6px;
          font-size: 15px;
          line-height: 17px; } }
      @media (max-width: 1199.98px) {
        .section_team .team .elem-team .name {
          font-size: 14px;
          line-height: 17px; } }
      @media (max-width: 767.98px) {
        .section_team .team .elem-team .name {
          font-size: 14px;
          line-height: 17px; } }
    .section_team .team .elem-team .photo {
      float: left;
      width: 33%;
      height: 171px;
      background-size: cover;
      background-position: center;
      position: absolute;
      top: 13px;
      left: 12px; }
      @media (max-width: 1399.98px) {
        .section_team .team .elem-team .photo {
          height: 142px;
          top: 11px;
          left: 10px; } }
      @media (max-width: 1199.98px) {
        .section_team .team .elem-team .photo {
          height: 117px;
          top: 8px;
          left: 8px; } }
      @media (max-width: 991.98px) {
        .section_team .team .elem-team .photo {
          height: 138px; } }
      @media (max-width: 767.98px) {
        .section_team .team .elem-team .photo {
          height: 171px;
          top: 13px;
          left: 12px; } }
      @media (max-width: 575.98px) {
        .section_team .team .elem-team .photo {
          height: 117px;
          top: 8px;
          left: 8px; } }
    .section_team .team .elem-team .infos {
      float: left;
      width: 61%;
      height: 171px;
      background-size: cover;
      position: absolute;
      top: 13px;
      right: 12px;
      padding: 30px;
      text-align: center;
      font-family: "Px Grotesk";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      z-index: 1; }
      @media (max-width: 1399.98px) {
        .section_team .team .elem-team .infos {
          height: 142px;
          top: 11px;
          right: 10px;
          font-size: 15px;
          line-height: 20px;
          padding: 0 22px; } }
      @media (max-width: 1199.98px) {
        .section_team .team .elem-team .infos {
          height: 113px;
          font-size: 12px;
          line-height: 16px;
          padding: 22px 22px 0 22px; } }
      @media (max-width: 767.98px) {
        .section_team .team .elem-team .infos {
          height: 172px;
          font-size: 15px;
          line-height: 17px;
          padding: 22px; } }
      @media (max-width: 575.98px) {
        .section_team .team .elem-team .infos {
          height: 113px;
          font-size: 12px;
          line-height: 16px;
          padding: 22px 22px 0 22px; } }
      .section_team .team .elem-team .infos .link_lk {
        background: white;
        text-decoration: none;
        padding: 13px 20px;
        border-radius: 60px;
        display: inline-block; }
        @media (max-width: 1199.98px) {
          .section_team .team .elem-team .infos .link_lk {
            padding: 5px 12px; } }
        @media (max-width: 767.98px) {
          .section_team .team .elem-team .infos .link_lk {
            padding: 13px 20px; } }
        @media (max-width: 575.98px) {
          .section_team .team .elem-team .infos .link_lk {
            padding: 5px 12px; } }
        .section_team .team .elem-team .infos .link_lk img {
          content: "";
          width: 12px;
          height: 12px;
          display: block;
          background-color: white; }
  .section_team .team .elem-team-text {
    position: relative; }
    @media (max-width: 767.98px) {
      .section_team .team .elem-team-text {
        width: 396px;
        margin: auto; } }
    @media (max-width: 575.98px) {
      .section_team .team .elem-team-text {
        width: 270px;
        margin: auto; } }
    .section_team .team .elem-team-text:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 195px;
      background-image: url(../img/icon/cadre.svg);
      background-size: cover;
      z-index: 1; }
      @media (max-width: 1399.98px) {
        .section_team .team .elem-team-text:before {
          height: 162px; } }
      @media (max-width: 1199.98px) {
        .section_team .team .elem-team-text:before {
          height: 133px; } }
      @media (max-width: 991.98px) {
        .section_team .team .elem-team-text:before {
          height: 155px; } }
      @media (max-width: 767.98px) {
        .section_team .team .elem-team-text:before {
          height: 195px; } }
      @media (max-width: 575.98px) {
        .section_team .team .elem-team-text:before {
          height: 133px; } }
    .section_team .team .elem-team-text .dflex {
      height: 195px;
      padding: 0 30px;
      text-align: center; }
      @media (max-width: 1399.98px) {
        .section_team .team .elem-team-text .dflex {
          height: 162px; } }
      @media (max-width: 1199.98px) {
        .section_team .team .elem-team-text .dflex {
          height: 132px;
          padding: 0 11px; } }
      @media (max-width: 991.98px) {
        .section_team .team .elem-team-text .dflex {
          height: 154px;
          padding: 0 13px; } }
      @media (max-width: 767.98px) {
        .section_team .team .elem-team-text .dflex {
          height: 195px;
          padding: 0 30px; } }
      @media (max-width: 575.98px) {
        .section_team .team .elem-team-text .dflex {
          height: 132px;
          padding: 0 11px; } }
    .section_team .team .elem-team-text .text {
      font-family: "Px Grotesk";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center; }
      @media (max-width: 1399.98px) {
        .section_team .team .elem-team-text .text {
          font-size: 15px;
          line-height: 20px; } }
      @media (max-width: 1199.98px) {
        .section_team .team .elem-team-text .text {
          font-size: 13px;
          line-height: 16px; } }
      @media (max-width: 767.98px) {
        .section_team .team .elem-team-text .text {
          font-size: 16px;
          line-height: 24px; } }
      @media (max-width: 575.98px) {
        .section_team .team .elem-team-text .text {
          font-size: 13px;
          line-height: 16px; } }
  .section_team .team:hover .link_lk {
    background: #ff563f !important; }
    .section_team .team:hover .link_lk svg path {
      fill: white; }

.section_list img {
  width: 40px; }

.section_list h3 {
  font-family: "Px Grotesk";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 35px;
  color: #ff563f;
  padding: 3px 0; }

.section_list .desc {
  font-family: "Px Grotesk";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px; }

/*
|--------------------
|      SINGLE
|--------------------
*/
#page-news-single .post-back {
  position: absolute;
  top: 40px;
  font-family: "Px Grotesk";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  z-index: 1;
  color: #ff563f; }
  #page-news-single .post-back::before {
    content: "";
    background-image: url("../img/icon/arrow-right.svg");
    width: 32px;
    height: 32px;
    display: block;
    float: left;
    margin-top: -7px;
    margin-right: 4px;
    transform: rotate(-180deg); }

#page-news-single .post-date {
  font-family: "Px Grotesk";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #ff563f; }

#page-news-single .post-title {
  font-family: "Px Grotesk";
  font-style: normal;
  font-weight: 400;
  font-size: 55px;
  line-height: 64px;
  text-align: center; }
  @media (max-width: 991.98px) {
    #page-news-single .post-title {
      font-size: 40px;
      line-height: 48px; } }
  @media (max-width: 767.98px) {
    #page-news-single .post-title {
      font-size: 28px;
      line-height: 32px; } }

#page-news-single .post-intro {
  font-family: "Px Grotesk";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  text-align: center; }

#page-news-single .post-head {
  display: flex; }
  #page-news-single .post-head .link-alt {
    float: right;
    position: absolute;
    right: 0;
    top: 20px; }

#page-news-single .caption {
  text-align: center;
  font-family: "Px Grotesk";
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #ff563f;
  padding: 20px; }

/*
|--------------------
|   PAGE ecosysteme
|--------------------
*/
@media (max-width: 991.98px) {
  #page-ecosysteme .js-filterable h2 {
    text-align: center; } }

#page-ecosysteme .filter {
  display: inline-block; }
  #page-ecosysteme .filter li {
    display: inline-block; }
    #page-ecosysteme .filter li:hover {
      color: #ffffff;
      background-color: #ff563f; }
    #page-ecosysteme .filter li:hover {
      color: white;
      background-color: #ff563f; }
      #page-ecosysteme .filter li:hover:before {
        background-color: white; }
  #page-ecosysteme .filter .btn:hover, #page-ecosysteme .filter .btn-black:hover, #page-ecosysteme .filter .btn-white:hover, #page-ecosysteme .filter .btn-xs:hover, #page-ecosysteme .filter .btn-sm:hover, #page-ecosysteme .filter .btn-md:hover, #page-ecosysteme .filter .btn-lg:hover {
    background-color: #ff563f;
    color: white; }
    #page-ecosysteme .filter .btn:hover:before, #page-ecosysteme .filter .btn-black:hover:before, #page-ecosysteme .filter .btn-white:hover:before, #page-ecosysteme .filter .btn-xs:hover:before, #page-ecosysteme .filter .btn-sm:hover:before, #page-ecosysteme .filter .btn-md:hover:before, #page-ecosysteme .filter .btn-lg:hover:before {
      background-color: white; }
  #page-ecosysteme .filter .btn-alt:hover {
    color: white;
    background-color: #ff563f; }
    #page-ecosysteme .filter .btn-alt:hover:before {
      background-color: white; }

#page-ecosysteme .card-img {
  height: 120px;
  width: 120px;
  display: block;
  margin: auto; }

#page-ecosysteme .card-content {
  font-family: "Px Grotesk";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center; }

/*
|--------------------
|   PAGES profil
|--------------------
*/
#single-profil h1 {
  font-family: "Px Grotesk";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #ff563f;
  text-align: left; }

#single-profil .title {
  font-family: "Px Grotesk";
  font-style: normal;
  font-weight: 400;
  font-size: 59px;
  line-height: 63px; }
  @media (max-width: 991.98px) {
    #single-profil .title {
      font-size: 42px;
      line-height: 50px; } }
  @media (max-width: 1199.98px) {
    #single-profil .title {
      font-size: 36px;
      line-height: 40px; } }

#single-profil .list-anchor {
  width: 321px; }
  @media (max-width: 1199.98px) {
    #single-profil .list-anchor {
      max-width: 288px;
      width: 100%; } }
  @media (max-width: 991.98px) {
    #single-profil .list-anchor {
      margin: auto; } }
  #single-profil .list-anchor li {
    border-bottom: 1px solid #ff563f; }
    #single-profil .list-anchor li a {
      font-family: "Px Grotesk";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 20px;
      padding: 16px 40px 16px 12px;
      position: relative;
      background-color: transparent;
      width: 100%;
      border-radius: 7px;
      margin-top: -4px; }
      #single-profil .list-anchor li a:after {
        content: "";
        height: 22px;
        width: 22px;
        display: inline-block;
        position: absolute;
        right: 10px;
        top: 14px;
        background-image: url("../img/icon/ArrowDown.svg"); }
    #single-profil .list-anchor li:hover,
    #single-profil .list-anchor li .active {
      border-bottom: 1px solid transparent; }
      #single-profil .list-anchor li:hover a,
      #single-profil .list-anchor li .active a {
        background-color: white; }

#single-profil .intro {
  font-family: "Px Grotesk";
  font-style: normal;
  font-size: 18px;
  line-height: 28px; }

#page-profils .card-profils {
  background-color: white;
  padding: 20px 50px 20px 30px;
  margin-bottom: 30px;
  border-radius: 10px;
  position: relative;
  font-size: 16px;
  line-height: 24px; }
  #page-profils .card-profils:after {
    content: "";
    height: 22px;
    width: 12px;
    display: inline-block;
    position: absolute;
    right: 30px;
    top: 0;
    bottom: 0;
    margin: auto;
    background-image: url(../img/icon/arrow.svg);
    transition: all ease 0.3s; }
  #page-profils .card-profils:hover:after {
    right: 25px;
    transition: all ease 0.3s; }
  #page-profils .card-profils .title {
    font-weight: 700;
    display: block; }

@media (max-width: 575.98px) {
  #page-profils #section-temoignages .sliders-tem #swiper-tem-thumbs {
    display: none; } }

#page-profils #section-temoignages .sliders-tem #swiper-tem-thumbs .swiper-slide {
  width: 20%;
  height: 100%;
  cursor: pointer; }
  #page-profils #section-temoignages .sliders-tem #swiper-tem-thumbs .swiper-slide .tem-img {
    width: calc(100% - 30px);
    height: 112px;
    background-size: cover;
    background-position: center;
    background-color: #fff;
    border-radius: 10px;
    margin: 15px;
    display: flex;
    vertical-align: middle;
    align-items: center; }
    @media (max-width: 991.98px) {
      #page-profils #section-temoignages .sliders-tem #swiper-tem-thumbs .swiper-slide .tem-img {
        height: 60px; } }
    #page-profils #section-temoignages .sliders-tem #swiper-tem-thumbs .swiper-slide .tem-img img {
      max-height: 60px;
      height: inherit;
      margin: auto;
      display: block; }
      @media (max-width: 1199.98px) {
        #page-profils #section-temoignages .sliders-tem #swiper-tem-thumbs .swiper-slide .tem-img img {
          height: 40px; } }
  #page-profils #section-temoignages .sliders-tem #swiper-tem-thumbs .swiper-slide.swiper-slide-thumb-active {
    background-color: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px; }

#page-profils #section-temoignages .sliders-tem #swiper-tem {
  font-family: "Px Grotesk";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #383f51;
  overflow: hidden;
  background-color: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px; }
  #page-profils #section-temoignages .sliders-tem #swiper-tem .swiper-wrapper {
    position: relative; }
    #page-profils #section-temoignages .sliders-tem #swiper-tem .swiper-wrapper .swiper-slide {
      min-height: 475px; }
      #page-profils #section-temoignages .sliders-tem #swiper-tem .swiper-wrapper .swiper-slide .tem-title {
        color: #ff563f; }
      #page-profils #section-temoignages .sliders-tem #swiper-tem .swiper-wrapper .swiper-slide .tem-text {
        font-family: "Px Grotesk";
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 42px;
        position: relative; }
        @media (max-width: 767.98px) {
          #page-profils #section-temoignages .sliders-tem #swiper-tem .swiper-wrapper .swiper-slide .tem-text {
            font-size: 26px;
            line-height: 30px; } }
        #page-profils #section-temoignages .sliders-tem #swiper-tem .swiper-wrapper .swiper-slide .tem-text:before {
          content: "";
          background-image: url("../img/icon/quote.svg");
          width: 37px;
          height: 27px;
          display: block;
          float: left;
          left: 0;
          position: absolute;
          margin-top: 6px; }
      #page-profils #section-temoignages .sliders-tem #swiper-tem .swiper-wrapper .swiper-slide .tem-infos {
        float: left;
        margin-right: 10px; }
        #page-profils #section-temoignages .sliders-tem #swiper-tem .swiper-wrapper .swiper-slide .tem-infos .tem-auth-img {
          width: 80px;
          height: 80px;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          margin-right: 20px; }
        #page-profils #section-temoignages .sliders-tem #swiper-tem .swiper-wrapper .swiper-slide .tem-infos .tem-auth-title {
          font-weight: 700; }
  #page-profils #section-temoignages .sliders-tem #swiper-tem .swiper-buttons {
    position: absolute;
    bottom: 60px;
    right: 60px;
    z-index: 5; }
    @media (max-width: 767.98px) {
      #page-profils #section-temoignages .sliders-tem #swiper-tem .swiper-buttons {
        position: relative;
        bottom: 0;
        right: 0;
        left: 0;
        margin: 0 auto 20px auto;
        z-index: 5;
        display: block;
        text-align: center;
        width: 158px;
        height: 52px; } }
    #page-profils #section-temoignages .sliders-tem #swiper-tem .swiper-buttons .button-prev,
    #page-profils #section-temoignages .sliders-tem #swiper-tem .swiper-buttons .button-next {
      width: 58px;
      height: 52px;
      background: #f1e9da;
      border-radius: 50px;
      float: left;
      margin: 0 10px;
      cursor: pointer; }
      #page-profils #section-temoignages .sliders-tem #swiper-tem .swiper-buttons .button-prev::before,
      #page-profils #section-temoignages .sliders-tem #swiper-tem .swiper-buttons .button-next::before {
        content: "";
        background-image: url("../img/icon/arrow-right.svg");
        width: 32px;
        height: 32px;
        display: block;
        margin: 9px auto; }
      #page-profils #section-temoignages .sliders-tem #swiper-tem .swiper-buttons .button-prev.swiper-button-disabled,
      #page-profils #section-temoignages .sliders-tem #swiper-tem .swiper-buttons .button-next.swiper-button-disabled {
        opacity: 0.5; }
    #page-profils #section-temoignages .sliders-tem #swiper-tem .swiper-buttons .button-prev::before {
      content: "";
      transform: rotate(180deg); }

/*
|--------------------
|       Contact
|--------------------
*/
#page-contact {
  position: relative;
  overflow: hidden; }
  #page-contact .sup_title {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #ff563f; }
  #page-contact .email {
    font-style: normal;
    font-weight: 400;
    font-size: 64px;
    line-height: 71px;
    text-align: center;
    color: #383f51; }
    @media (max-width: 991.98px) {
      #page-contact .email {
        font-size: 44px;
        line-height: 50px; } }
    @media (max-width: 767.98px) {
      #page-contact .email {
        font-size: 22px;
        line-height: 26px; } }
  #page-contact .text {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    color: #383f51; }
  #page-contact .gform_fields {
    margin-right: 0px;
    margin-left: 0px; }
  #page-contact .gform_wrapper {
    font-family: Inter;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px; }
    #page-contact .gform_wrapper h2,
    #page-contact .gform_wrapper .gform_required_legend,
    #page-contact .gform_wrapper .gform_validation_errors {
      display: none; }
    #page-contact .gform_wrapper p,
    #page-contact .gform_wrapper a {
      font-family: "Px Grotesk";
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px; }
    #page-contact .gform_wrapper .gfield {
      margin-bottom: 15px; }
    #page-contact .gform_wrapper label {
      font-family: "Px Grotesk";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #383f51; }
    #page-contact .gform_wrapper .gfield_required {
      /* display: none; */ }
    #page-contact .gform_wrapper input,
    #page-contact .gform_wrapper textarea,
    #page-contact .gform_wrapper select {
      border: none; }
    #page-contact .gform_wrapper input[type="text"],
    #page-contact .gform_wrapper input[type="email"],
    #page-contact .gform_wrapper textarea {
      box-sizing: border-box;
      border-radius: 10px;
      padding: 15px 20px !important;
      background-color: #ffffff; }
      #page-contact .gform_wrapper input[type="text"]::placeholder,
      #page-contact .gform_wrapper input[type="email"]::placeholder,
      #page-contact .gform_wrapper textarea::placeholder {
        opacity: 0.5; }
    #page-contact .gform_wrapper textarea {
      height: 275px !important; }
    #page-contact .gform_wrapper .gfield_label_before_complex {
      display: none; }
    #page-contact .gform_wrapper .gfield_description {
      font-family: "Px Grotesk";
      font-style: normal;
      color: #a7a7a9;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px; }
    #page-contact .gform_wrapper .ginput_container_consent {
      font-family: "Px Grotesk";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      color: #383f51;
      position: relative; }
      #page-contact .gform_wrapper .ginput_container_consent a {
        text-decoration: underline;
        font-family: "Px Grotesk";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        color: #383f51;
        position: relative; }
      #page-contact .gform_wrapper .ginput_container_consent .gfield_required {
        /* display: none; */ }
      #page-contact .gform_wrapper .ginput_container_consent input[type="checkbox"] {
        position: absolute;
        cursor: pointer;
        border: none;
        left: 0;
        top: 2px;
        padding: 0;
        margin: 0; }
      #page-contact .gform_wrapper .ginput_container_consent input[type="checkbox"]:before {
        content: "";
        display: block;
        position: absolute;
        width: 21px;
        height: 21px;
        top: 0;
        left: 0;
        background-color: transparent;
        border: 1px solid #ff563f; }
      #page-contact .gform_wrapper .ginput_container_consent input[type="checkbox"]:checked:after {
        content: "";
        display: block;
        width: 15px;
        height: 15px;
        position: absolute;
        top: 3px;
        left: 3px;
        background-color: #ff563f; }
      #page-contact .gform_wrapper .ginput_container_consent label {
        margin-top: 4px;
        margin-left: 35px; }
        @media (max-width: 1199.98px) {
          #page-contact .gform_wrapper .ginput_container_consent label {
            padding-right: 0px; } }
    #page-contact .gform_wrapper .btn, #page-contact .gform_wrapper .btn-black, #page-contact .gform_wrapper .btn-white, #page-contact .gform_wrapper .btn-xs, #page-contact .gform_wrapper .btn-sm, #page-contact .gform_wrapper .btn-md, #page-contact .gform_wrapper .btn-lg {
      float: right; }
      @media (max-width: 991.98px) {
        #page-contact .gform_wrapper .btn, #page-contact .gform_wrapper .btn-black, #page-contact .gform_wrapper .btn-white, #page-contact .gform_wrapper .btn-xs, #page-contact .gform_wrapper .btn-sm, #page-contact .gform_wrapper .btn-md, #page-contact .gform_wrapper .btn-lg {
          float: initial; } }
      #page-contact .gform_wrapper .btn:after, #page-contact .gform_wrapper .btn-black:after, #page-contact .gform_wrapper .btn-white:after, #page-contact .gform_wrapper .btn-xs:after, #page-contact .gform_wrapper .btn-sm:after, #page-contact .gform_wrapper .btn-md:after, #page-contact .gform_wrapper .btn-lg:after {
        left: 20px; }
  #page-contact .gform_footer .btn, #page-contact .gform_footer .btn-black, #page-contact .gform_footer .btn-white, #page-contact .gform_footer .btn-xs, #page-contact .gform_footer .btn-sm, #page-contact .gform_footer .btn-md, #page-contact .gform_footer .btn-lg {
    float: initial;
    margin: auto; }
    #page-contact .gform_footer .btn .text, #page-contact .gform_footer .btn-black .text, #page-contact .gform_footer .btn-white .text, #page-contact .gform_footer .btn-xs .text, #page-contact .gform_footer .btn-sm .text, #page-contact .gform_footer .btn-md .text, #page-contact .gform_footer .btn-lg .text {
      font-family: "Px Grotesk";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 20px;
      transition: all ease 0.3s; }
    #page-contact .gform_footer .btn:hover .text, #page-contact .gform_footer .btn-black:hover .text, #page-contact .gform_footer .btn-white:hover .text, #page-contact .gform_footer .btn-xs:hover .text, #page-contact .gform_footer .btn-sm:hover .text, #page-contact .gform_footer .btn-md:hover .text, #page-contact .gform_footer .btn-lg:hover .text {
      color: white; }
  #page-contact #gform_ajax_spinner_1 {
    position: absolute; }
  #page-contact .textleave {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    color: #383f51;
    font-family: "Px Grotesk"; }
  #page-contact .gform_confirmation_message_1 {
    margin: 10vh 0 40vh 0;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    color: #383f51;
    font-family: "Px Grotesk"; }

/*
|--------------------
|       404
|--------------------
*/
#page-404 {
  background-size: cover;
  background-position: center; }
  #page-404 .section-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    color: #383f51;
    text-align: center;
    z-index: 1;
    position: relative; }
    #page-404 .section-content .item-title {
      font-size: 30px;
      font-weight: 300;
      line-height: 1; }
    #page-404 .section-content .item-text {
      font-size: 18px;
      line-height: 26px;
      font-weight: 300; }
      #page-404 .section-content .item-text a {
        color: #383f51;
        text-decoration: underline; }

/*
|--------------------
|      BUTTONS
|--------------------
*/
/*
|--------------------
|  Smooth Scrollbar
|--------------------
*/
/*
|
| Scroll area
|--------------
*/
.scroll-area .scrollbar-track.scrollbar-track-y {
  width: 4px !important; }
  .scroll-area .scrollbar-track.scrollbar-track-y .scrollbar-thumb {
    width: 100% !important;
    background: #000000 !important; }

/*
|-----------------------
| 	  Gravity Form
|-----------------------
|
*/
/*
|
| Reset form elements
|-------------------------
*/
input, textarea, select {
  width: 100%;
  -webkit-appearance: none;
  border: none;
  border-bottom: 1px solid #000000;
  border-radius: 0; }
  input:focus, textarea:focus, select:focus {
    outline: none; }

select {
  background-image: url("../img/icon/bottom.svg");
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: calc(100% - 10px) center; }

.validation_error {
  margin-bottom: 30px;
  color: #B00 !important; }

.validation_message {
  margin-top: 5px;
  color: #B00; }

#gform_ajax_spinner {
  margin-left: 10px;
  display: inline-block;
  width: 50px;
  height: 50px; }

/*
|
| Gravity form bootstrap
|-------------------------
*/
/*
|
| Materialize form
|-------------------
*/
.materialize-form .materialize-form-group {
  position: relative;
  margin-bottom: 25px; }
  .materialize-form .materialize-form-group label {
    position: absolute;
    top: 0;
    left: 15px; }
  .materialize-form .materialize-form-group input {
    margin-bottom: 35px;
    padding: 7px 0; }
  .materialize-form .materialize-form-group.gfield_error label {
    top: -20px; }

/*
|
| Page Loader
|--------------
*/
.page-loader {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  display: none; }
  .page-loader.active {
    display: flex; }
  .page-loader img {
    margin: auto; }
  .page-loader .item-content {
    width: 100%;
    color: #141414;
    text-align: center; }
  .page-loader .item-loadbar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    margin: auto; }
    .page-loader .item-loadbar .item-loadbar-inner {
      width: 100%;
      height: 100%;
      border-radius: 1px;
      background-color: #141414;
      animation: loadbar 1.2s cubic-bezier(0.92, 0, 0.09, 1);
      transform-origin: left top; }

@keyframes loadbar {
  from {
    transform: scaleX(0); }
  to {
    transform: scaleX(0.7); } }

[data-splittext] {
  opacity: 0; }
  [data-splittext].split-ready {
    opacity: 1; }

[data-kira-item="splitline"] .item-line {
  overflow: hidden; }

[data-kira-item^="fadeIn"] {
  opacity: 0; }

[data-kira-item^="fadeInLeft.stagger"],
[data-kira-item^="fadeInUp.stagger"] {
  opacity: 1; }
  [data-kira-item^="fadeInLeft.stagger"] [data-stagger-item],
  [data-kira-item^="fadeInUp.stagger"] [data-stagger-item] {
    opacity: 0; }
