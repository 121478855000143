/*
|--------------------------
|      DEFAULT & RESET
|--------------------------
*/
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  font-family: "Px Grotesk";
  color: $dark-grey;
  font-size: $default-font-size;
  line-height: $default-line-height;
  font-weight: $default-font-weight;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow-x: hidden;
  background-color: $beige;

  &.--loading {
    opacity: 0;
    overflow: hidden;
    transition: opacity 0.3s ease;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $dark-grey;
  font-family: "Px Grotesk";
}
h1 {
  font-family: "Px Grotesk";
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  line-height: 71px;
  text-align: center;
  @include media-breakpoint-down(lg) {
    font-size: 56px;
    line-height: 60px;
  }
  @include media-breakpoint-down(md) {
    font-size: 48px;
    line-height: 60px;
  }
  @include media-breakpoint-down(sm) {
    font-size: 30px;
    line-height: 36px;
  }
}
h2 {
  font-family: "Px Grotesk";
  font-style: normal;
  font-weight: 400;
  font-size: 44px;
  line-height: 46px;
  @include media-breakpoint-down(lg) {
    font-size: 44px;
    line-height: 46px;
  }
  @include media-breakpoint-down(md) {
    font-size: 36px;
    line-height: 40px;
  }
  @include media-breakpoint-down(sm) {
    font-size: 28px;
    line-height: 32px;
  }
}

.sup {
  color: $orange;
  font-family: "Px Grotesk";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
}

button,
input,
textarea {
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

button {
  -webkit-appearance: none;
  padding: 0;
  background-color: transparent;
  border: none;
}

img {
  max-width: 100%;
}

p {
  font-family: "Px Grotesk";
}

a,
img,
button {
  display: inline-block;
}

a {
  font-family: "Px Grotesk";
  color: $default-link-color;
  text-decoration: none;

  &:hover {
    color: $default-link-color;
  }
}

ul {
  margin: 0;
  padding: 0;

  li {
    list-style-type: none;
  }
}

.clearfix {
  clear: both;
  width: 100%;
  display: block;
}

.rel {
  position: relative;
}

.m-a {
  margin: auto;
}

.d-t {
  display: table !important;
}
.d-b {
  display: block !important;
}

.txt-c {
  text-align: center !important;
}
.is-hidden {
  display: none;
  transition: all ease 0.3s;
}

.mbm {
  mix-blend-mode: multiply;
}

.no-mbm,
.no-mbm img {
  mix-blend-mode: initial;
}

.sup {
  color: $orange;
  font-family: "Px Grotesk";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
}

.logo-c {
  display: none;
}

.disp-desk {
  display: block !important;
  @include media-breakpoint-down(md) {
    display: none !important;
  }
}

.disp-desk-lg {
  display: block !important;
  @include media-breakpoint-down(lg) {
    display: none !important;
  }
}

.disp-mob {
  display: none !important;
  @include media-breakpoint-down(md) {
    display: block !important;
  }
}
.disp-desk-flex {
  display: flex !important;
  @include media-breakpoint-down(md) {
    display: none !important;
  }
}
.disp-mob-flex {
  display: none !important;
  @include media-breakpoint-down(md) {
    display: flex !important;
  }
}

.dflex {
  display: flex;
  vertical-align: middle;
  align-items: center;
}
