/*
|--------------------
|      CONTENT
|--------------------
*/

.pagination-container {
  li {
    display: inline-block;

    a {
      transition: all 0.3s ease-out;
      padding: 0 8px;

      &:hover {
        color: $grey;
      }
    }

    .current {
      padding: 0 8px;
      font-weight: 800;
      color: $grey;
    }
  }
}

.cms {
  span {
    display: inline;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 30px;
  }

  h1 {
    @extend .title-xxl;
  }

  h2 {
    color: inherit;
    font-family: "graphik", sans-serif;
    font-weight: 400;
    line-height: 1.2;
    font-size: 40px;
  }

  h3 {
    @extend .title-lg;
  }

  h4 {
    @extend .title-md;
  }

  h5 {
    @extend .title-sm;
  }

  h6 {
    @extend .title-xs;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  strong {
    font-weight: bold;
  }

  p {
    margin-bottom: 20px;
  }

  ul {
    margin-bottom: 20px;
    padding-left: 20px;

    li {
      list-style: disc;

      &:not(:last-child) {
        margin-bottom: 5px;
      }

      ul {
        li {
          list-style: circle;
        }
      }
    }
  }

  ol {
    margin-bottom: 20px;
    padding-left: 20px;
  }

  &.cms-no-break {
    p {
      margin-bottom: 0;
    }
  }

  blockquote {
    position: relative;

    width: 80%;

    margin-right: 0;
    margin-left: auto;
    padding: 130px 0;

    font-size: 30px;
    line-height: 1.5em;
    font-weight: 300;

    @include media-breakpoint-down(md) {
      margin: auto;
    }

    &:before {
      content: "";

      position: absolute;

      background-image: url("../img/icon/quote-left.svg");

      width: 50px;
      height: 50px;

      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;

      transform: translateX(-60px) translateY(-30px);
    }

    &:after {
      content: "";

      position: absolute;
      right: 0;

      background-image: url("../img/icon/quote-right.svg");

      width: 50px;
      height: 50px;

      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;

      transform: translateX(0) translateY(-50px);

      @include media-breakpoint-down(md) {
        transform: translateX(60px) translateY(-50px);
      }
    }
  }
}

.custom-loader {
  width: 100px;
  display: inline-block;

  &.cl-center {
    margin-left: auto;
    margin-right: auto;
  }

  svg {
    display: inline-block;
    width: 100%;
    height: auto;
    fill: $black;

    circle {
      stroke: $black;
    }
  }

  &.ajax-loader {
    display: none;
  }
}

.btn {
  font-family: "Px Grotesk";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  background-color: white;
  padding: 14px 22px 12px 44px;
  border-radius: 55px;
  transition: all ease 0.3s;
  position: relative;
  &:before {
    content: "";
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: $orange;
    display: inline-block;
    position: absolute;
    left: 21px;
    top: 18px;
    transition: all ease 0.3s;
  }
  &:hover {
    background-color: $orange;
    transition: all ease 0.3s;
    color: white;
    &:before {
      content: "";
      background-color: white;
      transition: all ease 0.3s;
    }
  }
  &.btn-alt {
    color: $white;
    background-color: $orange;

    &:before {
      content: "";
      background-color: $white;
    }
    &:hover {
      background-color: $white;
      color: $very-dark-grey;
      &:before {
        content: "";
        background-color: $orange;
      }
    }
  }
}

.page-offset {
  padding-top: 95px;
}

.banner {
  height: 70vh;
  background-size: cover;
  background-position: center;
  position: relative;
  text-align: center;
  @include media-breakpoint-down(sm) {
    height: initial;
  }
  .item-content {
    height: 70vh;
    color: black;
    @include media-breakpoint-down(sm) {
      height: initial;
      min-height: 250px;
    }
    .item-title {
      font-family: "Px Grotesk";
      font-style: normal;
      font-weight: 400;
      font-size: 64px;
      line-height: 71px;
      text-align: center;
      @include media-breakpoint-down(md) {
        font-size: 40px;
        line-height: 44px;
      }
      @include media-breakpoint-down(xs) {
        font-size: 30px;
        line-height: 36px;
      }
    }
    .item-text {
      font-family: "Px Grotesk";
      font-style: normal;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      p {
        margin-bottom: 15px;
      }
    }
  }
}

.svg-big-virgule {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  height: 460px;
  width: 519px;
  z-index: -1;
  background-size: cover;
  background-image: url("../img/icon/big-virgule.svg");

  @include media-breakpoint-down(lg) {
    height: 430px;
    width: 485px;
  }
  @include media-breakpoint-down(md) {
    height: 357px;
    width: 403px;
  }
  @include media-breakpoint-down(sm) {
    height: 297px;
    width: 335px;
  }
  @include media-breakpoint-down(xs) {
    height: 227px;
    width: 256px;
  }
}

.svg-virgule {
  position: absolute;
  width: auto;
  height: 1120px;
  display: block;
  top: 210px;
  left: 150px;
  right: 0px;
  margin: auto;
  z-index: -1;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../img/icon/big-virgule-4.svg");
  @include media-breakpoint-down(xl) {
    width: 1100px;
  }
  @include media-breakpoint-down(lg) {
    top: 150px;
    width: 933px;
  }
  @include media-breakpoint-down(md) {
    left: 70px;
    width: 920px;
  }
  @include media-breakpoint-down(sm) {
    top: -110px;
    width: 100%;
  }
  @include media-breakpoint-down(xs) {
    opacity: 0.4;
  }
  &-home {
    left: 0;
    top: initial;
    bottom: -490px;
    opacity: 0.4;
    height: 710px;
    background-size: contain;
    @include media-breakpoint-down(xl) {
      bottom: -430px;
      height: 700px;
      width: 1000px;
    }
    @include media-breakpoint-down(md) {
      background-size: contain;
      width: auto;
      height: 543px;
    }
    @include media-breakpoint-down(xs) {
      display: none;
    }
  }
  &-actu {
    max-width: 1830px;
    height: 1015px;
    top: 110px;
    background-size: cover;
    width: auto;
    left: 0;
    right: 0px;

    @include media-breakpoint-down(xl) {
      width: inherit;
      left: 0;
    }
    @include media-breakpoint-down(lg) {
      width: inherit;
    }
    @include media-breakpoint-down(md) {
      top: 110px;
      width: inherit;
    }
    @include media-breakpoint-down(sm) {
      width: inherit;
    }
    @include media-breakpoint-down(xs) {
      opacity: 1;
    }
  }
  &-single {
    width: 1830px;
    height: 1015px;
    left: 0;
    top: 520px;

    background-size: cover;

    @include media-breakpoint-down(xl) {
      width: inherit;
      left: 0;
      top: 430px;
      background-size: contain;
    }
    @include media-breakpoint-down(lg) {
      top: 350px;
      width: inherit;
    }
    @include media-breakpoint-down(md) {
      top: 350px;
      width: inherit;
    }
    @include media-breakpoint-down(sm) {
      top: 110px;
      width: inherit;
    }
    @include media-breakpoint-down(xs) {
      top: 110px;
      opacity: 1;
    }
  }
  &-contact {
    top: 530px;
    height: 772px;
    width: 1290px;
    opacity: 0.2;
    left: 0;
    background-size: contain;
    @include media-breakpoint-down(xl) {
      top: 480px;
      height: 700px;
      width: 1000px;
    }
    @include media-breakpoint-down(md) {
      background-size: cover;
      width: auto;
      top: 290px;
    }
  }
  &-profil {
    top: 530px;
    height: 772px;
    width: 1290px;
    left: 0;
    background-size: contain;
    background-image: url("../img/icon/svg-orange.svg");
    @include media-breakpoint-down(xl) {
      top: 480px;
      height: 700px;
      width: 1000px;
    }
    @include media-breakpoint-down(md) {
      background-size: cover;
      width: auto;
      top: 460px;
    }
  }
  &-a-propos {
    top: 170px;
    left: 0px;
    right: 0;
    bottom: inherit;
    opacity: 1;
    background-image: url("../img/icon/big-virgule-3.svg");
    background-size: cover;
    transform: rotate(0);
    height: 1620px;
    @include media-breakpoint-down(xl) {
      height: 1820px;
      width: 100%;
    }
  }
  &-modulaire {
    top: 40px;
    left: 140px;
    right: 0;
    margin: auto;
    bottom: inherit;
    opacity: 1;
    background-image: url("../img/icon/svg-modulaire.svg");
    background-size: initial;
    transform: rotate(-13deg);
    height: 2000px;
    width: 100%;
    @include media-breakpoint-down(xl) {
      height: 1190px;
      width: 850px;
      left: 0;
      top: 180px;
      background-position: center;
      background-size: cover;
    }
    @include media-breakpoint-down(sm) {
      height: 1190px;
      width: 850px;
      left: 20px;
      background-position: center;
      background-size: contain;
    }
    @include media-breakpoint-down(xs) {
      left: -160px;
    }
  }
  &-red {
    width: 871px;
    height: 657px;
    top: 0;
    left: -550px;
    right: 0;
    bottom: inherit;
    opacity: 1;
    background-image: url("../img/icon/big-virgule-red.svg");
    background-size: cover;
    transform: rotate(0);
    @include media-breakpoint-down(xs) {
      opacity: 0.4;
    }
  }
}

.svg-flexible-big-virgule {
  position: absolute;
  right: -30px;
  margin: auto;
  top: 40px;
  bottom: 0;
  height: 520px;
  width: 689px;
  z-index: -1;
  background-size: cover;
  background-image: url("../img/icon/svg-flexible-big-virgule.svg");
  &-blue {
    right: initial;
    left: -30px;
    background-image: url("../img/icon/svg-flexible-big-virgule-blue.svg");
  }
  @include media-breakpoint-down(lg) {
    height: 367px;
    width: 485px;
  }
  @include media-breakpoint-down(md) {
    height: 357px;
    width: 393px;
  }
  @include media-breakpoint-down(sm) {
    height: 297px;
    width: 293px;
  }
  @include media-breakpoint-down(xs) {
    height: 227px;
    width: 256px;
  }
}

.svg-marie {
  position: absolute;
  left: 0;
  top: 0px;
  width: 157.23px;
  height: 105.92px;
  background-size: cover;
  background-image: url(../img/icon/svg-marie.svg);
  @include media-breakpoint-down(lg) {
    width: 113.23px;
    height: 75.92px;
  }
  @include media-breakpoint-down(md) {
    width: 157.23px;
    height: 105.92px;
  }
  @include media-breakpoint-down(xs) {
    display: none;
  }
}

.img_select1 {
  position: absolute;
  left: 20px;
  top: 70px;
  height: 73px;
  width: 179px;
  background-size: cover;
  background-image: url("../img/global/img-marg.svg");

  @include media-breakpoint-down(lg) {
    height: 58px;
    width: 142px;
    top: 30px;
  }
  @include media-breakpoint-down(md) {
    height: 74px;
    width: 182px;
    top: 30px;
    left: 0px;
  }
  @include media-breakpoint-down(sm) {
    height: 58px;
    width: 142px;
    top: 50px;
    left: 20px;
  }
  @include media-breakpoint-down(xs) {
    height: 58px;
    width: 142px;
    top: -10px;
    left: 5%;
    transform: scale(70%);
  }
}
.img_select2 {
  position: absolute;
  right: -20px;
  top: 170px;
  width: 157.23px;
  height: 105.92px;
  background-size: cover;
  background-image: url("../img/global/img-eco2.svg");

  @include media-breakpoint-down(lg) {
    height: 87px;
    width: 129px;
    top: 140px;
  }
  @include media-breakpoint-down(md) {
    height: 121px;
    width: 179px;
  }
  @include media-breakpoint-down(sm) {
    height: 87px;
    width: 129px;
    right: 0px;
    top: 169px;
  }
  @include media-breakpoint-down(xs) {
    height: 97px;
    width: 143px;
    right: 5%;
    top: -10px;
    transform: scale(70%);
  }
}
.pre-footer {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  .pft_img_1 {
    position: absolute;
    top: 0;
    left: 0;
    mix-blend-mode: multiply;
    z-index: -1;
    @include media-breakpoint-down(xl) {
      left: -116px;
    }
    @include media-breakpoint-down(md) {
      top: 80px;
      left: -80px;
      width: 410px;
    }
    @include media-breakpoint-down(sm) {
      top: initial;
      bottom: 0;
      left: -10px;
      width: 140px;
    }
    @include media-breakpoint-down(xs) {
      width: 110px;
    }
  }
  .pft_img_1_1 {
    width: 198px;
    height: 32px;
    position: absolute;
    top: 306px;
    left: 330px;
    background-repeat: no-repeat;
    @include media-breakpoint-down(xl) {
      left: 216px;
    }
    @include media-breakpoint-down(md) {
      top: 260px;
      left: 80px;
      width: 170px;
      background-size: contain;
    }
    @include media-breakpoint-down(sm) {
      top: initial;
      bottom: 40px;
      left: 10px;
      width: 140px;
      z-index: -1;
    }
    @include media-breakpoint-down(xs) {
      width: 110px;
    }
  }
  .pft_img_2 {
    position: absolute;
    width: 100%;
    height: 530px;
    bottom: -246px;
    left: -150px;
    right: 0;
    margin: auto;
    z-index: -2;
    background-position: center;
    background-repeat: no-repeat;
    @include media-breakpoint-down(lg) {
      height: 570px;
    }
    @include media-breakpoint-down(md) {
      height: 470px;
      background-size: cover;
      left: 0;
      right: 0;
    }
    @include media-breakpoint-down(sm) {
      height: 440px;
    }
    @include media-breakpoint-down(xs) {
      height: 380px;
    }
  }
  .pft_img_3 {
    position: absolute;
    top: 0;
    right: 0;
    mix-blend-mode: multiply;
    z-index: -1;
    @include media-breakpoint-down(xl) {
      right: -56px;
    }
    @include media-breakpoint-down(md) {
      top: 80px;
      right: -29px;
      width: 410px;
    }
    @include media-breakpoint-down(sm) {
      top: initial;
      bottom: -50px;
      right: -32px;
      width: 200px;
    }
    @include media-breakpoint-down(xs) {
      width: 160px;
    }
  }
  .content-pre-ft {
    text-align: center;
    height: 590px;
    @include media-breakpoint-down(lg) {
      height: 600px;
    }
    @include media-breakpoint-down(md) {
      height: 470px;
    }
    @include media-breakpoint-down(sm) {
      height: 400px;
    }
    @include media-breakpoint-down(xs) {
      height: 280px;
    }
    .desc {
      color: $dark-grey;
      text-shadow: 0px 0px 2px $beige;
    }
    h2 {
      font-size: 64px;
      line-height: 68px;
      @include media-breakpoint-down(lg) {
        font-size: 44px;
        line-height: 48px;
      }
      @include media-breakpoint-down(md) {
        font-size: 36px;
        line-height: 40px;
      }
      @include media-breakpoint-down(sm) {
        font-size: 26px;
        line-height: 30px;
      }
    }
  }
  .pft_img_2_1 {
    width: 157.23px;
    height: 106.76px;
    position: absolute;
    top: 4px;
    right: 355px;
    background-repeat: no-repeat;
    @include media-breakpoint-down(xl) {
      right: 299px;
    }
    @include media-breakpoint-down(md) {
      top: 82px;
      right: 197px;
      width: 105px;
      background-size: contain;
    }
    @include media-breakpoint-down(sm) {
      top: initial;
      bottom: 100px;
      right: 70px;
      width: 70px;
      z-index: -1;
    }
    @include media-breakpoint-down(xs) {
      width: 50px;
      bottom: 40px;
      right: 50px;
    }
  }
  .pft_img_2_2 {
    width: 134px;
    height: 32px;
    position: absolute;
    top: 239px;
    right: 219px;
    background-repeat: no-repeat;
    @include media-breakpoint-down(xl) {
      right: 164px;
    }
    @include media-breakpoint-down(md) {
      top: 234px;
      right: 112px;
      width: 88px;
      background-size: contain;
    }
    @include media-breakpoint-down(sm) {
      top: initial;
      bottom: 87px;
      right: 40px;
      width: 60px;
      z-index: -1;
    }
    @include media-breakpoint-down(xs) {
      width: 50px;
      bottom: 40px;
      right: 50px;
    }
  }
  .pft_img_2_3 {
    width: 126px;
    height: 40px;
    position: absolute;
    top: 162px;
    right: 59px;
    background-repeat: no-repeat;
    @include media-breakpoint-down(xl) {
      right: 5px;
    }
    @include media-breakpoint-down(md) {
      top: 184px;
      right: 2px;
      width: 88px;
      background-size: contain;
    }
    @include media-breakpoint-down(sm) {
      top: initial;
      bottom: 102px;
      right: -6px;
      width: 60px;
      z-index: -1;
    }
    @include media-breakpoint-down(xs) {
      width: 50px;
      bottom: 56px;
      right: -7px;
    }
  }
}
