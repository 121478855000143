/*
|--------------------
|       Contact
|--------------------
*/

#page-a-propos {
    position: relative;
    overflow: hidden;
    .banner {
        height: initial;
        padding: 280px 0px 70px 0px;
        @include media-breakpoint-down(lg) {
            padding: 280px 0px 0px 0px;
        }
    }
    .item-content {
        height: initial;
    }
}
