/*
|--------------------
|   PAGE ecosysteme
|--------------------
*/

#page-ecosysteme {
    .js-filterable {
        h2 {
            @include media-breakpoint-down(md) {
                text-align: center;
            }
        }
    }
    .filter {
        display: inline-block;
        li {
            display: inline-block;
            &:hover {
                color: #ffffff;
                background-color: #ff563f;
            }
            &:hover {
                color: white;
                background-color: $orange;
                &:before {
                    background-color: white;
                }
            }
        }
        .btn {
            &:hover {
                background-color: $orange;
                color: white;
                &:before {
                    background-color: white;
                }
            }
        }
        .btn-alt {
            &:hover {
                color: white;
                background-color: $orange;
                &:before {
                    background-color: white;
                }
            }
        }
    }
    .card-img {
        height: 120px;
        width: 120px;
        display: block;
        margin: auto;
    }
    .card-content {
        font-family: "Px Grotesk";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
    }
}
