/*
|--------------------
|     PAGE HOME
|--------------------
*/
#page-home {
  overflow: hidden;
  position: relative;
}
.banner-home {
  height: 100vh;
  position: relative;
  text-align: center;

  .item-content {
    position: relative;
    z-index: 8;
    height: 100vh;
    color: black;
    .item-title {
      font-family: "Px Grotesk";
      font-style: normal;
      font-weight: 400;
      font-size: 64px;
      line-height: 71px;
      text-align: center;
      @include media-breakpoint-down(md) {
        font-size: 54px;
        line-height: 60px;
      }
      @include media-breakpoint-down(sm) {
        font-size: 44px;
        line-height: 50px;
      }
      @include media-breakpoint-down(xs) {
        font-size: 30px;
        line-height: 36px;
      }
    }
    .item-text {
      font-family: "Px Grotesk";
      font-style: normal;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      p {
        margin-bottom: 15px;
      }
    }
    .home-link {
      position: absolute;
      bottom: 30px;
      left: 0;
      right: 0;
      padding: 7px;
      width: 430px;
      border-radius: 40px;
      margin: auto;
      background-color: white;
      z-index: 1;
      @include media-breakpoint-down(sm) {
        display: block !important;
        width: 90%;
        left: 10px;
        right: 10px;
        background: none;
        span {
          display: block;
          margin-bottom: 10px;
          background: none;
        }
      }
      a {
        font-family: "Px Grotesk";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        text-align: center;
        border: none;
      }
    }
  }
}

.a__svg {
  position: absolute;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: scale(0.8);

  @include media-breakpoint-down(md) {
    bottom: 200px;
  }

  .a__svg-top,
  .a__svg-right,
  .a__svg-bottom,
  .a__svg-left {
    position: absolute;

    top: 50%;
    right: 50%;
    transform: translate(50% 50%);

    display: flex;
    justify-content: center;

    svg {
      width: 70vw;
      max-width: 1000px;
      height: auto;
    }
  }
  /*.a__svg-top {
    position: absolute;

    top: -23vw;
    left: 0;
    right: 0;

    display: flex;
    justify-content: center;

    svg {
      width: 70vw;
      max-width: 1000px;
      height: auto;
    }
  }

  .a__svg-right {
    position: absolute;

    right: -20vw;
    top: 0;
    bottom: 0;

    display: flex;
    align-items: center;

    svg {
      height: 70vw;
      max-height: 1000px;
      width: auto;
    }
  }

  .a__svg-bottom {
    position: absolute;

    bottom: -23vw;
    left: 0;
    right: 0;

    display: flex;
    justify-content: center;

    width: 60%;
    margin: auto;

    svg {
      width: 70vw;
      max-width: 1000px;
      height: auto;
    }
  }

  .a__svg-left {
    position: absolute;

    left: -20vw;
    top: 0;
    bottom: 0;

    display: flex;
    align-items: center;

    svg {
      height: 70vw;
      max-height: 1000px;
      width: auto;
    }
  }*/
}
